import * as Yup from 'yup';

export const RecipeBlastNotifValidationSchema = Yup.object().shape({
  recipe_id: Yup.string()
    .required('Pilih Resep')
    .label('Pilih Resep')
    .max(100, 'Panjang Maksimum 100 Karakter'),
  title: Yup.string()
    .required('Title harus di isi')
    .label('Title')
    .max(100, 'Panjang Maksimum 100 Karakter'),
  description: Yup.string()
    .required('Deskripsi harus di isi')
    .label('Deskripsi')
    .max(100, 'Panjang Maksimuk 100 Karakter'),
});

export const RecipeCategorySchema = Yup.object().shape({
  name: Yup.string()
    .required('Nama Kategori Harus Di Isi')
    .label('Nama Kategori')
    .max(100, 'Panjang Maximum 100 Karakter'),
  color: Yup.string()
    .required('Color Harus Di Isi')
    .matches(
      /^#?([A-Fa-f0-9]{6})$/,
      'Warna hex harus berupa 6 karakter (e.g #FF9AAA)'
    )
    .label('Color')
    .length(7, 'Hex harus 6 karakter'),
});

export const RecipeInformationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Nama resep harus diisi')
    .label('Nama resep')
    .max(100, 'Panjang Maximum 100 Karakter'),
  slug: Yup.string().required('Slug harus diisi'),
  category: Yup.string()
    .oneOf(['food', 'beverage'])
    .required('Tipe resep harus diisi'),
  author: Yup.string()
    .required('Nama pembuat harus diisi')
    .label('Dibuat oleh')
    .max(100, 'Panjang Maximum 100 Karakter'),
  tags: Yup.array().of(Yup.string()).min(1, 'Minimal pilih 1 kategori resep'),
});

export const youtubeRegex =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|live\/|v\/|shorts\/)?)([\w-]+)(\S+)?$/;

export const RecipeDescriptionSchema = Yup.object().shape({
  video: Yup.string()
    .matches(youtubeRegex, {
      message: 'URL YouTube tidak valid',
      excludeEmptyString: true,
    })
    .nullable(),
  short_desc: Yup.string()
    .min(10, 'Deskripsi minimal 10 karakter')
    .required('Short description is required'),
  allergens: Yup.array().of(Yup.string()),
  medias: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .oneOf(['image', 'video'], 'Type must be either "image" or "video"')
          .required('Media type is required'),
        url: Yup.string()
          .url('Invalid URL format')
          .required('Media URL is required'),
      })
    )
    .min(1, 'Minimal upload 1 gambar')
    .required('Medias are required'),
});

export const RecipeIngredientsSchema = Yup.object().shape({
  making_duration: Yup.number()
    .typeError('Durasi masak harus diisi')
    .min(1, 'Durasi masak minimal 1 menit')
    .required('Durasi masak harus diisi'),
  portion_size: Yup.number()
    .typeError('Durasi masak harus diisi')
    .min(1, 'Jumlah porsi minimal 1 porsi')
    .required('Jumlah porsi harus diisi'),
  ingredient_groups: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required('Ingredient group title is required'),
        is_optional: Yup.boolean().required('is_optional flag is required'),
        ingredients: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string().required('Nama bahan harus diisi'),
              amount: Yup.number()
                .typeError('Takaran harus diisi')
                .min(0, 'Takaran harus diisi')
                .required('Takaran harus diisi'),
              amount_unit: Yup.string().required('Amount unit is required'),
              id: Yup.string().nullable(), // Optional ID
              recommended_products: Yup.array().of(
                Yup.string().required('Nama produk harus dipilih')
              ),
              alternative_products: Yup.array().of(
                Yup.string().required('Nama produk harus dipilih')
              ),
            })
          )
          .min(1, 'Minimal masukkan 1 nama bahan')
          .required('Bahan-bahan harus diisi'),
      })
    )
    .min(1, 'Minimal masukkan 1 bahan')
    .required('Bahan harus diisi'),
});

export const RecipeSectionSchema = Yup.object().shape({
  title: Yup.string()
    .required('Nama section harus diisi')
    .label('Nama section')
    .max(40, 'Panjang maximum 40 karakter'),
  type: Yup.string()
    .oneOf(['created_at_desc', 'views_desc', 'manual'])
    .required('Urutan harus diisi'),
  recipes: Yup.array().when('type', {
    is: 'manual',
    then: Yup.array()
      .min(1, 'Minimal masukkan 1 resep')
      .required('Harus pilih resep'),
    otherwise: Yup.array().notRequired(),
  }),
});

export const RecipeSectionRecipeListCreateSchema = Yup.object().shape({
  title: Yup.string()
    .required('Nama section harus diisi')
    .label('Nama section')
    .max(40, 'Panjang maximum 40 karakter'),
  recipes: Yup.array()
    .min(1, 'Minimal masukkan 1 resep')
    .required('Harus pilih resep'),
});
