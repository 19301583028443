import { Box, BoxProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

export const ListItem = forwardRef<
  React.ElementRef<typeof Box>,
  React.PropsWithChildren<BoxProps>
>(({ children, ...props }, ref) => {
  return (
    <Box as="li" ref={ref} {...props}>
      {children}
    </Box>
  );
});

ListItem.displayName = 'ListItem';
