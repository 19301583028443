import { PageLoader } from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/components';
import { Outlet } from 'react-router-dom';

import { useRecipeDetail, useRecipeTags } from '../../api';

export function RecipeDetailLayout() {
  const { isPending: isRecipeDetailPending } = useRecipeDetail();
  const { isPending: isRecipeTagsPending } = useRecipeTags();

  if (isRecipeDetailPending || isRecipeTagsPending) {
    return (
      <AdminLayout>
        <PageLoader />
      </AdminLayout>
    );
  }

  return <Outlet />;
}
