import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { Breadcrumbs, PageLoader } from '@diamond/shared/ui';
import { AdminLayout, ErrorPage } from '@diamond/sol-admin/components';
import { useNavigate, useParams } from 'react-router-dom';

import { usePromoManagementDetail } from '../api';
import { PromoDetailForm, PromoDetailTable } from '../components';

export function PromoDetailPage() {
  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Promo Management Detail',
      link: '/promo',
    },
  ];

  const params = useParams();
  const navigate = useNavigate();
  const promo_id = params['promoId'] as string;

  const {
    data,
    isPending: isLoading,
    error,
    dataUpdatedAt,
  } = usePromoManagementDetail(promo_id);

  if (isLoading) {
    return (
      <AdminLayout>
        <PageLoader />
      </AdminLayout>
    );
  }

  if (error) {
    return (
      <ErrorPage
        message={error.message}
        subtitle={error.subtitle}
        buttonTitle="Kembali ke halaman promo"
        action={() => navigate('/promo')}
      />
    );
  }

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box py={4}>
        <Stack spacing={8} direction="row" justifyContent="space-between">
          <Text fontSize="2xl" fontWeight="bold">
            Promo Management Detail
          </Text>
        </Stack>
      </Box>
      <PromoDetailForm key={dataUpdatedAt} data={data} />
      <Heading fontSize="xl" mb="4">
        Detail Promo
      </Heading>
      <PromoDetailTable data={data.items} />
    </AdminLayout>
  );
}
