import {
  BackofficeRecipeSectionItemDeleteParams,
  BackofficeRecipeSectionItemDeleteResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BACKOFFICE_RECIPE_QUERY_SECTION_KEY } from './get-recipe-section';

export const deleteRecipeSectionItem = async (
  token: string,
  params: BackofficeRecipeSectionItemDeleteParams
) => {
  const url = `/backoffice/recipe/section/${params.id}/item/${params.item_id}`;
  const { data } = await apiInstance(
    token
  ).delete<BackofficeRecipeSectionItemDeleteResponse>(url);
  return data;
};

export function useRecipeSectionItemDeleteQuery() {
  const queryClient = useQueryClient();
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: (params: BackofficeRecipeSectionItemDeleteParams) =>
      deleteRecipeSectionItem(token, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY],
      });
    },
  });
}
