import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import {
  ActionMeta,
  ChakraStylesConfig,
  CreatableProps as ChakraReactSelectCreatableProps,
  CreatableSelect,
  GroupBase,
} from 'chakra-react-select';
import { KeyboardEventHandler, useMemo, useState } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface CreatableProps<
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>,
  FormValues extends FieldValues = FieldValues,
  TName extends Path<FormValues> = Path<FormValues>
> extends Omit<
      ChakraReactSelectCreatableProps<Option, true, Group>,
      'name' | 'defaultValue'
    >,
    UseControllerProps<FormValues, TName> {
  label?: string;
  labelProps?: FormLabelProps;
  transformToValue: (option: Option) => PathValueItem<FormValues, TName>;
  transformToOption: (value: PathValueItem<FormValues, TName>) => Option;
  handleActionMetaChange?: (actionMeta: ActionMeta<Option>) => void;
}

type PathValueItem<
  FormValues extends FieldValues = FieldValues,
  TName extends Path<FormValues> = Path<FormValues>
> = PathValue<FormValues, TName> extends Array<infer U> ? U : never;

export function PrimitiveCreatable<
  Option = unknown,
  FormValues extends FieldValues = FieldValues,
  Group extends GroupBase<Option> = GroupBase<Option>,
  TName extends Path<FormValues> = Path<FormValues>
>({
  name,
  label,
  labelProps,
  options,
  control,
  rules,
  shouldUnregister,
  transformToValue,
  transformToOption,
  handleActionMetaChange,
  ...creatableProps
}: CreatableProps<Option, Group, FormValues, TName>) {
  const [inputValue, setInputValue] = useState('');

  const chakraStyles: ChakraStylesConfig<Option, true, Group> = useMemo(
    () => ({
      valueContainer: (provided) => ({
        ...provided,
        px: 2,
      }),
    }),
    []
  );

  const {
    field: { ref, value, onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        const input = transformToOption(
          inputValue as PathValueItem<FormValues, TName>
        );
        onChange([...value, transformToValue(input)]);
        setInputValue('');
        event.preventDefault();
      }
    }
  };

  return (
    <FormControl label={label} isInvalid={!!error} id={name}>
      {label && (
        <FormLabel aria-label={label} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <CreatableSelect
        components={{
          DropdownIndicator: null,
        }}
        chakraStyles={chakraStyles}
        options={options}
        {...creatableProps}
        {...field}
        isMulti
        menuIsOpen={false}
        inputValue={inputValue}
        onKeyDown={handleKeyDown}
        onInputChange={(newValue) => setInputValue(newValue)}
        value={value.map(transformToOption)}
        onChange={(newValues, actionMeta) => {
          handleActionMetaChange?.(actionMeta);
          onChange(newValues.map(transformToValue));
        }}
        ref={ref}
      />
      <FormErrorMessage textColor="red">{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
