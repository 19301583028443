import { BackofficePromoUpdateRequest } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';

export async function updatePromoDetail(
  access_token: string,
  promo_id: string,
  payload: BackofficePromoUpdateRequest
) {
  return apiInstance(access_token).patch(
    `/backoffice/promo/${promo_id}`,
    payload
  );
}
