import { isHotkey } from '@udecode/plate-core';
import { KeyboardHandler } from '@udecode/plate-core/react';
import { ListItemContentPlugin } from '@udecode/plate-list/react';

import { TipsPlugin } from '../tips-plugin';
import { listSoftBreak } from './transform';
import { ListSoftBreakConfig } from './types';

export const onKeyDownListSoftBreak: KeyboardHandler<ListSoftBreakConfig> = ({
  editor,
  event,
  getOptions,
}) => {
  const { rules = [] } = getOptions();

  if (event.defaultPrevented) return;

  const entry = editor.api.block();

  if (!entry) return;

  rules.forEach(({ hotkey, ...rule }) => {
    const isListItemContent = entry[0].type === ListItemContentPlugin.key;
    const isTips = entry[0].type === TipsPlugin.key;

    const isAllowedElement = isListItemContent || isTips;

    if (
      isHotkey(hotkey, event as any) &&
      isAllowedElement &&
      listSoftBreak(editor, rule)
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  });
};
