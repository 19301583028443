import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';

import { useStepContext, useStepperStyles } from './step-context';

export type StepProps = HTMLChakraProps<'div'>;

export const Step = forwardRef<StepProps, 'div'>(function Step(props, ref) {
  const { orientation, status, showLastSeparator } = useStepContext();
  const styles = useStepperStyles();

  return (
    <chakra.div
      ref={ref}
      data-status={status}
      data-orientation={orientation}
      data-stretch={showLastSeparator ? '' : undefined}
      __css={styles['step']}
      {...props}
      className={props.className}
    />
  );
});
