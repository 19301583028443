import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  InputRightAddon,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  BackofficeUpdateRecipeListBody,
  RecipeIngredientsSectionPayload,
} from '@diamond/shared/types';
import { FieldArrayCreatable, TextField } from '@diamond/shared/ui';
import { AddOutlined } from '@mui/icons-material';
import { OptionBase } from 'chakra-react-select';
import { useState } from 'react';
import {
  useFieldArray,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useRecipeDetail, useUpdateRecipeMutation } from '../../../api';
import { AlternativeProductsContext } from './alternative-products-context';
import { IngredientsGroupFieldArray } from './ingredients-group-field-array';
import { ProductModeContext } from './product-mode-context';
import {
  Ingredient,
  IngredientGroup,
  ProductModeType,
  RecipeIngredientsFields,
} from './types';
import { emptyArr } from './utils';

interface Options extends OptionBase, IngredientGroup {
  label: string;
  value: string;
  id: string;
}

type RecipeIngredientsProps<
  TFields extends RecipeIngredientsFields = RecipeIngredientsFields
> = {
  form: UseFormReturn<TFields>;
};

type RecipeTypeSelectionButtonProps = {
  form: UseFormReturn<RecipeIngredientsSectionPayload>;
  fieldArray: UseFieldArrayReturn<
    RecipeIngredientsSectionPayload,
    'ingredient_groups'
  >;
  productModeType: ProductModeType;
  setProductModeType: (productModeType: ProductModeType) => void;
  hasAlternativeProducts: boolean;
  setHasAlternativeProducts: (hasAlternative: boolean) => void;
};

export function RecipeIngredientsForm<
  TFields extends RecipeIngredientsFields = RecipeIngredientsFields
>({ form }: RecipeIngredientsProps<TFields>) {
  const _form = form as UseFormReturn<RecipeIngredientsFields>;

  const { data } = useRecipeDetail();
  const isDisabled = data?.is_published;

  const fieldArray = useFieldArray({
    control: _form.control,
    name: 'ingredient_groups',
  });

  const { fields } = fieldArray;

  const [productModeType, setProductModeType] = useState<ProductModeType>(
    'recommended_products'
  );

  const [hasAlternativeProducts, setHasAlternativeProducts] = useState(false);

  return (
    <Card variant="outline" size="sm" flex="1" minH="650px">
      <CardHeader>
        <Heading size="md">Input Bahan & Produk</Heading>
      </CardHeader>
      <CardBody>
        <RecipeTypeSelectionButton
          productModeType={productModeType}
          setProductModeType={setProductModeType}
          hasAlternativeProducts={hasAlternativeProducts}
          setHasAlternativeProducts={setHasAlternativeProducts}
          fieldArray={fieldArray}
          form={_form}
        />
        <AlternativeProductsContext.Provider value={hasAlternativeProducts}>
          <ProductModeContext.Provider value={productModeType}>
            <VStack spacing="3" alignItems="flex-start">
              {productModeType === 'recommended_products' ? (
                <>
                  <Flex gap="3" w={{ base: 'full', xl: '50%' }}>
                    <TextField
                      register={_form.register}
                      registerOptions={{
                        pattern: {
                          value: /^[1-9][0-9]*$/,
                          message: 'Please enter only numbers greater than 0.',
                        },
                      }}
                      type="number"
                      name="portion_size"
                      label="Jumlah Porsi"
                      placeholder="-"
                      errors={_form.formState.errors}
                      labelProps={{
                        color: 'gray.75',
                        fontWeight: 'medium',
                        fontSize: 'sm',
                      }}
                      rightAddon={
                        <InputRightAddon bg="white">Porsi</InputRightAddon>
                      }
                      inputMode="numeric"
                      isDisabled={isDisabled}
                    />
                    <TextField
                      register={_form.register}
                      registerOptions={{
                        pattern: {
                          value: /^[1-9][0-9]*$/,
                          message: 'Please enter only numbers greater than 0.',
                        },
                      }}
                      type="number"
                      name="making_duration"
                      label="Durasi Masak"
                      placeholder="-"
                      errors={_form.formState.errors}
                      labelProps={{
                        color: 'gray.75',
                        fontWeight: 'medium',
                        fontSize: 'sm',
                      }}
                      rightAddon={
                        <InputRightAddon bg="white">Menit</InputRightAddon>
                      }
                      isDisabled={isDisabled}
                    />
                  </Flex>
                  <VStack alignItems="flex-start" w="full">
                    <Text mt="4" fontWeight="medium">
                      Bahan-bahan terbaik
                    </Text>
                    <FieldArrayCreatable
                      fieldArray={fieldArray}
                      appendOpts={{
                        focusName: `ingredient_groups.${fields.length}.ingredients.0.title`,
                      }}
                      name="ingredient_groups"
                      components={{
                        ClearIndicator: () => null,
                        DropdownIndicator: null,
                      }}
                      tagVariant="recipe"
                      placeholder="Klik ↩️ untuk menambahkan bahan"
                      label="Nama Komponen Resep:"
                      labelProps={{
                        color: 'gray.75',
                        fontWeight: 'medium',
                        fontSize: 'sm',
                      }}
                      size="lg"
                      transformToValue={(opt: Options) => ({
                        id: opt.id,
                        title: opt.value,
                        ingredients: opt.ingredients,
                        is_optional: opt.is_optional,
                      })}
                      transformToOption={(value) => ({
                        label: value.title,
                        value: value.title,
                        id: value.id,
                        ingredients: value.ingredients,
                        is_optional: value.is_optional,
                        title: value.title,
                      })}
                      transformInputToValue={(inputValue) => ({
                        title: inputValue,
                        ingredients: emptyArr,
                        is_optional: false,
                      })}
                      isDisabled={isDisabled}
                    />
                  </VStack>
                </>
              ) : null}

              <Box>
                <VStack spacing="5" alignItems="flex-start" w="full" mt="8">
                  {fields.map((f, i) => (
                    <IngredientsGroupFieldArray
                      key={f.id}
                      form={_form}
                      title={f.title}
                      index={i}
                    />
                  ))}
                </VStack>
              </Box>
            </VStack>
          </ProductModeContext.Provider>
        </AlternativeProductsContext.Provider>
      </CardBody>
    </Card>
  );
}

function RecipeTypeSelectionButton({
  form,
  fieldArray,
  productModeType,
  hasAlternativeProducts: hasAlternativeProductsState,
  setHasAlternativeProducts,
  setProductModeType,
}: RecipeTypeSelectionButtonProps) {
  const recipeId = useParams()['recipeId'] as string;
  const updateRecipeMutation = useUpdateRecipeMutation(recipeId);
  const { data } = useRecipeDetail();
  const isDisabled = data?.is_published;
  const ingredientGroups = useWatch({
    control: form.control,
    name: 'ingredient_groups',
  });

  const replaceAlternativeProductsWith = (
    replacer: (ingredient: Ingredient) => Ingredient['alternative_products']
  ) => {
    const deepCopiedField = fieldArray.fields.map((field) => ({
      ...field,
      ingredients: field.ingredients.map((ig) => ({
        ...ig,
        alternative_products: replacer(ig),
      })),
    }));
    fieldArray.replace(deepCopiedField);
    form.handleSubmit(
      async (data) =>
        await updateRecipeMutation.mutateAsync({
          ...data,
          ingredient_groups: deepCopiedField,
        } as unknown as BackofficeUpdateRecipeListBody)
    )();
  };

  const hasAlternativeProducts = ingredientGroups.some((group) =>
    group.ingredients.some((ig) => ig.alternative_products.length > 0)
  );

  if (hasAlternativeProducts !== hasAlternativeProductsState) {
    setHasAlternativeProducts(hasAlternativeProducts);
  }

  // TODO: Optimize this worst case O(n^3)
  const hasRecommendedProducts = ingredientGroups.some((group) =>
    group.ingredients.some(
      (ig) =>
        ig.recommended_products.length > 0 &&
        ig.recommended_products.some((rp) => rp !== '')
    )
  );

  return (
    <Flex gap="3" mb="4">
      <Button
        size="sm"
        variant={
          productModeType === 'recommended_products' ? 'solid' : 'outline'
        }
        onClick={() => setProductModeType('recommended_products')}
      >
        Resep Utama
      </Button>
      {hasRecommendedProducts ? (
        <Button
          size="sm"
          variant={
            productModeType === 'alternative_products' ? 'solid' : 'outline'
          }
          leftIcon={
            !hasAlternativeProducts ? (
              <Icon fontSize="md" as={AddOutlined} />
            ) : undefined
          }
          onClick={() => {
            setProductModeType('alternative_products');
            if (hasAlternativeProducts) return;
            replaceAlternativeProductsWith((ingredient) => [
              ...ingredient.recommended_products,
            ]);
          }}
        >
          Resep Alternatif
        </Button>
      ) : null}
      <Spacer />
      {hasAlternativeProducts && !isDisabled ? (
        <Button
          size="sm"
          variant="ghost"
          bg="white"
          color="gray.75"
          onClick={() => {
            replaceAlternativeProductsWith(() => []);
            setProductModeType('recommended_products');
          }}
        >
          Hapus alternatif
        </Button>
      ) : null}
    </Flex>
  );
}
