import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react';
import { RemoveRedEyeOutlined } from '@mui/icons-material';

type ViewCountProps = {
  count: number;
} & FlexProps;

export function ViewCount({ count, ...props }: ViewCountProps) {
  return (
    <Flex alignItems="center" gap="1" color="gray.75" {...props}>
      <Icon as={RemoveRedEyeOutlined} fontSize={{ base: 'xs', md: 'sm' }} />
      <Text fontSize={{ base: 'xs', md: 'sm' }}>{count}</Text>
    </Flex>
  );
}
