/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Divider,
  HStack,
  Stack,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { BackofficeCreateNotification } from '@diamond/shared/types';
import { Modal, TextArea, TextField } from '@diamond/shared/ui';
import { showToast } from '@diamond/shared/ui';
import { RecipeBlastNotifValidationSchema } from '@diamond/shared/validation';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import {
  BACKOFFICE_RECIPE_NOTIFICATION_QUERY_KEY,
  getRecipeList,
  useCreateRecipeNotificationMutation,
  useRecipeList,
} from '../api';
import { useDebouncedPromise } from '../hooks';
import { SelectSingleRecipeField } from './forms/fields/select-single-recipe-field';

export interface CreateBlastNotifProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateBlastNotification = ({
  isOpen = false,
  onClose,
}: CreateBlastNotifProps) => {
  const { data } = useRecipeList();
  const toast = useToast();
  const queryClient = useQueryClient();
  const token = useAuthStore((s) => s.access_token);

  const options =
    data &&
    data.data.map((notif) => {
      return {
        value: notif.id,
        label: notif.title,
      };
    });
  const methods = useForm<BackofficeCreateNotification>({
    resolver: yupResolver(RecipeBlastNotifValidationSchema),
    shouldUnregister: false,
  });
  const createRecipeNotification = useCreateRecipeNotificationMutation({
    onSuccess: () => {
      showToast(toast, 'success', 'Berhasil Menambah Notification');
      onClose();
      methods.reset();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_NOTIFICATION_QUERY_KEY],
      });
    },
  });
  const onSubmit = async (values: any) => {
    await createRecipeNotification.mutateAsync(values);
  };

  const loadOptions = useDebouncedPromise(
    async (search: string) =>
      getRecipeList(token, {
        page: 1,
        size: 10,
        search_query: search,
      }).then(mapToOptions),
    1000
  );

  return (
    <Modal
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        methods.reset();
      }}
      name="form-tambah-blast-notif"
      title="Add New Notif"
      hideAction
    >
      <Divider borderColor="black" border="1px" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack py={4} data-testid="CreateBlastNotificationSelectRecipe">
            <SelectSingleRecipeField
              form={methods}
              name="recipe_id"
              label="Pilih Resep"
              placeholder="Pilih Resep"
              loadOptions={loadOptions}
              defaultOptions={options ?? []}
              handleOnSelect={(value) => {
                if (value) {
                  methods.setValue('recipe_id', value.value);
                }
              }}
            />
          </Stack>
          <Stack pt={4} pb={8}>
            <TextField
              name="title"
              label="Title"
              placeholder="Masukan Title"
              register={methods.register}
              maxLength={100}
              errors={methods.formState.errors}
            />
          </Stack>
          <Stack pt={4} pb={8}>
            <TextArea
              register={methods.register}
              errors={methods.formState.errors}
              name="description"
              label="Description"
              placeholder="Masukan Description"
              maxLength={100}
            />
          </Stack>
          <Divider borderColor="black" border="1px" />
          <Stack py={4}>
            <Wrap justify="end">
              <WrapItem>
                <HStack>
                  <Button
                    onClick={() => {
                      onClose();
                      methods.reset();
                    }}
                    variant="outline"
                  >
                    Tutup
                  </Button>
                  <Button type="submit" variant="solid">
                    <span>Save</span>
                  </Button>
                </HStack>
              </WrapItem>
            </Wrap>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
};

function mapToOptions(response: Awaited<ReturnType<typeof getRecipeList>>) {
  return response.data.map((opt) => ({ value: opt.id, label: opt.title }));
}
