/* eslint-disable @typescript-eslint/no-explicit-any */
import { HStack, Stack, useToast, Wrap, WrapItem } from '@chakra-ui/react';
import { PostDataWrapper } from '@diamond/shared/data-access';
import { ProductKeyword } from '@diamond/shared/types';
import {
  Button,
  Divider,
  Modal,
  showToast,
  Text,
  TextField,
} from '@diamond/shared/ui';
import { isValidUrl } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { CustomerTypeInput } from '@diamond/sol-admin/features/shipto';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import validator from 'validator';
import * as Yup from 'yup';

interface CreateKeywordProps {
  isOpen: boolean;
  onClose: () => void;
  data: ProductKeyword;
  refetch: () => void;
}

const Keyword = Yup.object().shape({
  name: Yup.string()
    .required('Nama Keyword harus di isi')
    .trim('Harus berupa karakter')
    .strict(true),
  url: Yup.string().required('URL keyword harus di isi'),
});

export function CreateKeyword({
  isOpen = false,
  onClose,
  data: allProductKeyword,
  refetch: refetchProductKeyword,
}: CreateKeywordProps) {
  const session = useAuthStore();
  const toast = useToast();

  const methods = useForm({
    resolver: yupResolver(Keyword),
    shouldUnregister: false,
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const { postData } = PostDataWrapper(
    `/backoffice/product/keyword`,
    session.access_token,
    false,
    'admin'
  );

  const createKeyword = useMutation({
    mutationFn: async (payload: unknown) => {
      postData(payload)
        .then(() => {
          showToast(toast, 'success', 'Keyword berhasil disimpan');
          refetchProductKeyword();
        })
        .catch((error) => {
          showToast(toast, 'error', error.message);
        });
    },
  });

  const onSubmit: SubmitHandler<any> = (values) => {
    let indexToCheck = 1;
    let payload: unknown = {};

    const urlForSubmit = !validator.contains(values.url, 'http')
      ? `https://${values.url}`
      : values.url;

    if (!isValidUrl(urlForSubmit)) {
      return showToast(toast, 'error', 'URL tidak sesuai');
    }

    for (let i = 0; i < 5; i++) {
      if (typeof allProductKeyword[i] !== 'undefined') {
        if (allProductKeyword[i].index === indexToCheck) {
          indexToCheck++;
          continue;
        }
      } else {
        payload = {
          name: values.name,
          url: urlForSubmit.replace(/%20/g, '+'),
          index: indexToCheck,
          business_segmentation:
            values?.business_segmentation?.map((val: any) => val.value) ?? [],
          distribution_channel:
            values?.distribution_channel?.map((val: any) => val.value) ?? [],
        };
        break;
      }
    }
    createKeyword.mutate(payload);
    reset();
    onClose();
  };

  const titLeLength =
    watch('name') === undefined ? 0 : String(watch('name')).length;

  return (
    <Modal
      size="xl"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      name="form-tambah-keyword"
      title="Tambah Keyword"
      hideAction
    >
      <Divider borderColor="black" />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack py={4}>
            <TextField
              name="name"
              label="Nama Keyword"
              placeholder="Masukkan nama keyword"
              maxLength={30}
              register={register}
              errors={errors}
            />
            <Text fontSize="sm" color="gray" textAlign="right">
              {titLeLength}/30
            </Text>
            <CustomerTypeInput />
            <TextField
              name="url"
              aria-label="Link"
              label="Link"
              placeholder="Masukkan URL"
              register={register}
              errors={errors}
            />
          </Stack>
          <Divider borderColor="black" />
          <Stack py={4}>
            <Wrap justify="end">
              <WrapItem>
                <HStack>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                  >
                    Tutup
                  </Button>
                  <Button type="submit" variant="solid">
                    Tambahkan
                  </Button>
                </HStack>
              </WrapItem>
            </Wrap>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
}
