import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BACKOFFICE_RECIPE_QUERY_KEY } from './get-recipe-list';

export const reorderRecipe = async (
  token: string,
  id: string,
  index: number
) => {
  return apiInstance(token).patch(
    `/backoffice/recipe/${id}/reorder`,
    JSON.stringify({ index })
  );
};

type ReorderRecipeArgs = {
  id: string;
  destinationIndex: number;
};

export function useRecipeReorderMutation() {
  const queryClient = useQueryClient();
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: ({ id, destinationIndex }: ReorderRecipeArgs) =>
      reorderRecipe(token, id, destinationIndex),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_KEY],
      });
    },
  });
}
