import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';

import { useStepContext, useStepperStyles } from './step-context';
import { StepIcon } from './step-icon';
import { StepNumber } from './step-number';
import { StepStatus } from './step-status';

export type StepIndicatorProps = HTMLChakraProps<'div'>;

export const StepIndicator = forwardRef<StepIndicatorProps, 'div'>(
  function StepIndicator(props: StepIndicatorProps, ref) {
    const { status } = useStepContext();
    const styles = useStepperStyles();
    return (
      <chakra.div
        ref={ref}
        data-status={status}
        {...props}
        __css={styles['indicator']}
        className={props.className}
      />
    );
  }
);

export function StepIndicatorContent() {
  return (
    <StepStatus
      complete={<StepIcon />}
      incomplete={<StepNumber />}
      active={<StepNumber />}
    />
  );
}
