import { FlexProps } from '@chakra-ui/react';
import { type DropResult, DragDropContext } from '@hello-pangea/dnd';
import { ReactElement, useEffect, useState } from 'react';

import reorder from './reorder';
import SortableList from './sortable-list';
import type { SortableItem } from './types';

export type { DropResult } from '@hello-pangea/dnd';

interface SortableProps {
  data: SortableItem[];
  handleChange?: (result: DropResult, items: SortableItem[]) => void;
  handleReorderIndex?: (from: number, to: number) => void;
  handleClickIndexing?: (id: string) => void;
  sortableCardProps?: FlexProps;
  shouldRefetchData?: boolean;
  isPromotion?: boolean;
  pageLoadedIndex?: number[]; // Indexes of items where clickIndexing action should be hidden
  showIndexingButton?: boolean;
}

export function Sortable(props: SortableProps): ReactElement {
  const {
    data,
    handleChange,
    handleReorderIndex,
    handleClickIndexing,
    sortableCardProps,
    pageLoadedIndex,
    shouldRefetchData = true,
    isPromotion = false,
    showIndexingButton,
  } = props;
  const [items, setItems] = useState(data);
  const onDragStart = () => {
    // Add a little vibration if the browser supports it.
    // Add's a nice little physical feedback
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  useEffect(() => {
    if (data && shouldRefetchData) {
      setItems(data);
    }
  }, [data, shouldRefetchData]);

  const onDragEnd = (result: DropResult) => {
    // combining item
    if (result.combine) {
      // super simple: just removing the dragging item
      const newItems: SortableItem[] = [...items];
      newItems.splice(result.source.index, 1);
      setItems(newItems);
      return;
    }

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);

    if (handleReorderIndex) {
      handleReorderIndex(result.source.index, result.destination.index);
    }

    if (handleChange) {
      return handleChange(result, items);
    }
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <SortableList
        listId="list"
        items={items}
        sortableCardProps={sortableCardProps}
        isPromotion={isPromotion}
        handleClickIndexing={handleClickIndexing}
        pageLoadedIndex={pageLoadedIndex}
        showIndexingButton={showIndexingButton}
      />
    </DragDropContext>
  );
}
