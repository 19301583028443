import {
  Box,
  HStack,
  Image as ChakraImage,
  ImageProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import {
  Breadcrumbs,
  Button,
  DataTable,
  PageLoader,
  Text,
  TextField,
} from '@diamond/shared/ui';
import {
  adminCurrencyFormatter,
  paymentMethodFormatter,
} from '@diamond/shared/utils';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useCustomerTypes } from '@diamond/sol-admin/features/shipto';
import { formatDeliveryDate } from '@diamond/sol-admin/utils';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useActivitiesDetail } from '../api';
import { columns } from '../components/activity-detail-po-columns';

interface ActivitiesImagePaymentProps extends ImageProps {
  url: string;
}

function ActivitiesImagePayment({
  url,
  ...props
}: ActivitiesImagePaymentProps) {
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  }, [url]);

  const imagePosition =
    imageDimensions.height > imageDimensions.width
      ? {
          width: '25%',
          height: '25%',
        }
      : {
          width: '60%',
          height: '60%',
        };

  return <ChakraImage src={url} style={{ ...imagePosition }} {...props} />;
}

export function ActivitiesPaymentPage() {
  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Activities',
      link: '/activities',
    },
    {
      title: 'Bukti Pembayaran',
      link: ``,
    },
  ];
  const params = useParams();
  const methods = useForm();
  const activitiesId = params['activitiesId'] as string;
  const pdfUrls: string[] = [];
  const imageUrls: string[] = [];

  const { activitiesDetailData, isLoading } = useActivitiesDetail(
    activitiesId,
    {
      order_by: '',
      direction: '',
      size: 9999,
      page: 1,
    }
  );
  const activitiesDiscountData = activitiesDetailData?.order_items.map(
    (discount) => {
      const matchDiscount =
        activitiesDetailData.order.promos.compiled_product_discounts.find(
          (discount2) => discount2.product_id === discount.product_id
        );

      return matchDiscount ? { ...discount, ...matchDiscount } : discount;
    }
  );
  const activitiesDiscountDataSingle = activitiesDiscountData?.map(
    (discount) => {
      const matchDiscount =
        activitiesDetailData?.order.promos.single_discount_promos.find(
          (discount2) => discount2.product_id === discount.product_id
        );

      if (matchDiscount) {
        const { id, ...rest } = matchDiscount; // Exclude the `id` field from matchDiscount
        return { ...discount, ...rest }; // Merge but keep the `id` from `discount`
      }

      return discount;
    }
  );
  const activitiesPromoData = activitiesDiscountDataSingle?.flatMap(
    (discount) => {
      const matchFreegoodPromo =
        activitiesDetailData?.order.promos.single_free_good_promos.find(
          (freeGood) => freeGood.ref_product_id === discount.product_id
        );

      return matchFreegoodPromo
        ? [discount, { ...discount, ...matchFreegoodPromo }]
        : [discount];
    }
  );

  const { maps: mapsCustomerTypes } = useCustomerTypes();

  activitiesDetailData?.order?.attachments?.payment_proofs.forEach((url) => {
    const urlObj = new URL(url);

    if (urlObj.pathname.endsWith('.pdf') || urlObj.pathname.endsWith('.PDF')) {
      pdfUrls.push(url);
    } else if (
      urlObj.pathname.endsWith('.png') ||
      urlObj.pathname.endsWith('.PNG')
    ) {
      imageUrls.push(url);
    } else if (
      urlObj.pathname.endsWith('.jpg') ||
      urlObj.pathname.endsWith('.jpeg') ||
      urlObj.pathname.endsWith('.JPG') ||
      urlObj.pathname.endsWith('.JPEG')
    ) {
      imageUrls.push(url);
    }
  });

  if (!activitiesDetailData || isLoading) {
    return (
      <AdminLayout>
        <PageLoader />
      </AdminLayout>
    );
  }

  const openMultipleLinks = (links: string[]) => {
    links.forEach((url) => window.open(url));
  };
  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <FormProvider {...methods}>
        <Box>
          <TableContainer id="product-table-division" maxWidth="container.sm">
            <Table variant="simple" size="sm">
              <Tbody>
                <Tr>
                  <Td>Nomor PO</Td>
                  <Td fontWeight="bold">
                    {activitiesDetailData?.order.transaction_id || '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Nama Shipto</Td>
                  <Td fontWeight="bold">
                    {activitiesDetailData?.order.ship_to['name'] || '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Nama Pemesan</Td>
                  <Td fontWeight="bold">
                    {activitiesDetailData?.order.ship_to_contact?.name || '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td>No HP Pemesan</Td>
                  <Td fontWeight="bold">
                    {activitiesDetailData?.order.ship_to_contact?.telp || '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Tipe Customer</Td>
                  <Td fontWeight="bold">
                    {mapsCustomerTypes[
                      activitiesDetailData?.order.ship_to[
                        'cg_1_business_segmentation'
                      ] as string
                    ] || '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Alamat Pengiriman</Td>
                  <Td fontWeight="bold">
                    {activitiesDetailData?.order.ship_to['address'] || '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Catatan Pengiriman</Td>
                  <Td
                    fontWeight="bold"
                    wordBreak="break-word"
                    whiteSpace="pre-wrap"
                  >
                    {activitiesDetailData?.order.note || '-'}
                  </Td>
                </Tr>

                <Tr>
                  <Td>Tanggal Pengiriman</Td>
                  <Td fontWeight="bold">
                    {formatDeliveryDate(
                      activitiesDetailData.order.delivery_option,
                      activitiesDetailData.order.delivery_date
                    )}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Jenis Pembayaran</Td>
                  <Td fontWeight="bold">
                    {activitiesDetailData?.order['payment_method']
                      ? paymentMethodFormatter(
                          activitiesDetailData?.order['payment_method']
                        )
                      : '-'}
                  </Td>
                </Tr>
                {activitiesDetailData?.order?.payment ? (
                  <>
                    <Tr>
                      <Td>Transfer ke Bank</Td>
                      <Td fontWeight="bold">
                        {activitiesDetailData?.order?.payment?.bank_name || '-'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Atas Nama</Td>
                      <Td fontWeight="bold">
                        {activitiesDetailData?.order?.payment?.bank_account ||
                          '-'}
                      </Td>
                    </Tr>
                  </>
                ) : null}
              </Tbody>
            </Table>
          </TableContainer>
          <Box px="4">
            <HStack
              my="3"
              spacing="4"
              direction="row"
              justifyContent="space-between"
            >
              <Text fontSize="14px" pb="2" pt="4">
                Produk
              </Text>
              <Box display="flex" flexDir="column" gap="2">
                <TextField
                  fontWeight="bold"
                  width="25rem"
                  name="total_po_cart"
                  placeholder={'Total PO Cart'}
                  register={methods.register}
                  value={`Total PO Cart: ${adminCurrencyFormatter(
                    activitiesDetailData?.order_meta_total
                  )}`}
                  isDisabled
                  _disabled={{
                    color: 'black',
                  }}
                />
                <TextField
                  fontWeight="bold"
                  width="25rem"
                  name="total"
                  placeholder={'Total Nilai Order'}
                  register={methods.register}
                  value={`Total Order Per Divisi: ${adminCurrencyFormatter(
                    activitiesDetailData?.total
                  )}`}
                  isDisabled
                  _disabled={{
                    color: 'black',
                  }}
                />
              </Box>
            </HStack>
            <DataTable
              columns={columns}
              data={activitiesPromoData ? activitiesPromoData : []}
            />
          </Box>
          <Box px="4">
            <Text fontSize="14px" pt="4" pb="2">
              Lampiran
            </Text>
            <Box py="2" display={pdfUrls.length === 0 ? 'none' : 'block'}>
              <Button
                isDisabled={pdfUrls.length === 0}
                onClick={() => openMultipleLinks(pdfUrls)}
              >
                Download PDF
              </Button>
            </Box>
            {imageUrls.map((url: string, idx: number) => {
              return (
                <ActivitiesImagePayment
                  key={`payment-${idx}`}
                  url={url}
                  alt={`Bukti Pembayaran-${idx}`}
                  objectFit="cover"
                  loading="lazy"
                  pb={2}
                />
              );
            })}
          </Box>
        </Box>
      </FormProvider>
    </AdminLayout>
  );
}
