import {
  BackofficeRecipeSectionItemCreateParams,
  BackofficeRecipeSectionItemCreateResponse,
} from '@diamond/shared/types';
import { BackofficeRecipeSectionItemCreateBody } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation } from '@tanstack/react-query';

export const createRecipeSectionItem = async (
  token: string,
  params: BackofficeRecipeSectionItemCreateParams,
  body: BackofficeRecipeSectionItemCreateBody
) => {
  const url = `/backoffice/recipe/section/${params.id}/item`;
  const { data } = await apiInstance(
    token
  ).post<BackofficeRecipeSectionItemCreateResponse>(url, body);
  return data;
};
type useCreateItemRecipeSectionMutationArgs = {
  onSuccess: () => void;
  onSettled: () => void;
};

export function useRecipeSectionItemCreateList({
  onSuccess,
  onSettled,
}: useCreateItemRecipeSectionMutationArgs) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: BackofficeRecipeSectionItemCreateParams;
      body: BackofficeRecipeSectionItemCreateBody;
    }) => createRecipeSectionItem(token, params, body),
    onSuccess,
    onSettled,
  });
}
