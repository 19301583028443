import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    solid: {
      _hover: {
        bg: 'azure',
      },
      _active: {
        bg: 'blue',
      },
      _disabled: {
        bg: 'gray.33',
        color: 'gray.75',
      },
      bg: 'blue',
      textColor: 'white',
      borderColor: 'blue',
    },
    outline: {
      _hover: {
        bg: 'white',
        borderColor: 'azure',
        textColor: 'azure',
      },
      _active: {
        bg: 'blue',
        color: 'white',
        borderColor: 'blue',
      },
      _disabled: {
        bg: 'white',
        color: 'gray.75',
        borderColor: 'gray.75',
      },
      borderColor: 'blue',
      color: 'blue',
    },
    ghost: {
      _hover: {
        bg: 'gray.33',
        textColor: 'blue',
      },
      _active: {
        bg: 'blue',
        color: 'white',
        borderColor: 'blue',
      },
      color: 'blue',
      bgColor: 'gray.customGray',
    },
    toolbar: {
      border: '1px',
      borderColor: 'gray.75',
      color: 'black',
      bgColor: 'white',
    },
    toolbarSolid: {
      color: 'white',
      bgColor: 'black',
    },
    defaultProps: {
      size: 'md',
      variant: 'outline',
    },
  },
};
