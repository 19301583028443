import { Box, BoxProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

export const List = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BoxProps>
>(({ children, ...props }, ref) => {
  const size = 20;
  return (
    <Box
      ref={ref}
      marginInlineStart={`calc(1.5em + ${size}px)`}
      listStyleType="none"
      sx={{
        counterReset: 'recipe',
        '& li': {
          counterIncrement: 'recipe',
          position: 'relative',
          margin: '0 0 0.5rem 0',
          '&::before': {
            content: "''",
            position: 'absolute',
            top: '.2rem',
            left: `calc(-1 * ${size}px - 20px)`,
            width: `${size}px`,
            height: `${size}px`,
            background: 'azure',
            transform: 'rotate(45deg)',
            borderRadius: 'md',
          },
          '&::after': {
            content: 'counter(recipe)',
            position: 'absolute',
            top: '.2rem',
            textAlign: 'center',
            left: `calc(-1 * ${size}px - 20px)`,
            width: `${size}px`,
            height: `${size}px`,
            lineHeight: `${size}px`,
            color: 'white',
            fontWeight: '500',
            fontSize: 'xs',
          },
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
});

List.displayName = 'List';
