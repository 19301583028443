import { QueryStringSearch } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import cleanDeep from 'clean-deep';

export const getProductAdditional = async (
  token: string,
  activitiesId: string,
  params: Readonly<QueryStringSearch>
) => {
  const url = `/backoffice/order/${activitiesId}/additional-products`;
  const { data } = await apiInstance(token).get(url, {
    params: cleanDeep(params),
  });
  return data;
};
