import {
  BackofficeRecipeSectionItemCreateParams,
  BackofficeRecipeSectionItemCreateResponse,
} from '@diamond/shared/types';
import { BackofficeRecipeSectionItemCreateBody } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BACKOFFICE_RECIPE_QUERY_SECTION_KEY } from './get-recipe-section';

export const createRecipeSectionItem = async (
  token: string,
  params: BackofficeRecipeSectionItemCreateParams,
  body: BackofficeRecipeSectionItemCreateBody
) => {
  const url = `/backoffice/recipe/section/${params.id}/item`;
  const { data } = await apiInstance(
    token
  ).post<BackofficeRecipeSectionItemCreateResponse>(url, body);
  return data;
};

export function useRecipeSectionItemCreateQuery() {
  const queryClient = useQueryClient();
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: BackofficeRecipeSectionItemCreateParams;
      body: BackofficeRecipeSectionItemCreateBody;
    }) => createRecipeSectionItem(token, params, body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY],
      });
    },
  });
}
