import { Flex, FlexProps, Stack, Text } from '@chakra-ui/react';

export function RecipeSectionHeading(props: FlexProps) {
  return (
    <Flex
      alignItems="center"
      maxW="container.xl"
      w="full"
      px="5"
      py="6"
      bg="#0000A310"
      borderTopRadius="xl"
      {...props}
    >
      <Flex
        w="full"
        flex="1"
        alignItems="center"
        gap="4"
        justifyContent="space-between"
      >
        <Stack flex="0 0 80px"></Stack>
        <Stack flex="0 0 60%">
          <Text fontWeight="semibold" fontSize="sm">
            Judul Section
          </Text>
        </Stack>
        <Stack flex="1 0 30%">
          <Text fontWeight="semibold" fontSize="sm">
            Action
          </Text>
        </Stack>
      </Flex>
    </Flex>
  );
}
