import { Box, Stack } from '@chakra-ui/react';
import {
  Step,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
} from '@diamond/shared/ui';

const steps = [
  { title: 'Informasi Resep' },
  { title: 'Deskripsi' },
  { title: 'Bahan & Produk' },
  { title: 'Instruksi Memasak' },
];

export function RecipeStepper({ currentIndex }: { currentIndex: number }) {
  const { activeStep } = useSteps({
    index: currentIndex,
    count: steps.length,
  });
  return (
    <Box maxW="container.lg" mx="auto">
      <Stepper index={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <Stack alignItems="center">
              <StepIndicator mt="7">
                <StepStatus
                  complete={<StepNumber />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0" textAlign="center">
                <StepTitle>{step.title}</StepTitle>
              </Box>
            </Stack>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
