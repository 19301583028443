import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import {
  ActionMeta,
  ChakraStylesConfig,
  CreatableProps as ChakraReactSelectCreatableProps,
  CreatableSelect,
  GroupBase,
} from 'chakra-react-select';
import { KeyboardEventHandler, useMemo, useState } from 'react';
import {
  ArrayPath,
  FieldArray,
  FieldArrayMethodProps,
  FieldArrayWithId,
  FieldValues,
  UseFieldArrayProps,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';

interface CreatableProps<
  Option extends Record<'id', string>,
  Group extends GroupBase<Option> = GroupBase<Option>,
  FormValues extends FieldValues = FieldValues,
  TName extends ArrayPath<FormValues> = ArrayPath<FormValues>
> extends Omit<
      ChakraReactSelectCreatableProps<Option, true, Group>,
      'name' | 'defaultValue'
    >,
    UseFieldArrayProps<FormValues, TName> {
  fieldArray: UseFieldArrayReturn<FormValues, TName, 'id'>;
  label?: string;
  labelProps?: FormLabelProps;
  transformToValue: (
    option: Option
  ) => FieldArrayWithId<FormValues, TName, 'id'>;
  transformToOption: (
    value: FieldArrayWithId<FormValues, TName, 'id'>
  ) => Option;
  handleActionMetaChange?: (actionMeta: ActionMeta<Option>) => void;
  transformInputToValue: (inputVal: string) => FieldArray<FormValues, TName>;
  appendOpts?: FieldArrayMethodProps;
}

export function FieldArrayCreatable<
  Option extends Record<'id', string>,
  Group extends GroupBase<Option> = GroupBase<Option>,
  FormValues extends FieldValues = FieldValues,
  TName extends ArrayPath<FormValues> = ArrayPath<FormValues>
>({
  fieldArray,
  name,
  label,
  labelProps,
  options,
  rules,
  transformToValue,
  transformToOption,
  transformInputToValue,
  appendOpts,
  ...creatableProps
}: CreatableProps<Option, Group, FormValues, TName>) {
  const [inputValue, setInputValue] = useState('');
  const {
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const chakraStyles: ChakraStylesConfig<Option, true, Group> = useMemo(
    () => ({
      valueContainer: (provided) => ({
        ...provided,
        px: 2,
      }),
    }),
    []
  );

  const { fields, append, replace, remove } = fieldArray;

  const values = fields.map(transformToOption);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        clearErrors(name);
        append(transformInputToValue(inputValue), appendOpts);
        setInputValue('');
        event.preventDefault();
      }
    }
  };

  return (
    <FormControl label={label} id={name} isInvalid={!!errors[name]?.message}>
      {label && (
        <FormLabel aria-label={label} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <CreatableSelect
        components={{
          DropdownIndicator: null,
        }}
        chakraStyles={chakraStyles}
        options={options}
        {...creatableProps}
        isMulti
        menuIsOpen={false}
        inputValue={inputValue}
        onKeyDown={handleKeyDown}
        onInputChange={(newValue) => setInputValue(newValue)}
        value={values}
        onChange={(_, actionMeta) => {
          if (actionMeta.action === 'clear') {
            replace([]);
          } else if (
            actionMeta.action === 'remove-value' ||
            actionMeta.action === 'pop-value'
          ) {
            remove(
              values.findIndex((p) => p.id === actionMeta.removedValue?.id)
            );
          }
        }}
      />
      <FormErrorMessage textColor="red">
        {errors[name]?.message as string}
      </FormErrorMessage>
    </FormControl>
  );
}
