import {
  Box,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { BackofficeRecipeSectionListResponse } from '@diamond/shared/types';
import { showToast } from '@diamond/shared/ui';
import { DeleteOutline } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';

import {
  BACKOFFICE_RECIPE_QUERY_SECTION_KEY,
  useDeleteRecipeSectionMutation,
} from '../api';

type RecipeSectionListProps = {
  section: BackofficeRecipeSectionListResponse['data'][number];
  action: React.ReactNode;
  isSortable?: boolean;
  isDeletable?: boolean;
};

const sortableStyles: FlexProps = {
  p: 4,
  shadow: 'sm',
  borderWidth: '1px',
  backgroundColor: 'gray.50',
};

export function RecipeSectionListItem({
  section,
  isSortable,
  action,
  isDeletable,
}: RecipeSectionListProps) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const deleteMutation = useDeleteRecipeSectionMutation({
    onSuccess: () => {
      showToast(toast, 'success', 'Berhasil Menghapus section');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY],
      });
    },
  });

  const onDelete = async (id: string) => {
    await deleteMutation.mutate(id);
  };

  return (
    <Flex
      w="full"
      flex="0 0 120px"
      alignItems="center"
      gap="4"
      justifyContent="space-between"
      {...(!isSortable ? sortableStyles : {})}
    >
      {!isSortable ? <Box w="80px" /> : null}
      <Stack
        ml={isSortable ? '30px' : '0'}
        flex={isSortable ? '0 0 66%' : '0 0 60%'}
      >
        <Text fontSize="sm" fontWeight="medium">
          {section.title}
        </Text>
      </Stack>
      <HStack flex="1 0 30%">
        {action}{' '}
        {isDeletable ? (
          <IconButton
            bgColor="red.500"
            aria-label="Edit section"
            icon={<DeleteOutline />}
            onClick={() => {
              onDelete(section.id);
            }}
          />
        ) : null}
      </HStack>
    </Flex>
  );
}
