import {
  Box,
  Center,
  HStack,
  IconButton,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { usePaginationAdmin } from '@diamond/shared/context';
import {
  DeleteDataWrapper,
  PatchDataWrapper,
  PostDataWrapper,
} from '@diamond/shared/data-access';
import {
  BackofficeActivitiesOrderItem,
  BackofficeOrderDetail,
} from '@diamond/shared/types';
import {
  Breadcrumbs,
  Button,
  DataTable,
  DISPLAY_IN_LIMITS,
  Divider,
  Dropdown,
  Icon,
  LoadingOverlay,
  Modal,
  PageLoader,
  Pagination,
  showToast,
  Text,
  TextField,
} from '@diamond/shared/ui';
import {
  getSAPErrorMessage,
  sapErrors,
  statusFormatter,
} from '@diamond/shared/utils';
import { adminCurrencyFormatter } from '@diamond/shared/utils';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import {
  DeleteOutlined as DeleteOutlinedIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';
import { ColumnDef, Row } from '@tanstack/react-table';
import cleanDeep from 'clean-deep';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useActivitiesDetail, useCancelReason } from '../api';
import {
  EditableColumns,
  infoColumns,
  quantityColumns,
} from '../components/activity-detail-columns';
import { ActivitiesDetailnfo } from '../components/activity-detail-info';
import { FormAddProduct } from '../components/form-add-product';
import { OrderHistoryModal } from '../components/order-history-modal';

const CANCEL_ORDER_URL = 'backoffice/order/cancel';
const SUBMIT_ORDER_URL = 'backoffice/order/submit';
const SUBMIT_ADD_PRODUCT_URL = 'backoffice/order/additional-products/submit';

export function ActivitiesDetailPage() {
  const params = useParams();
  const toast = useToast();
  const activitiesId = params['activitiesId'] as string;
  const methods = useForm();
  const navigate = useNavigate();
  const session = useAuthStore();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const [reload, setReload] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Activities',
      link: '/activities',
    },
    {
      title: 'Detail',
      link: `/activities/${activitiesId}`,
    },
  ];

  const { patchData: cancelOrder } = PatchDataWrapper(
    CANCEL_ORDER_URL,
    session.access_token,
    'admin'
  );
  const { patchData: submitOrder } = PatchDataWrapper(
    SUBMIT_ORDER_URL,
    session.access_token,
    'admin',
    (error, message) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (sapErrors.includes(error as any)) {
        return getSAPErrorMessage(error);
      }
      return message || '';
    }
  );
  const { postData: postAdditionalProduct } = PostDataWrapper(
    SUBMIT_ADD_PRODUCT_URL,
    session.access_token,
    false,
    'admin'
  );

  const { deleteDataWithCustomObject: deleteAdditionalProduct } =
    DeleteDataWrapper(
      `backoffice/order/${activitiesId}`,
      session.access_token,
      'admin'
    );

  const [selectedData, setSelectedData] = useState<
    Row<BackofficeActivitiesOrderItem>[]
  >([]);

  const showDeleteItem = selectedData.every(
    (e) => e.original.is_new_product === true
  );

  const { cancelReasonData } = useCancelReason({
    size: 50,
    page: 1,
  });

  const queryString = {
    order_by: '',
    direction: '',
    size: size,
    page: page,
  };

  const { activitiesDetailData, isLoading, pageCount, refetch, isFetching } =
    useActivitiesDetail(activitiesId, queryString);

  const cancelReasonId = activitiesDetailData?.order.cancel_reason_id;
  const cancelReason = cancelReasonId
    ? cancelReasonData?.cancelReasonMap[cancelReasonId].reason
    : '';

  const activitiesDiscountData = activitiesDetailData?.order_items.map(
    (discount) => {
      const matchDiscount =
        activitiesDetailData.order.promos.compiled_product_discounts.find(
          (discount2) => discount2.product_id === discount.product_id
        );

      return matchDiscount ? { ...discount, ...matchDiscount } : discount;
    }
  );

  const activitiesSingleFreegood = activitiesDiscountData?.flatMap(
    (discount) => {
      const matchFreegoodPromo =
        activitiesDetailData?.order.promos.single_free_good_promos.find(
          (freeGoods) => freeGoods.ref_product_id === discount.product_id
        );

      return matchFreegoodPromo
        ? [discount, { ...discount, ...matchFreegoodPromo }]
        : [discount];
    }
  );
  const activitiesPromoData = activitiesSingleFreegood?.flatMap((discount) => {
    const matchFreegoodPromo =
      activitiesDetailData?.order.promos.bundled_free_good_promos.find(
        (freeGoods) => freeGoods.ref_product_id === discount.product_id
      );

    return matchFreegoodPromo
      ? [discount, { ...discount, ...matchFreegoodPromo }]
      : [discount];
  });

  const { nextPage, previousPage, lastPage, firstPage } = usePaginationAdmin(
    page,
    setPage,
    refetch,
    pageCount
  );

  const {
    onOpen: onOpenSubmit,
    onClose: onCloseSubmit,
    isOpen: isOpenSubmit,
  } = useDisclosure();
  const {
    onOpen: onOpenCancelOrder,
    onClose: onCloseCancelOrder,
    isOpen: isOpenCancelOrder,
  } = useDisclosure();

  const {
    isOpen: isOpenAddProduct,
    onOpen: onOpenAddProduct,
    onClose: onCloseAddProduct,
  } = useDisclosure();

  const {
    isOpen: isOpenOrderHistory,
    onOpen: onOpenOrderHistory,
    onClose: onCloseOrderHistory,
  } = useDisclosure();

  const filteredCancelOrder = cancelReasonData
    ? cancelReasonData?.data.filter(
        (value) => value.reason_type.toLowerCase() === 'order'
      )
    : [];

  const filteredCancelItem = cancelReasonData
    ? cancelReasonData?.data.filter(
        (value) => value.reason_type.toLowerCase() === 'item'
      )
    : [];

  const cancelReasonPerOrder = filteredCancelOrder.map((value) => {
    return {
      label: value.reason,
      value: value.reason_id,
    };
  });

  const cancelReasonPerItem = [
    { label: '', value: '' },
    ...filteredCancelItem.map((value) => {
      return {
        label: value.reason,
        value: value.reason_id,
      };
    }),
  ];
  const statusData = activitiesDetailData?.order.status;
  const isDisable = statusData !== 'submitted' ? true : false;

  const editableColumns = EditableColumns(
    setSelectedData,
    selectedData,
    refetch,
    activitiesId,
    cancelReasonPerItem,
    isDisable,
    [`Activities-${activitiesId}`, page, cleanDeep(queryString)]
  );

  const submitNewProduct = useMutation({
    mutationFn: async (payload: unknown) => {
      postAdditionalProduct(payload)
        .then(() => {
          refetch();
          showToast(toast, 'success', 'Produk baru berhasil ditambahkan!');
          onCloseAddProduct();
        })
        .catch((error) => {
          showToast(toast, 'error', error.message);
          if (
            error.message === 'Terdapat produk yang sudah ditambahkan ke order'
          ) {
            refetch();
            onCloseAddProduct();
          }
        });
    },
  });

  const deleteNewProduct = useMutation({
    mutationFn: async () => {
      const product_ids = selectedData.map(
        (selected) => selected.original.product_id
      );
      deleteAdditionalProduct('item', { product_ids })
        .then(() => {
          refetch();
          setSelectedData([]);
          setReload(!reload);
          showToast(toast, 'success', 'Produk berhasil dihapus!');
        })
        .catch((err) => {
          showToast(toast, 'error', err.message);
        });
    },
  });

  const handleDeleteAdditionalProduct = async () => {
    deleteNewProduct.mutate();
  };

  if (isLoading) {
    return (
      <AdminLayout>
        <PageLoader />
      </AdminLayout>
    );
  }

  const handleSubmit = () => {
    setIsLoadingSubmit(true);
    onCloseSubmit();
    submitOrder('', activitiesId)
      .then(async () => {
        showToast(toast, 'success', 'Berhasil Submit Order');
        setIsLoadingSubmit(false);
        window.location.reload();
      })
      .catch((error) => {
        showToast(toast, 'error', error.message);
        setIsLoadingSubmit(false);
        refetch();
      });
  };

  const handleCancel = () => {
    if (activitiesDetailData?.order.cancel_reason_id === null) {
      showToast(
        toast,
        'error',
        'Alasan pembatalan kosong. Silakan mengisi alasan pembatalan untuk membatalkan pesanan.'
      );
      onCloseCancelOrder();
    } else {
      cancelOrder('', activitiesId)
        .then(async () => {
          showToast(toast, 'success', 'Berhasil Cancel Order');
          navigate('/activities');
        })
        .catch((error) => {
          showToast(toast, 'error', error.message);
        });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableColumns: ColumnDef<any, any>[] = [
    ...editableColumns.checkboxColumn,
    ...infoColumns,
    ...editableColumns.editableColumns,
    ...quantityColumns,
  ];
  const isMenuDisabled =
    statusData === 'waiting_for_payment' || statusData === 'submitted';

  return (
    <AdminLayout maxWidth="full">
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <LoadingOverlay
        isLoading={isLoadingSubmit}
        text="Submitting Your Order, Please Don't Refresh This Page!"
      />
      <FormProvider {...methods}>
        <Box>
          <HStack
            direction="row"
            justifyContent="space-between"
            verticalAlign="center"
          >
            <Text fontSize="xl" fontWeight="bold">
              Activity Detail
            </Text>
          </HStack>
          <Divider />
          <HStack my="3" spacing="4" direction="row">
            {selectedData.length > 0 && showDeleteItem ? (
              <IconButton
                aria-label="Delete"
                icon={<DeleteOutlinedIcon />}
                fontSize="xs"
                variant="solid"
                borderColor="gray.33"
                background="red"
                color="white"
                onClick={handleDeleteAdditionalProduct}
              />
            ) : null}
            <Button
              variant="ghost"
              onClick={onOpenAddProduct}
              display={
                activitiesDetailData?.order.status === 'submitted'
                  ? 'flex'
                  : 'none'
              }
              data-testid="add-more-product"
            >
              Tambah Produk
            </Button>
            <Box px="4">
              <Text fontSize="md" color="blue">
                {activitiesDetailData?.order.count_items} Produk
              </Text>
            </Box>
            <Spacer />
            <Box
              py="2"
              px="4"
              borderRadius="lg"
              bgColor="#0000a31a"
              color="blue"
            >
              <Text>
                Status:{' '}
                {statusFormatter(
                  statusData,
                  cancelReason,
                  activitiesDetailData?.order.sap_note || ''
                )}
              </Text>
            </Box>
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  aria-label="menu"
                  variant="ghost"
                  name="submit-button"
                  color="blue"
                  bgColor="#0000a31a"
                  pt={1}
                  px={0}
                >
                  <Icon as={MenuIcon} />
                </MenuButton>
                <Portal>
                  <MenuList>
                    <MenuItem
                      as={ChakraLink}
                      href={activitiesDetailData?.order.delivery_link}
                      isDisabled={isMenuDisabled}
                      isExternal
                    >
                      Delivery Info
                    </MenuItem>
                    <MenuItem
                      as={ChakraLink}
                      href={activitiesDetailData?.order.invoice_link}
                      isDisabled={isMenuDisabled}
                      isExternal
                    >
                      Invoice
                    </MenuItem>
                    <MenuItem onClick={onOpenOrderHistory}>
                      Riwayat Pesanan
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
            <Box>
              <TextField
                name="total"
                placeholder={'Total Nilai Order'}
                register={methods.register}
                value={`Total: ${adminCurrencyFormatter(
                  activitiesDetailData?.total
                )}`}
                isDisabled
              />
            </Box>
            <Box justifyContent="flex-end">
              <Button
                px="4"
                mx="2"
                variant="solid"
                onClick={onOpenSubmit}
                isDisabled={isDisable}
              >
                Submit
              </Button>
              <Button
                px="4"
                borderColor="red"
                color="red"
                onClick={onOpenCancelOrder}
                isDisabled={
                  statusData !== 'submitted' &&
                  statusData !== 'waiting_for_payment'
                }
              >
                Batalkan
              </Button>
            </Box>
          </HStack>
        </Box>
        <Divider />
        <ActivitiesDetailnfo
          data={activitiesDetailData as BackofficeOrderDetail}
          cancelReasonData={cancelReasonPerOrder}
          refetch={refetch}
        />
        <Divider />
        <Box my={8} width="full">
          <DataTable
            key={reload.toString()}
            columns={tableColumns}
            data={activitiesPromoData as BackofficeActivitiesOrderItem[]}
            isLoading={isFetching}
          />
          <Center my={8}>
            <Pagination
              currentPage={page}
              totalPage={pageCount}
              nextPage={nextPage}
              firstPage={firstPage}
              lastPage={lastPage}
              previousPage={previousPage}
            />
            <Box ml={8}>
              <HStack>
                <Text fontSize="sm">Lihat</Text>
                <Dropdown
                  register={methods.register}
                  name="page-size"
                  placeholder=""
                  items={DISPLAY_IN_LIMITS}
                  defaultValue="15"
                  onClick={() => setSize(methods.watch('page-size'))}
                />
                <Text fontSize="sm">Per&nbsp;halaman</Text>
              </HStack>
            </Box>
          </Center>
        </Box>
        <Divider />
        <Modal
          name="cancel_order"
          isOpen={isOpenCancelOrder}
          onClose={onCloseCancelOrder}
          labelSubmit="Ya"
          labelCancel="Tidak"
          buttonColor="red"
          onSubmit={handleCancel}
        >
          <Center>
            <Text m="10" align="center" fontSize="2xl" as="b">
              Cancel Order?
            </Text>
          </Center>
        </Modal>
        <Modal
          name="submit_order"
          isOpen={isOpenSubmit}
          onClose={onCloseSubmit}
          onSubmit={handleSubmit}
          isLoading={isLoadingSubmit}
        >
          <Center>
            <Text m="10" align="center" fontSize="2xl" as="b">
              Submit Order?
            </Text>
          </Center>
        </Modal>
        <Modal
          title="Tambah Produk"
          size="2xl"
          name="form-add-product"
          isOpen={isOpenAddProduct}
          onClose={() => {
            onCloseAddProduct();
          }}
          isCentered
          hideAction
          closeOnOverlayClick={false}
          modalContentProps={{
            maxH: '100%',
            mt: 0,
          }}
        >
          <FormAddProduct
            activitiesId={activitiesId}
            addProductToActivity={submitNewProduct}
            onClose={onCloseAddProduct}
            refetch={refetch}
            division={activitiesDetailData?.order.division}
          />
        </Modal>
        <OrderHistoryModal
          isOpen={isOpenOrderHistory}
          onClose={onCloseOrderHistory}
          selectedDataId={activitiesId}
        />
      </FormProvider>
    </AdminLayout>
  );
}
