import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';

import { useStepContext, useStepperStyles } from './step-context';

export type StepNumberProps = HTMLChakraProps<'div'>;

export const StepNumber = forwardRef<StepNumberProps, 'div'>(
  function StepNumber(props, ref) {
    const { children, ...restProps } = props;
    const { status, index } = useStepContext();
    const styles = useStepperStyles();
    return (
      <chakra.div
        ref={ref}
        data-status={status}
        __css={styles['number']}
        {...restProps}
        className={props.className}
      >
        {children || index + 1}
      </chakra.div>
    );
  }
);
