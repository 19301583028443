import './autocomplete.css';

import { SearchProvider } from '@diamond/shared/context';
import { AuthProvider } from '@diamond/sol-admin/features/auth';
import { AppRoutes } from '@diamond/sol-admin/routes';
import { CookieUniversalProvider } from '@shopify/react-cookie';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient();

export function App() {
  return (
    <CookieUniversalProvider>
      <QueryClientProvider client={queryClient}>
        <SearchProvider>
          <BrowserRouter>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </BrowserRouter>
        </SearchProvider>

        <ReactQueryDevtools
          initialIsOpen={false}
          position="right"
          buttonPosition="bottom-right"
        />
      </QueryClientProvider>
    </CookieUniversalProvider>
  );
}

export default App;
