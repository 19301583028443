import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BackofficeUpdateRecipeListResponse } from '@diamond/shared/types';
import {
  CalendarMonthOutlined,
  FavoriteBorder,
  FavoriteOutlined,
  ShareOutlined,
  WarningOutlined,
} from '@mui/icons-material';
import { format } from 'date-fns';

import { ShareButtonPopover } from '../share-button-popover/share-button-popover';
import { RecipeTag } from './recipe-tag';
import { ViewCount } from './view-count';

export interface RecipesDescriptionCardProps<
  TData extends Omit<BackofficeUpdateRecipeListResponse, 'cg_3_business_types'>
> {
  recipe: TData;
  handleFavorite?: (id: string) => void;
  isFavorite?: boolean;
  player?: React.ReactNode;
  isLivePreview?: boolean;
}

export const RecipeDescriptionCard = <
  TData extends Omit<BackofficeUpdateRecipeListResponse, 'cg_3_business_types'>
>({
  recipe,
  handleFavorite,
  isFavorite = false,
  player,
  isLivePreview = false,
}: RecipesDescriptionCardProps<TData>) => {
  if (!recipe.title && !recipe.author) return null;
  const fullUrl = typeof window !== 'undefined' ? window.location.href : '';
  const shareWording =
    'Dapatkan inspirasi resep terkini dari chef terbaik, eksklusif hanya di Sukanda OneLink. Yuk, cek sekarang juga!';

  return (
    <Box borderRadius="lg" overflow="hidden" p="4" bg="white">
      <VStack alignItems="flex-start" spacing={3}>
        <Flex justifyContent="space-between" w="full">
          <Box>
            <Text
              fontSize={isLivePreview ? 'xl' : { base: 'xl', xl: '3xl' }}
              textColor="#363554"
              fontWeight="semibold"
              wordBreak="break-word"
            >
              {recipe.title}
            </Text>
            {recipe.author ? (
              <Text
                fontSize={isLivePreview ? 'sm' : { md: 'xs', xl: 'sm' }}
                color="gray.500"
                wordBreak="break-word"
              >
                Dibuat oleh{' '}
                <Text as="span" fontWeight="semibold">
                  {recipe.author}
                </Text>
              </Text>
            ) : null}
            {recipe.created_at ? (
              <Flex gap={2} color="gray.75">
                <Flex alignItems="center" gap="1">
                  <Icon
                    as={CalendarMonthOutlined}
                    fontSize={
                      isLivePreview ? 'xs' : { base: 'xs', md: 'xs', xl: 'sm' }
                    }
                  />
                  <Text
                    fontSize={
                      isLivePreview ? 'xs' : { base: 'xs', md: 'xs', xl: 'sm' }
                    }
                    p={0.5}
                    mr={2}
                  >
                    {format(recipe.created_at, 'dd MMM yyyy')}
                  </Text>
                </Flex>
                <ViewCount count={recipe.views} />{' '}
              </Flex>
            ) : null}
          </Box>
          {recipe.slug ? (
            <Box flex="0 0 100px">
              <Flex gap={2}>
                <Box textAlign="center">
                  <ShareButtonPopover
                    title="Bagikan resep"
                    url={fullUrl}
                    textToShare={shareWording}
                  >
                    <IconButton
                      icon={<ShareOutlined />}
                      aria-label="Share Recipe"
                      variant="link"
                      fontWeight="bold"
                      textColor="black"
                    />
                  </ShareButtonPopover>
                  <Text
                    fontSize={
                      isLivePreview
                        ? '2xs'
                        : { base: '2xs', md: 'xs', xl: 'sm' }
                    }
                  >
                    Bagikan
                  </Text>
                </Box>
                <Box textAlign="center">
                  <IconButton
                    icon={
                      isFavorite ? <FavoriteOutlined /> : <FavoriteBorder />
                    }
                    aria-label="Favorite"
                    variant="link"
                    fontWeight="bold"
                    textColor="red.600"
                    onClick={() => {
                      if (handleFavorite) {
                        handleFavorite(recipe.id);
                      }
                    }}
                  />
                  <Text
                    fontSize={
                      isLivePreview
                        ? '2xs'
                        : { base: '2xs', md: 'xs', xl: 'sm' }
                    }
                  >
                    {isFavorite ? 'Tersimpan' : 'Simpan'}
                  </Text>
                </Box>
              </Flex>
            </Box>
          ) : null}
        </Flex>
        <HStack spacing={1} flexWrap="wrap" rowGap={2}>
          {recipe.tags?.map((t) => {
            return <RecipeTag key={t.id} tag={t} />;
          })}
        </HStack>
        <Text
          mt="3"
          whiteSpace="pre-wrap"
          fontSize={isLivePreview ? 'sm' : { base: 'sm', xl: 'md' }}
          textAlign={{ base: 'justify', md: 'left' }}
        >
          {recipe.short_desc}
        </Text>
      </VStack>

      <Flex
        align={isLivePreview ? 'center' : { md: 'start', xl: 'center' }}
        justify={recipe.allergens.length > 0 ? 'space-between' : 'flex-end'}
        fontSize="sm"
        gap="2"
        mt="3"
      >
        {recipe.allergens.length > 0 ? (
          <Flex
            align="center"
            bg={{ base: 'white', md: 'white' }}
            borderRadius="md"
          >
            <Icon as={WarningOutlined} color="#F97316" boxSize={5} mr={2} />
            <Text fontSize={{ base: '2xs', md: 'xs', xl: 'sm' }}>
              Mengandung bahan alergen:{' '}
              <Text as="span" fontWeight="bold">
                {recipe.allergens.join(', ')}
              </Text>
            </Text>
          </Flex>
        ) : null}
        {player && !isLivePreview ? player : null}
      </Flex>
    </Box>
  );
};
