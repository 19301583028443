import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';

import { useStepContext, useStepperStyles } from './step-context';

export type StepDescriptionProps = HTMLChakraProps<'p'>;

export const StepDescription = forwardRef<StepDescriptionProps, 'p'>(
  function StepDescription(props: StepDescriptionProps, ref) {
    const { status } = useStepContext();
    const styles = useStepperStyles();
    return (
      <chakra.p
        ref={ref}
        data-status={status}
        {...props}
        className={props.className}
        __css={styles['description']}
      />
    );
  }
);
