import { RecipeIngredientsSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  recipeResponseToState,
  useRecipeDetail,
  useUpdateRecipeMutation,
} from '../../api';
import { RecipeIngredientsForm } from '../../components/forms/recipe-ingredients-form';
import { RecipeFormLayout } from '../../components/layouts/recipe-form-layout';

export function RecipeIngredientDetail() {
  const params = useParams();
  const recipeId = params['recipeId'] as string;

  const { data } = useRecipeDetail();
  const navigate = useNavigate();

  const form = useForm({
    // https://react-hook-form.com/faqs#Howtoinitializeformvalues
    values: data ? recipeResponseToState(data) : undefined,
    resetOptions: {
      keepDirtyValues: true,
    },
    resolver: yupResolver(RecipeIngredientsSchema),
  });

  const updateRecipeMutation = useUpdateRecipeMutation(recipeId);

  const onSubmit =
    (cb?: () => void) =>
    async (data: ReturnType<typeof recipeResponseToState>) => {
      await updateRecipeMutation.mutateAsync(data);
      cb?.();
    };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit())}>
        <RecipeFormLayout
          section={{
            title: 'Input Bahan & Produk',
            link: 'ingredients',
            index: 2,
          }}
          handleNext={form.handleSubmit(
            onSubmit(() => navigate(`/recipe/list/${recipeId}/instruction`))
          )}
          handlePrev={form.handleSubmit(
            onSubmit(() => navigate(`/recipe/list/${recipeId}/description`))
          )}
        >
          <RecipeIngredientsForm form={form} />
        </RecipeFormLayout>
      </form>
    </FormProvider>
  );
}
