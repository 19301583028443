import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';

export interface TextAreaProps<T extends FieldValues = FieldValues>
  extends TextareaProps {
  name: Path<T>;
  label?: string;
  register: UseFormRegister<T>;
  registerOptions?: RegisterOptions<T>;
  errors?: FieldErrors<T>;
  labelProps?: FormLabelProps;
}

export function TextArea<T extends FieldValues = FieldValues>({
  name,
  label,
  register,
  registerOptions,
  errors,
  labelProps,
  ...props
}: TextAreaProps<T>) {
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';
  return (
    <FormControl id={props.id} isInvalid={isInvalid}>
      {label && (
        <FormLabel aria-label={label} htmlFor={props.id} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <Textarea {...register(name)} {...props} />
      <FormErrorMessage textColor="red">
        {errorMessage as string}
      </FormErrorMessage>
    </FormControl>
  );
}

export default TextArea;
