import { BackofficeStatusHistory } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useQuery } from '@tanstack/react-query';

export const getOrderHistory = async (token: string, activityId: string) => {
  const url = `/backoffice/order/${activityId}/status-history`;
  const { data } = await apiInstance(token).get<BackofficeStatusHistory>(url);
  return data;
};

const ORDER_HISTORY_QUERY_KEY = 'OrderHistory';

export function useOrderHistory(activityId: string | undefined) {
  const { access_token } = useAuthStore();
  return useQuery({
    queryKey: [ORDER_HISTORY_QUERY_KEY, activityId],
    queryFn: () => getOrderHistory(access_token, activityId || ''),
    enabled: !!activityId,
  });
}
