import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Icon,
  Image,
  Link,
  ListItem,
  Spacer,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { BackofficeUpdateRecipeListResponse } from '@diamond/shared/types';
import { buyerCurrencyFormatter, trackEvent } from '@diamond/shared/utils';
import { KeyboardArrowDown } from '@mui/icons-material';

type ProductCard = {
  name: string;
  price: string;
  unit: string;
  img: string;
  url: string;
};

type MaterialCard = {
  name: string;
  composition: string;
};

export type ProductType = {
  title: string;
  product: Array<ProductCard>;
};

export type MaterialType = {
  title: string;
  material: Array<MaterialCard>;
};

type IngredientProductType = 'recommended_products' | 'alternative_products';

export interface RecepiesMaterialAndProductCardProps<
  TData extends Omit<BackofficeUpdateRecipeListResponse, 'cg_3_business_types'>
> {
  recipe: TData;
  type: IngredientProductType;
  isAuthenticated?: boolean;
}

type CollapsibleIngredientProps = {
  type: IngredientProductType;
  ingredient: BackofficeUpdateRecipeListResponse['ingredient_groups'][number]['ingredients'][number];
  isAuthenticated?: boolean;
};

function CollapsibleIngredient({
  ingredient,
  type = 'recommended_products',
  isAuthenticated = false,
}: CollapsibleIngredientProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <ListItem>
      <Button
        variant="unstyled"
        h="auto"
        justifyContent="space-between"
        onClick={() => onToggle()}
        display="flex"
        w="full"
        fontWeight="normal"
        color="azure"
        fontSize="sm"
      >
        <Box
          as="span"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {ingredient.title} {' · '}{' '}
          {ingredient[type][0].brand_desc.charAt(0).toUpperCase() +
            ingredient[type][0].brand_desc.slice(1).toLowerCase()}
        </Box>
        <Flex alignItems="center" gap="1">
          <Box as="span" fontWeight="medium" color="black">
            {ingredient.amount}&nbsp;
            {ingredient.amount_unit}
          </Box>
          <Icon
            fontSize="md"
            as={KeyboardArrowDown}
            pr="0"
            transform="auto"
            transition="transform .1s linear"
            rotate={isOpen ? '180deg' : '0deg'}
          />
        </Flex>
      </Button>
      <Collapse in={isOpen} animateOpacity>
        {ingredient[type].map((product) => (
          <Flex
            bg="gray.customGray"
            justifyContent="space-between"
            alignItems="center"
            key={product.id}
            px={{ base: '3', md: '1', xl: '1' }}
            p={{ base: '1', md: '1', xl: '1' }}
            rounded="lg"
            mt="2"
          >
            <Flex gap={0.5} p={1}>
              <Box
                bg="white"
                alignItems="center"
                mt={1}
                ml={1}
                w="35px"
                h="35px"
                position="relative"
              >
                <Image
                  alt="Recipe  Product Illustration"
                  src={product.product_image_thumbnails?.[0]}
                  objectFit="cover"
                  fallbackSrc={'/assets/images/product_placeholder.png'}
                />
              </Box>
              <Stack fontWeight="light" gap={0} p={1}>
                <Flex gap={0.5}>
                  <Text fontSize={{ base: '11px', md: '7px', xl: '10px' }}>
                    Estimasi HPP per porsi
                  </Text>

                  <Text
                    display={{ base: 'none', lg: 'block' }}
                    fontSize={{ base: '11px', md: '7px', xl: '10px' }}
                  >
                    {' · '}
                  </Text>
                  <Text
                    display={{ base: 'none', lg: 'block' }}
                    fontSize={{ base: '11px', md: '7px', xl: '10px' }}
                  >
                    min.qty
                  </Text>
                </Flex>
                <Flex>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: '11px', md: '7px', xl: '10px' }}
                  >
                    {isAuthenticated
                      ? buyerCurrencyFormatter(product.cogs_per_portion)
                      : 'Rp. xxx'}
                    {'  · '}
                  </Text>
                  <Text
                    fontSize={{ base: '11px', md: '7px', xl: '10px' }}
                    ml={1}
                  >
                    {' '}
                    {product.minimum_order_quantity / 1000}{' '}
                    {product.sales_unit_desc}
                  </Text>
                </Flex>
              </Stack>
            </Flex>
            <Link
              href={`/product/${product.id}`}
              color="blue"
              fontWeight="semibold"
              fontSize={{ base: '11px', md: '7px', xl: '10px' }}
              target="_blank"
              onClick={() =>
                trackEvent(`click_recipe_product`, {
                  id: product.id,
                  brand: product.brand_desc,
                  code: product?.item_code,
                  section_name: 'recipe_detail',
                })
              }
            >
              Lihat Produk
            </Link>
          </Flex>
        ))}
      </Collapse>
    </ListItem>
  );
}

export const RecepiesMaterialAndProductCard = <
  TData extends Omit<BackofficeUpdateRecipeListResponse, 'cg_3_business_types'>
>({
  recipe,
  type = 'recommended_products',
  isAuthenticated = true,
}: RecepiesMaterialAndProductCardProps<TData>) => {
  return (
    <Box>
      {recipe.ingredient_groups.map((group, index) => {
        return (
          <>
            <Stack
              key={group.title}
              p={{ md: 2, xl: 3 }}
              mb="4"
              _last={{
                mb: 0,
              }}
            >
              <Flex>
                <Text fontWeight="bold" ml={-4}>
                  {group.title}
                </Text>
                <Text ml={2}>
                  {group.is_optional ? '(Optional)' : undefined}
                </Text>
              </Flex>

              <UnorderedList spacing={2}>
                {group.ingredients.map((ingredient) => {
                  if (!ingredient.title) return null;

                  if (ingredient[type].length > 0) {
                    return (
                      <CollapsibleIngredient
                        ingredient={ingredient}
                        type={type}
                        isAuthenticated={isAuthenticated}
                      />
                    );
                  }
                  return (
                    <ListItem>
                      <Flex
                        justifyContent="space-between"
                        w="full"
                        fontWeight="normal"
                        fontSize="sm"
                      >
                        <Box as="span">{ingredient.title}</Box>
                        <Flex alignItems="center" gap="1">
                          <Box as="span" fontWeight="medium">
                            {ingredient.amount}
                            &nbsp; {ingredient.amount_unit}
                          </Box>
                          <Spacer w="1rem" />
                        </Flex>
                      </Flex>
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </Stack>
            {index !== recipe.ingredient_groups.length - 1 && (
              <Divider
                mt={4}
                mb="1"
                borderStyle="dashed"
                borderColor="gray.75"
                borderWidth="1px"
                w={{ base: '360px', md: '720px', lg: '360px' }}
                ml={-3}
              />
            )}
          </>
        );
      })}
    </Box>
  );
};
