import { createIcon } from '@chakra-ui/react';

export const PlayButtonIcon = createIcon({
  displayName: 'PlayButtonIcon',
  viewBox: '0 0 24 24',
  path: [
    <mask
      id="mask0_18296_48730"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>,
    <g mask="url(#mask0_18296_48730)">
      <path
        d="M9.78725 15.9167L15.9167 12L9.78725 8.08325V15.9167ZM12.0017 21.5C10.6946 21.5 9.464 21.2507 8.31 20.752C7.156 20.2533 6.14858 19.5738 5.28775 18.7135C4.42708 17.853 3.74725 16.846 3.24825 15.6925C2.74942 14.539 2.5 13.3087 2.5 12.0017C2.5 10.6877 2.74933 9.45267 3.248 8.2965C3.74667 7.14033 4.42617 6.13467 5.2865 5.2795C6.147 4.42433 7.154 3.74725 8.3075 3.24825C9.461 2.74942 10.6912 2.5 11.9982 2.5C13.3122 2.5 14.5473 2.74933 15.7035 3.248C16.8597 3.74667 17.8653 4.42342 18.7205 5.27825C19.5757 6.13308 20.2528 7.13833 20.7517 8.294C21.2506 9.44967 21.5 10.6844 21.5 11.9982C21.5 13.3054 21.2507 14.536 20.752 15.69C20.2533 16.844 19.5766 17.8514 18.7218 18.7122C17.8669 19.5729 16.8617 20.2528 15.706 20.7517C14.5503 21.2506 13.3156 21.5 12.0017 21.5ZM12 20.2435C14.2965 20.2435 16.2446 19.442 17.8442 17.839C19.4438 16.236 20.2435 14.2897 20.2435 12C20.2435 9.7035 19.4438 7.75542 17.8442 6.15575C16.2446 4.55625 14.2965 3.7565 12 3.7565C9.71033 3.7565 7.764 4.55625 6.161 6.15575C4.558 7.75542 3.7565 9.7035 3.7565 12C3.7565 14.2897 4.558 16.236 6.161 17.839C7.764 19.442 9.71033 20.2435 12 20.2435Z"
        fill="currentColor"
      />
    </g>,
  ],
});
