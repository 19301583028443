import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';

import { useStepContext, useStepperStyles } from './step-context';

export type StepTitleProps = HTMLChakraProps<'h3'>;

export const StepTitle = forwardRef<StepTitleProps, 'h3'>(function StepTitle(
  props: StepTitleProps,
  ref
) {
  const { status } = useStepContext();
  const styles = useStepperStyles();
  return (
    <chakra.h3
      ref={ref}
      data-status={status}
      {...props}
      __css={styles['title']}
      className={props.className}
    />
  );
});
