import { Box, Image } from '@chakra-ui/react';
import { useMediaState } from '@udecode/plate-media/react';
import { withRef } from '@udecode/react-utils';

import { PlateElement } from './plate-element';

export const ImageElement = withRef<typeof PlateElement>(
  ({ children, nodeProps, ...props }, ref) => {
    const { focused, selected } = useMediaState();

    const url = props.element['url'] as string;

    return (
      <PlateElement
        ref={ref}
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        {...props}
      >
        <Box
          as="figure"
          position="relative"
          m="0"
          contentEditable={false}
          role="group"
        >
          <Image
            alt=""
            display="block"
            w="100%"
            maxW="100%"
            cursor="pointer"
            objectFit="cover"
            px="0"
            borderRadius="sm"
            {...nodeProps}
            sx={{
              ...(focused &&
                selected && {
                  outline: '2px solid',
                  outlineColor: 'blue.500',
                  outlineOffset: '2px',
                }),
            }}
            src={url}
          />
        </Box>

        {children}
      </PlateElement>
    );
  }
);
