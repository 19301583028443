import { chakra } from '@chakra-ui/react';
import { List, ListItem } from '@diamond/shared/ui';
import { withRef } from '@udecode/react-utils';

import { PlateElement } from './plate-element';

const PlateElementChakra = chakra(PlateElement);

export const ListElement = withRef<typeof PlateElement>(
  ({ children, ...props }, ref) => {
    return (
      <PlateElementChakra asChild ref={ref} {...props}>
        <List>{children}</List>
      </PlateElementChakra>
    );
  }
);

export const UnorderedListElement = withRef<typeof PlateElement>(
  ({ children, ...props }, ref) => {
    return (
      <PlateElementChakra asChild ref={ref} marginInlineStart="1em" {...props}>
        <ul>{children}</ul>
      </PlateElementChakra>
    );
  }
);

export const ListItemElement = withRef<typeof PlateElement>(
  ({ children, ...props }, ref) => {
    return (
      <PlateElementChakra asChild ref={ref} {...props}>
        <ListItem>{children}</ListItem>
      </PlateElementChakra>
    );
  }
);
