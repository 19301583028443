import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import {
  AsyncProps,
  AsyncSelect,
  ChakraStylesConfig,
  OptionBase,
} from 'chakra-react-select';
import { useMemo } from 'react';
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { GroupBase, SingleValue } from 'react-select';

export interface ProductOptions extends OptionBase {
  value: string;
  label: string;
}

interface SelectProductFieldProps<
  Option extends ProductOptions = ProductOptions,
  Group extends GroupBase<Option> = GroupBase<Option>,
  FormValues extends FieldValues = FieldValues,
  TName extends Path<FormValues> = Path<FormValues>
> extends Omit<AsyncProps<Option, false, Group>, 'name' | 'defaultValue'>,
    UseControllerProps<FormValues, TName> {
  label?: string;
  labelProps?: FormLabelProps;
  handleOnSelect?: (val: SingleValue<Option>) => void;
}

export function SelectProductField<
  Option extends ProductOptions,
  Group extends GroupBase<Option> = GroupBase<Option>,
  FormValues extends FieldValues = FieldValues,
  TName extends Path<FormValues> = Path<FormValues>
>({
  control,
  name,
  label,
  labelProps,
  handleOnSelect,
  ...props
}: SelectProductFieldProps<Option, Group, FormValues, TName>) {
  const chakraStyles: ChakraStylesConfig<Option, false, Group> = useMemo(
    () => ({
      option: (provided, { isSelected }) => ({
        ...provided,
        ...(isSelected && {
          backgroundColor: 'azure',
          color: 'white',
        }),
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: state.hasValue ? 'azure' : 'black',
      }),
    }),
    []
  );

  const {
    field: { onChange, value, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel aria-label={label} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <AsyncSelect
        chakraStyles={chakraStyles}
        {...props}
        {...field}
        onChange={(value) => {
          onChange(value?.value);
          handleOnSelect?.(value);
        }}
      />
      <FormErrorMessage textColor="red">{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
