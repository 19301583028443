import {
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { Breadcrumbs, Divider } from '@diamond/shared/ui';
import { adminCurrencyFormatter } from '@diamond/shared/utils';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useAccountContactDetail } from '../api';
import { CardAccountInfo } from '../components/card-account-info';
import TransactionList from '../components/transaction-list';

export function AccountPageDetail() {
  const params = useParams();

  const userId = params['userId'] as string;
  const shiptoId = params['shiptoId'] as string;

  const tabList = [{ name: 'Detail Akun' }, { name: 'List Transaksi' }];
  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Account & Contact',
      link: '/account',
    },
    {
      title: 'Detail',
      link: `/account/${userId}/${shiptoId}`,
    },
  ];

  const { accountDetail, isLoadingDetail } = useAccountContactDetail(
    userId,
    shiptoId
  );

  const reStructuredData = {
    ...accountDetail,
    payment_method:
      accountDetail?.payment_method === 'term_of_payment'
        ? `Term of Payment ${accountDetail.term_of_payment_days || ''}`
        : 'Cash',
    credit_limit: adminCurrencyFormatter(accountDetail?.credit_limit),
    bill_to: {
      ...accountDetail?.bill_to,
      is_blocked: accountDetail?.bill_to.is_blocked ? 'Yes' : 'No',
    },
  };

  const methods = useForm({ defaultValues: reStructuredData });

  useEffect(() => {
    if (!isLoadingDetail) {
      methods.reset(reStructuredData);
    }
  }, [accountDetail]);

  return (
    <AdminLayout>
      <Stack>
        <Breadcrumbs links={links} useRouterDom />
        {isLoadingDetail ? (
          <>
            <Skeleton height="100px" isLoaded={false} fadeDuration={1} />
            <Skeleton height="100px" isLoaded={false} fadeDuration={1} />
          </>
        ) : (
          <FormProvider {...methods}>
            <Tabs variant="unstyled">
              <Stack pb={4}>
                :width: ,
                <Divider borderWidth="1px" borderColor="gray.33" />
              </Stack>
              <TabList p={4} bg="gray.customGray" borderRadius="md">
                {tabList.map((item, index) => (
                  <Tab
                    key={index}
                    outlineColor="gray.75"
                    borderRadius="md"
                    _selected={{
                      bg: 'rgba(0, 0, 163, 0.1)',
                      color: 'blue',
                      border: 'rgba(0, 0, 163, 0.1)',
                      outlineColor: 'blue',
                    }}
                    outlineOffset="unset"
                    mr={8}
                  >
                    {item.name}
                  </Tab>
                ))}
              </TabList>
              <Stack py={4}>
                <Divider borderWidth="1px" borderColor="gray.33" />
              </Stack>
              <TabPanels>
                <TabPanel p={0}>
                  <CardAccountInfo />
                </TabPanel>
                <TabPanel>
                  <TransactionList userId={userId} shipToId={shiptoId} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </FormProvider>
        )}
      </Stack>
    </AdminLayout>
  );
}
