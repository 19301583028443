import { withBlockquote } from '@udecode/plate-block-quote';
import { createSlatePlugin } from '@udecode/plate-core';
import { toTPlatePlugin } from '@udecode/plate-core/react';

const BaseTipsPlugin = createSlatePlugin({
  key: 'tips',
  node: { isElement: true },
  parsers: {
    html: {
      deserializer: {
        rules: [
          {
            validNodeName: 'BLOCKQUOTE',
            validAttribute: 'data-tips',
          },
        ],
      },
    },
  },
}).overrideEditor(withBlockquote);

export const TipsPlugin = toTPlatePlugin(BaseTipsPlugin).configure({
  handlers: {
    onPaste: ({ editor, event }) => {
      const node = editor.api.above({
        match: { type: BaseTipsPlugin.key },
      });
      if (node) {
        event.preventDefault();
        editor.tf.insertText(event.clipboardData.getData('text'));
      }
    },
  },
});
