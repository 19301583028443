import { apiInstance } from '@diamond/shared/utils';

export const reorderProductHighlightItem = async (
  token: string,
  productHighlightId: string,
  productItemId: string,
  index: number
) => {
  return apiInstance(token).patch(
    `/backoffice/product/highlight/${productHighlightId}/item/${productItemId}/reorder`,
    JSON.stringify({ index })
  );
};
