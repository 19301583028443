import { QueryStringSearch } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useQuery } from '@tanstack/react-query';
import cleanDeep from 'clean-deep';

export const getProductHighlightItem = async (
  token: string,
  id: string,
  params: Readonly<QueryStringSearch>
) => {
  const url = '/backoffice/product/highlight';
  const { data } = await apiInstance(token).get(`${url}/${id}/item`, {
    params: cleanDeep(params),
  });
  return data;
};

export const useProductHighlightItem = (
  id: string,
  params: Readonly<QueryStringSearch>
) => {
  const token = useAuthStore((s) => s.access_token);
  return useQuery({
    queryKey: ['ProductHighLightItem', params],
    queryFn: () => getProductHighlightItem(token, id, params),
    enabled: id !== '' && id !== undefined,
  });
};
