import { createStylesContext } from '@chakra-ui/react';
import { createContext, useContext } from 'react';

export type StepStatusType = 'active' | 'complete' | 'incomplete';

export type Orientation = 'horizontal' | 'vertical';

export interface StepContext {
  /**
   * The status of the step
   * @type "active" | "complete" | "incomplete"
   */
  status: StepStatusType;
  /**
   * The total number of steps
   */
  count: number;
  /**
   * The index of the step
   */
  index: number;
  /**
   * The orientation of the stepper
   */
  orientation: Orientation;
  /**
   * Whether the step is the last step
   */
  isLast: boolean;
  /**
   * Whether the step is the first step
   */
  isFirst: boolean;
  /**
   * Whether to show or not the last separator while in vertical orientation
   */
  showLastSeparator?: boolean;
}

const Context = createContext<StepContext | null>(null);

export const StepContextProvider = Context.Provider;
export const useStepContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useStepContext must be used inside of StepContextProvider'
    );
  }
  return context;
};

export const [StepperStylesProvider, useStepperStyles] =
  createStylesContext('Stepper');
