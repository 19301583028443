import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useState } from 'react';
import {
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';

export interface PasswordFieldProps<TFields extends FieldValues>
  extends InputProps {
  name: Path<TFields>;
  label: string;
  placeholder?: string;
  register: UseFormRegister<TFields>;
  errors?: Partial<FieldErrorsImpl<{ [x: string]: string }>>;
}

export function PasswordField<TFields extends FieldValues>({
  name,
  label,
  placeholder = 'Enter Password',
  register,
  errors,
  ...props
}: PasswordFieldProps<TFields>) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';

  return (
    <FormControl id={props.id} isInvalid={isInvalid}>
      <FormLabel aria-label={label} htmlFor={props.id}>
        {label}
      </FormLabel>
      <InputGroup size="md">
        <Input
          aria-label={label}
          pr="4.5rem"
          type={show ? 'text' : 'password'}
          color="black"
          placeholder={placeholder}
          {...register(name)}
          {...props}
        />
        <InputRightElement>
          <IconButton
            size="sm"
            bgColor="transparent"
            aria-label={label}
            variant="ghost"
            icon={
              show ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />
            }
            _hover={{ bgColor: 'transparent' }}
            onClick={handleClick}
          />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage textColor="red">
        {errorMessage as string}
      </FormErrorMessage>
    </FormControl>
  );
}

export default PasswordField;
