import { Button } from '@chakra-ui/react';
import { ImageOutlined } from '@mui/icons-material';
import { useEditorRef } from '@udecode/plate-core/react';
import { ImagePlugin } from '@udecode/plate-media/react';
import { withRef } from '@udecode/react-utils';
import { useFilePicker } from 'use-file-picker';

type ImageButtonProps = {
  icon: React.ReactNode;
};

const MEDIA_CONFIG: Record<
  string,
  {
    accept: string[];
    icon: React.ReactNode;
    title: string;
    tooltip: string;
  }
> = {
  [ImagePlugin.key]: {
    accept: ['image/*'],
    icon: <ImageOutlined />,
    title: 'Insert Image',
    tooltip: 'Image',
  },
};

export const ImageButton = withRef<typeof Button, ImageButtonProps>(
  ({ icon, ...rest }, ref) => {
    const editor = useEditorRef();

    const { openFilePicker } = useFilePicker({
      accept: MEDIA_CONFIG[ImagePlugin.key].accept,
      multiple: false,
      onFilesSelected: ({ plainFiles: updatedFiles }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (editor as any).tf.insert.media(updatedFiles);
      },
    });

    const handleClick = () => {
      openFilePicker();
    };

    return (
      <Button
        w="30px"
        size="sm"
        px="2"
        variant="toolbar"
        ref={ref}
        onClick={handleClick}
        {...rest}
      >
        {icon}
      </Button>
    );
  }
);
