import {
  Button,
  Divider,
  HStack,
  Stack,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { PostDataWrapper } from '@diamond/shared/data-access';
import { BackofficeRecipeMediaUploadResponse } from '@diamond/shared/types';
import { Dropzone, Modal, showToast, TextField } from '@diamond/shared/ui';
import { apiInstance } from '@diamond/shared/utils';
import { RecipeCategorySchema } from '@diamond/shared/validation';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { BACKOFFICE_RECIPE_QUERY_KEY } from '../api';
import { calculateColorShade } from './color-shade';
import { ColorTextField } from './color-text-field';

export interface CreateRecipeCategoryProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateRecipeCategory = ({
  isOpen = false,
  onClose,
}: CreateRecipeCategoryProps) => {
  const session = useAuthStore();
  const toast = useToast();
  const [isReady, setIsReady] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const methods = useForm({
    resolver: yupResolver(RecipeCategorySchema),
    shouldUnregister: false,
  });

  const { postData: postUpload } = PostDataWrapper(
    '/backoffice/recipe/upload',
    session.access_token,
    true,
    'admin'
  );
  const onSubmit = (formValues: FieldValues) => {
    const formData = new FormData();
    const { name, image, icon, color } = formValues;
    const { bg_color, text_color } = calculateColorShade(color);
    setIsReady(true);
    formData.append('file', image[0], image[0].name);
    formData.append('file', icon[0], icon[0].name);

    postUpload(formValues, {
      headers: {
        authorization: `Bearer ${session.access_token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(async (res) => {
        const response = res as BackofficeRecipeMediaUploadResponse;
        await apiInstance(session.access_token).post('/backoffice/recipe/tag', {
          name,
          big_icon_url: response[0].url,
          small_icon_url: response[1].url,
          text_color,
          category: 'food',
          bg_color,
        });
        showToast(toast, 'success', 'Kategori berhasil disimpan');
        queryClient.invalidateQueries({
          queryKey: [BACKOFFICE_RECIPE_QUERY_KEY],
        });
        onClose();
      })
      .finally(() => {
        methods.reset();
      });
  };
  return (
    <Modal
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        methods.reset();
      }}
      name="form-tambah-blast-notif"
      title="Tambah Kategori"
      hideAction
    >
      <Divider borderColor="black" border="1px" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack pt={4} pb={8}>
            <TextField
              name="name"
              label="Title"
              placeholder="Masukan Title"
              register={methods.register}
              maxLength={100}
              errors={methods.formState.errors}
            />
          </Stack>
          <Stack py={4}>
            <Text fontSize="md" fontWeight="bold">
              Gambar
            </Text>
            <Dropzone
              name="image"
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png', '.svg'],
              }}
              errors={methods.formState.errors}
              onEmptied={() => setIsReady(false)}
              setIsFileAttached={(val) => setIsReady(val)}
              maxSize={0.1}
            />
            <Text fontSize="xs" color="gray" textAlign="right">
              Hanya menerima format PNG, SVG dan JPG dengan ukuran maksimal
              100kb.{' '}
            </Text>
            <Text fontSize="xs" color="gray" textAlign="right">
              Resolusi gambar disarankan berukuran 24x24 pixel.
            </Text>
          </Stack>

          <Stack py={4}>
            <Text fontSize="md" fontWeight="bold">
              Icon Kategori
            </Text>
            <Dropzone
              name="icon"
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png', '.svg'],
              }}
              errors={methods.formState.errors}
              onEmptied={() => setIsReady(false)}
              setIsFileAttached={(val) => setIsReady(val)}
              maxSize={0.1}
            />
            <Text fontSize="xs" color="gray" textAlign="right">
              Hanya menerima format PNG, SVG dan JPG dengan ukuran maksimal
              100kb.{' '}
            </Text>
            <Text fontSize="xs" color="gray" textAlign="right">
              Resolusi gambar disarankan berukuran 24x24 pixel.
            </Text>
          </Stack>
          <Stack py={4}>
            <ColorTextField />
          </Stack>

          <Divider borderColor="black" border="1px" />
          <Stack py={4}>
            <Wrap justify="end">
              <WrapItem>
                <HStack>
                  <Button
                    onClick={() => {
                      onClose();
                      methods.reset();
                    }}
                    variant="outline"
                  >
                    Tutup
                  </Button>
                  <Button type="submit" variant="solid" disabled={isReady}>
                    <span>Save</span>
                  </Button>
                </HStack>
              </WrapItem>
            </Wrap>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
};
