import { ProductHighlightDetail } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useQuery } from '@tanstack/react-query';

export const getHighlightDetail = async (token: string, id: string) => {
  const url = `/backoffice/product/highlight/${id}`;
  const { data } = await apiInstance(token).get<ProductHighlightDetail>(url);
  return data;
};

export const useProductHighlightDetail = (id: string) => {
  const token = useAuthStore((s) => s.access_token);
  return useQuery({
    queryKey: ['ProductHighLightDetail', id],
    queryFn: () => getHighlightDetail(token, id),
    enabled: id !== '' && id !== undefined,
  });
};
