import {
  BackofficeOrderPO,
  BackofficeOrderPOItems,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useQuery } from '@tanstack/react-query';

export const getPODetail = async (id: string, token: string) => {
  const { data } = await apiInstance(token).get(`/backoffice/order/meta/${id}`);
  return data;
};

export const useGetPODetail = (metaId: string) => {
  const session = useAuthStore();
  return useQuery<BackofficeOrderPO>({
    queryKey: ['PODetail', metaId],
    queryFn: () => getPODetail(metaId, session.access_token),
  });
};

export const useGetPODetailItems = (id: string) => {
  const session = useAuthStore();
  const fetchData = async () => {
    try {
      const res = await apiInstance(session.access_token).get(
        `/backoffice/order/${id}/item`
      );
      return res.data;
    } catch (error) {
      throw new Error('Terjadi Kesalahan - useGetPODetailItems');
    }
  };
  return useQuery<BackofficeOrderPOItems>({
    queryKey: ['PODetailItems', id],
    queryFn: fetchData,
    enabled: !!id,
  });
};
