import {
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BackofficeRecipeSectionDetailResponse } from '@diamond/shared/types';
import { CountCharacterField, showToast, TextField } from '@diamond/shared/ui';
import { RecipeSectionRecipeListCreateSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { DeleteOutlined, DragHandle } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  FormProvider,
  SubmitHandler,
  UseFieldArrayReturn,
  useForm,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';

import {
  BACKOFFICE_RECIPE_QUERY_SECTION_KEY,
  useRecipeSectionItemCreateList,
  useRecipeSectionItemReorderMutation,
  useRecipeSectionListItemDelete,
} from '../../api';
import {
  RecipeItemsField,
  RecipeOptions,
  SortableSelectRecipe,
} from './fields/sortable-select-recipe-field';

export type RecipeSectionRecipeList = BackofficeRecipeSectionDetailResponse;

type RecipeSectionTypes =
  | {
      type: 'create';
    }
  | {
      type: 'edit';
      section: RecipeSectionRecipeList;
    };

type RecipeSectionFormProps = RecipeSectionTypes & {
  onSubmit?: SubmitHandler<RecipeSectionRecipeList>;
  onClose?: () => void;
  isDisabled?: boolean;
};

export function RecipeSectionRecipeListForm({
  onSubmit,
  onClose,
  isDisabled,
  ...props
}: RecipeSectionFormProps) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const form = useForm({
    defaultValues:
      props.type === 'edit'
        ? props.section
        : {
            title: '',
            type: 'manual',
            recipes: [],
          },
    resolver: yupResolver(RecipeSectionRecipeListCreateSchema),
  });

  useEffect(() => {
    form.setFocus('title');
  }, [form]);

  const deleteRecipeSectionItemMutation = useRecipeSectionListItemDelete({
    onSuccess: () => {
      showToast(toast, 'success', 'Berhasil Menghapus Item');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY, form.getValues('id')],
      });
    },
  });

  const updateItemsRecipeSectionMutation = useRecipeSectionItemCreateList({
    onSuccess: () => {
      showToast(toast, 'success', 'Berhasil Menambah Item');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY, form.getValues('id')],
      });
    },
  });

  const reorderSectionItemMutation = useRecipeSectionItemReorderMutation();

  const handleDelete = async (id: string) => {
    if (props.type === 'edit') {
      await deleteRecipeSectionItemMutation.mutate({
        id: props.section.id,
        item_id: id,
      });
    }
  };

  const handleSelect = async (
    options: RecipeOptions,
    fieldArray: UseFieldArrayReturn<RecipeItemsField, 'recipes', 'item_id'>
  ) => {
    if (fieldArray.fields.length >= 10) {
      showToast(toast, 'error', 'Maksimal data resep adalah 10');
      return;
    }

    if (props.type === 'edit') {
      const response = await updateItemsRecipeSectionMutation.mutateAsync({
        params: { id: props.section.id },
        body: { recipes: [options.value] },
      });
      fieldArray.append(response.recipes[0]);
      return;
    }

    fieldArray.append({
      id: options.value,
      title: options.label,
      index: options.index,
      recipe_id: options.value as string,
    });
  };

  const handleReorder = (itemId: string, index: number) => {
    if (props.type === 'edit') {
      reorderSectionItemMutation.mutate({
        sectionId: props.section.id,
        itemId,
        index,
      });
    }
  };

  return (
    <FormProvider {...form}>
      <VStack
        as="form"
        onSubmit={form.handleSubmit((data) => onSubmit?.(data))}
        spacing="4"
        alignItems="flex-start"
      >
        <VStack w="full">
          <TextField
            register={form.register}
            name="title"
            label="Nama Section"
            placeholder="Masukkan nama section"
            maxLength={40}
            errors={form.formState.errors}
            isDisabled={isDisabled}
          />
          <CountCharacterField
            control={form.control}
            name="title"
            maxLength={40}
          />
        </VStack>
        {form.getValues('type') === 'manual' ? (
          <SortableSelectRecipe
            onDelete={handleDelete}
            onReorder={handleReorder}
            onSelect={handleSelect}
            form={form}
          />
        ) : (
          <ReadOnlyRecipesList form={form} />
        )}
        <Flex py="4" justifyContent="flex-end" w="full">
          <Button colorScheme="blue" variant="outline" mr={3} onClick={onClose}>
            Tutup
          </Button>
          <Button colorScheme="blue" type="submit">
            Simpan
          </Button>
        </Flex>
      </VStack>
    </FormProvider>
  );
}

function ReadOnlyRecipesList({
  form,
}: {
  form: UseFormReturn<RecipeSectionRecipeList>;
}) {
  const recipes = useWatch({ control: form.control, name: 'recipes' });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <VStack
      spacing={2}
      align="stretch"
      maxW="container.xl"
      w="full"
      overflow="auto"
    >
      {recipes.map((recipe) => (
        <Flex
          key={recipe.id}
          maxWidth="inherit"
          alignItems="center"
          gap={2}
          p={4}
          shadow="sm"
          borderWidth="1px"
          backgroundColor="gray.50"
        >
          <Flex
            bg="gray.33"
            w="40px"
            borderRadius="md"
            minH="40px"
            gap={2}
            opacity="0.2"
          >
            <Center w="40px" h="40px" color="gray.75" borderRadius="md">
              <DragHandle />
            </Center>
          </Flex>
          <Spacer />
          <HStack key={recipe.id ?? recipe.title} w="full">
            <Text flex="1" color="gray.75">
              {recipe.title}
            </Text>
            <IconButton
              icon={<DeleteOutlined />}
              aria-label="Hapus recipe"
              color="white"
              _disabled={{ opacity: 0.2, bg: 'gray.33', color: 'gray.75' }}
              isDisabled
            />
          </HStack>
        </Flex>
      ))}
    </VStack>
  );
}
