import {
  BackofficeRecipeSectionItemDeleteParams,
  BackofficeRecipeSectionItemDeleteResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation } from '@tanstack/react-query';

export const deleteRecipeSectionItem = async (
  token: string,
  params: BackofficeRecipeSectionItemDeleteParams
) => {
  const url = `/backoffice/recipe/section/${params.id}/item/${params.item_id}`;
  const { data } = await apiInstance(
    token
  ).delete<BackofficeRecipeSectionItemDeleteResponse>(url);
  return data;
};

type useDeleteRecipeSectionItemMutationArgs = {
  onSuccess: () => void;
  onSettled: () => void;
};

export function useRecipeSectionListItemDelete({
  onSuccess,
  onSettled,
}: useDeleteRecipeSectionItemMutationArgs) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: (params: BackofficeRecipeSectionItemDeleteParams) =>
      deleteRecipeSectionItem(token, params),
    onSuccess,
    onSettled,
  });
}
