import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import {
  AsyncProps,
  AsyncSelect,
  ChakraStylesConfig,
  OptionBase,
} from 'chakra-react-select';
import { useMemo } from 'react';
import {
  FieldError,
  FieldValues,
  get,
  Path,
  UseFormReturn,
} from 'react-hook-form';
import { GroupBase } from 'react-select';

export interface RecipeOptions extends OptionBase {
  value: string;
  label: string;
}

interface SelectRecipeFieldProps<
  Option extends RecipeOptions = RecipeOptions,
  Group extends GroupBase<Option> = GroupBase<Option>,
  FormValues extends FieldValues = FieldValues
> extends Omit<
    AsyncProps<Option, true, Group>,
    'name' | 'defaultValue' | 'form'
  > {
  form: UseFormReturn<FormValues>;
  name: Path<FormValues>;
  label?: string;
  labelProps?: FormLabelProps;
  handleOnSelect?: (val: Option) => void;
}

export function SelectRecipeField<
  Option extends RecipeOptions,
  Group extends GroupBase<Option> = GroupBase<Option>,
  FormValues extends FieldValues = FieldValues
>({
  form,
  name,
  label,
  labelProps,
  handleOnSelect,
  ...props
}: SelectRecipeFieldProps<Option, Group, FormValues>) {
  const chakraStyles: ChakraStylesConfig<Option, true, Group> = useMemo(
    () => ({
      option: (provided, { isSelected }) => ({
        ...provided,
        ...(isSelected && {
          backgroundColor: 'azure',
          color: 'white',
        }),
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'gray.75',
      }),
    }),
    []
  );

  const {
    formState: { errors },
  } = form;

  const error: FieldError | undefined = get(errors, name);
  const isInvalid = !!error;
  const errorMessage = error?.message || '';

  return (
    <FormControl isInvalid={isInvalid}>
      {label && (
        <FormLabel aria-label={label} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <AsyncSelect
        chakraStyles={chakraStyles}
        isClearable={false}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        onChange={(_, actionMeta) => {
          if (actionMeta.action === 'select-option' && actionMeta.option) {
            handleOnSelect?.(actionMeta.option);
          }
        }}
        isMulti
        {...props}
      />
      <FormErrorMessage textColor="red">
        {errorMessage as string}
      </FormErrorMessage>
    </FormControl>
  );
}
