import { Box, Center, Spinner } from '@chakra-ui/react';
import { BackofficeRecipeListResponse } from '@diamond/shared/types';
import {
  DropResult,
  LoadingOverlay,
  Searchbox,
  Sortable,
  SortableItem,
} from '@diamond/shared/ui';
import { useSearchbox } from '@diamond/sol-admin/hooks';

import { useRecipeReorderMutation } from '../api';
import { RecipeListHeader, RecipeListItem } from './recipe-list-item';

type RecipeListProps = {
  isRecipePending: boolean;
  recipes?: BackofficeRecipeListResponse;
  firstTwoIndex: number[] | undefined;
};

export function RecipeList({
  recipes,
  isRecipePending,
  firstTwoIndex,
}: RecipeListProps) {
  const { register } = useSearchbox();
  const reorderMutation = useRecipeReorderMutation();

  const handleIndexChange = (result: DropResult, items: SortableItem[]) => {
    if (!result.destination) return;
    const destinationIdx = items[result.destination.index].originalIndex; // Target original index rather than draggable index
    if (typeof destinationIdx === 'undefined') return;
    reorderMutation.mutate({
      id: result.draggableId,
      destinationIndex: destinationIdx,
    });
  };

  const handleClickIndexing = (id: string) => {
    if (firstTwoIndex && firstTwoIndex[1]) {
      reorderMutation.mutate({
        id: id,
        destinationIndex: firstTwoIndex[1],
      });
    }
  };

  const sortableRecipes =
    recipes?.data.map<SortableItem>((recipe) => ({
      id: recipe.id,
      originalIndex: recipe.index,
      children: <RecipeListItem recipe={recipe} />,
    })) || [];

  return (
    <Box mt="4">
      <Searchbox register={register} placeholder="Donat Cromboloni Coklat" />
      <RecipeListHeader mt="4" />
      {reorderMutation.isPending && (
        <LoadingOverlay
          isLoading={reorderMutation.isPending}
          text="Mengubah urutan resep..."
        />
      )}
      {isRecipePending ? (
        <Center mt="8">
          <Spinner />
        </Center>
      ) : (
        <Sortable
          data={sortableRecipes}
          handleChange={handleIndexChange}
          handleClickIndexing={handleClickIndexing}
          pageLoadedIndex={firstTwoIndex} // Skip indexing button for the first two items
          showIndexingButton
        />
      )}
    </Box>
  );
}
