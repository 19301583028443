import {
  AspectRatio,
  Box,
  BoxProps,
  Flex,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { useEffect, useReducer } from 'react';

type ImageItem = {
  image: string;
  url: string;
  name: string;
  id: string;
};

type CarouselAspectRatioProps = {
  imageData: Array<ImageItem>;
  ratio?: number;
  duration?: number;
  dotProps?: BoxProps;
  autoPlay?: boolean;
  onBannerClick?: (img: ImageItem) => void;
} & BoxProps;

type SlideAction =
  | { type: 'next' }
  | { type: 'prev' }
  | { type: 'set'; slide: number };

const arrowStyles = {
  cursor: 'pointer',
  pos: 'absolute',
  top: '50%',
  w: 'auto',
  mt: '-22px',
  p: '16px',
  color: 'white',
  fontWeight: 'bold',
  fontSize: '18px',
  transition: '0.6s ease',
  borderRadius: '0 3px 3px 0',
  userSelect: 'none',
  _hover: {
    opacity: 0.8,
    bg: 'black',
  },
} as const;

export function CarouselAspectRatio({
  imageData,
  duration = 8000,
  ratio = 1312 / 512,
  dotProps,
  autoPlay = true,
  onBannerClick,
  ...props
}: CarouselAspectRatioProps) {
  const slidesCount = imageData.length;

  const [currentSlide, dispatch] = useReducer(
    (slide: number, action: SlideAction) => {
      if (action.type === 'next') return wrap(0, slidesCount, slide + 1);
      if (action.type === 'prev') return wrap(0, slidesCount, slide - 1);
      if (action.type === 'set') return wrap(0, slidesCount, action.slide);
      throw new Error('Method not implemented');
    },
    0
  );

  useEffect(() => {
    if (!autoPlay) return;
    const automatedSlide = setInterval(
      () => dispatch({ type: 'next' }),
      duration
    );
    return () => clearInterval(automatedSlide);
  }, [autoPlay, duration]);

  const handleNavigateBanner = (img: ImageItem) => {
    onBannerClick?.(img);
    if (img.url) {
      window.open(img.url, '_newtab');
    }
  };

  return (
    <Box overflow="hidden" position="relative" rounded="lg" {...props}>
      <Flex
        transition="transform 0.5s"
        transform={`translateX(-${currentSlide * 100}%)`}
        willChange="transform"
      >
        {imageData.map((img, i) => (
          <Box
            key={img.image}
            minW="0px"
            flex="0 0 100%"
            position="relative"
            rounded="lg"
            cursor={img.url !== '' ? 'pointer' : 'default'}
            onClick={() => handleNavigateBanner(img)}
          >
            <AspectRatio ratio={ratio}>
              <Image
                src={img.image ?? '/images/product_placeholder.png'}
                alt={`Banner ${i}`}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
            </AspectRatio>
          </Box>
        ))}
      </Flex>
      {slidesCount > 1 ? (
        <>
          <Text
            {...arrowStyles}
            left="0"
            onClick={() => dispatch({ type: 'prev' })}
          >
            &#10094;
          </Text>
          <Text
            {...arrowStyles}
            right="0"
            onClick={() => dispatch({ type: 'next' })}
          >
            &#10095;
          </Text>
        </>
      ) : null}
      <HStack
        justify={{ base: 'center', md: 'right' }}
        pos="absolute"
        bottom={{ base: 2, md: 4 }}
        right={{ base: 0, md: 12 }}
        w="full"
        spacing="1"
        display={slidesCount > 1 ? 'flex' : 'none'}
        {...dotProps}
      >
        {Array.from({ length: slidesCount }).map((_, slide) => (
          <Box
            key={`dots-${slide}`}
            cursor="pointer"
            boxSize={'2'}
            bg={currentSlide === slide ? 'blue' : 'gray.33'}
            borderRadius="100%"
            display="inline-block"
            transition="background-color 0.6s ease"
            _hover={{ bg: 'blackAlpha.800' }}
            onClick={() => dispatch({ type: 'set', slide })}
          />
        ))}
      </HStack>
    </Box>
  );
}

export const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};
