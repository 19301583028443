import { BackOfficeOrderChangelogs } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useQuery } from '@tanstack/react-query';

export const getOrderChangelogs = async (token: string, activityId: string) => {
  const url = `/backoffice/order/${activityId}/changelog`;
  const { data } = await apiInstance(token).get<BackOfficeOrderChangelogs>(url);
  return data;
};

export const ORDER_CHANGELOG_QUERY_KEY = 'OrderChangelogs';

export function useOrderChangelogs(activityId: string | undefined) {
  const access_token = useAuthStore((s) => s.access_token);
  return useQuery({
    queryKey: [ORDER_CHANGELOG_QUERY_KEY, activityId],
    queryFn: () => getOrderChangelogs(access_token, activityId || ''),
    enabled: !!activityId,
  });
}
