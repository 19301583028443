import { useCallback, useRef } from 'react';

export function useDebouncedPromise<Input, Output>(
  fn: (arg: Input) => Promise<Output>,
  delay: number
): (arg: Input) => Promise<Output> {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (arg: Input) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      return new Promise((resolve) => {
        timeout.current = setTimeout(async () => {
          resolve(await fn(arg));
        }, delay);
      });
    },
    [fn, delay]
  );
}
