import { Card, CardBody, CardHeader, Heading, Text } from '@chakra-ui/react';
import {
  BackofficeUpdateRecipeListBody,
  RecipeBody,
  RecipeInstructionSectionPayload,
} from '@diamond/shared/types';
import { Editor } from '@diamond/sol-admin/recipe-editor/ui';
import { useCallback } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDebounceCallback } from 'usehooks-ts';

import { useRecipeDetail, useUpdateRecipeMutation } from '../../api';

type RecipeInstructionFields = RecipeInstructionSectionPayload;

type RecipeInstructionProps<
  TFields extends RecipeInstructionFields = RecipeInstructionFields
> = {
  form: UseFormReturn<TFields>;
};

export function RecipeInstructionForm<
  TFields extends RecipeInstructionFields = RecipeInstructionFields
>({ form }: RecipeInstructionProps<TFields>) {
  const _form = form as UseFormReturn<RecipeInstructionFields>;

  return (
    <Card variant="outline" size="sm" flex="1">
      <CardHeader>
        <Heading size="md">Instruksi Memasak</Heading>
      </CardHeader>
      <CardBody>
        <Text>Deskripsi Langkah</Text>
        <Text color="gray.75" mb="3">
          Jelaskan secara rinci langkah yang harus dilakukan pada tahap ini.
        </Text>
        <ControlledEditor form={_form} />
      </CardBody>
    </Card>
  );
}

function ControlledEditor({
  form,
}: {
  form: UseFormReturn<RecipeInstructionFields>;
}) {
  const {
    field: { value, onChange },
  } = useController({
    control: form.control,
    name: 'body',
  });

  const recipeId = useParams()['recipeId'] as string;
  const updateRecipeMutation = useUpdateRecipeMutation(recipeId);
  const { data } = useRecipeDetail();
  const isDisabled = data?.is_published;

  const submit = useCallback(
    (body: RecipeBody) => {
      onChange(body);
      form.handleSubmit((data) => {
        updateRecipeMutation.mutate({
          ...data,
          body,
        } as unknown as BackofficeUpdateRecipeListBody);
      })();
    },
    [form, onChange, updateRecipeMutation]
  );

  const debouncedSubmit = useDebounceCallback(submit, 1000);

  return (
    <Editor
      defaultValue={value}
      handleChange={debouncedSubmit}
      isDisabled={isDisabled}
    />
  );
}
