import { QueryWrapper } from '@diamond/shared/data-access';
import { BackofficeOrderDetail } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/features/auth';

type UseActivitiesDataProps = {
  order_by: string;
  direction: string;
  size: number;
  page: number;
};

export const useActivitiesDetail = (
  id: string,
  queryString: UseActivitiesDataProps
) => {
  const session = useAuthStore();

  const queryActivities = QueryWrapper(
    `/backoffice/order/${id}`,
    session.access_token
  );

  const {
    data: activitiesDetailData,
    isLoading,
    pageCount,
    refetch,
    isFetching,
  } = queryActivities.useQueryGetAll<BackofficeOrderDetail>(
    `Activities-${id}`,
    queryString,
    0,
    '/activities/error',
    {
      staleTime: 30000,
      prefetchNextPage: true,
      enabled: !!id,
    }
  );

  return {
    activitiesDetailData,
    pageCount,
    isLoading,
    isFetching,
    refetch,
  };
};

export default useActivitiesDetail;
