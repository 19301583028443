import { StepContext, StepStatusType, useStepContext } from './step-context';

type MaybeRenderProp =
  | React.ReactNode
  | ((props: StepContext) => React.ReactNode);

export type StepStatusProps = Partial<Record<StepStatusType, MaybeRenderProp>>;

export function StepStatus(props: StepStatusProps) {
  const { complete, incomplete, active } = props;
  const context = useStepContext();

  let render: React.ReactNode | null = null;

  switch (context.status) {
    case 'complete':
      render = runIfFn(complete, context);
      break;
    case 'incomplete':
      render = runIfFn(incomplete, context);
      break;
    case 'active':
      render = runIfFn(active, context);
      break;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return render ? <>{render}</> : null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFunction = (value: any): value is Function =>
  typeof value === 'function';

function runIfFn<T, U>(
  valueOrFn: T | ((...fnArgs: U[]) => T),
  ...args: U[]
): T {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}
