import { RecipeSectionLayout } from '../../components';
import { RecipeSectionHomepageList } from '../../components/recipe-section-homepage-list';

export function RecipeSectionHomepage() {
  return (
    <RecipeSectionLayout section="homepage">
      <RecipeSectionHomepageList />
    </RecipeSectionLayout>
  );
}
