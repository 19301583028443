import { TextProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

import { Paragraph } from './paragraph';

export const ListItemContent = forwardRef<
  React.ElementRef<typeof Paragraph>,
  React.PropsWithChildren<TextProps>
>(({ children, ...props }, ref) => {
  return (
    <Paragraph ref={ref} {...props}>
      {children}
    </Paragraph>
  );
});

ListItemContent.displayName = 'ListItemContent';
