import {
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BackofficeRecipeSectionDetailResponse } from '@diamond/shared/types';
import {
  CountCharacterField,
  RadioButtonGroup,
  showToast,
  TextField,
} from '@diamond/shared/ui';
import { RecipeSectionSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { DeleteOutlined, DragHandle } from '@mui/icons-material';
import { useEffect } from 'react';
import {
  FormProvider,
  SubmitHandler,
  UseFieldArrayReturn,
  useForm,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';

import {
  useRecipeSectionItemReorderMutation,
  useRecipeSectionUpdate,
} from '../../api';
import { useRecipeSectionItemCreateQuery } from '../../api/create-recipe-section-item';
import { useRecipeSectionItemDeleteQuery } from '../../api/delete-recipe-section-item';
import {
  RecipeItemsField,
  RecipeOptions,
  SortableSelectRecipe,
} from './fields/sortable-select-recipe-field';

export type RecipeSection = BackofficeRecipeSectionDetailResponse;

type RecipeSectionTypes = {
  section: RecipeSection;
};

type RecipeSectionFormProps = RecipeSectionTypes & {
  onSubmit?: SubmitHandler<RecipeSection>;
  onClose?: () => void;
  isDisabled?: boolean;
};

export function RecipeSectionHomepageForm({
  onSubmit,
  onClose,
  isDisabled,
  section,
}: RecipeSectionFormProps) {
  const toast = useToast();

  const form = useForm<RecipeSection>({
    values: section,
    resolver: yupResolver(RecipeSectionSchema),
  });

  const { mutateAsync: updateRecipeSection } = useRecipeSectionUpdate();
  const { mutateAsync: createRecipeSectionItem } =
    useRecipeSectionItemCreateQuery();
  const { mutateAsync: deleteRecipeSectionItem } =
    useRecipeSectionItemDeleteQuery();

  const reorderSectionItemMutation = useRecipeSectionItemReorderMutation();

  const type = useWatch({ control: form.control, name: 'type' });

  const handleSortByChange = async (value: string) => {
    const type = value as 'created_at_desc' | 'views_desc' | 'manual';

    await updateRecipeSection({
      params: { id: section.id },
      body: {
        title: section.title,
        type,
      },
    });
  };

  const handleOnSelect = async (
    options: RecipeOptions,
    fieldArray: UseFieldArrayReturn<RecipeItemsField, 'recipes', 'item_id'>
  ) => {
    if (fieldArray.fields.length >= 6) {
      showToast(toast, 'error', 'Maksimal data resep adalah 6');
      return;
    }

    const response = await createRecipeSectionItem({
      params: { id: section.id },
      body: { recipes: [options.value] },
    });
    fieldArray.append(response.recipes[0]);
    return;
  };

  const handleOnDelete = async (id: string) => {
    await deleteRecipeSectionItem({
      id: section.id,
      item_id: id,
    });
  };

  const handleOnReorder = async (itemId: string, index: number) => {
    reorderSectionItemMutation.mutate({
      sectionId: section.id,
      itemId,
      index,
    });
  };

  useEffect(() => {
    form.setFocus('title');
  }, [form]);

  return (
    <FormProvider {...form}>
      <VStack
        as="form"
        onSubmit={form.handleSubmit((data) => onSubmit?.(data))}
        spacing="4"
        alignItems="flex-start"
      >
        <VStack w="full">
          <TextField
            register={form.register}
            name="title"
            label="Nama Section"
            placeholder="Masukkan nama section"
            maxLength={40}
            errors={form.formState.errors}
            isDisabled={isDisabled}
          />
          <CountCharacterField
            control={form.control}
            name="title"
            maxLength={40}
          />
        </VStack>
        <RadioButtonGroup
          form={form}
          onChange={handleSortByChange}
          name="type"
          direction="row"
          value={type}
          options={[
            {
              label: 'Populer',
              value: 'views_desc',
            },
            {
              label: 'Terbaru',
              value: 'created_at_desc',
            },
            {
              label: 'Manual',
              value: 'manual',
            },
          ]}
          w="full"
          stackProps={{
            divider: undefined,
            sx: {
              '& > label': {
                flex: '1',
                ml: 'unset',
                my: '2',
              },
            },
          }}
        />
        {type === 'manual' ? (
          <SortableSelectRecipe
            form={form}
            onSelect={handleOnSelect}
            onDelete={handleOnDelete}
            onReorder={handleOnReorder}
          />
        ) : (
          <PopularRecipesList form={form} />
        )}

        <Flex py="4" justifyContent="flex-end" w="full">
          <Button colorScheme="blue" variant="outline" mr={3} onClick={onClose}>
            Tutup
          </Button>
          <Button colorScheme="blue" type="submit">
            Simpan
          </Button>
        </Flex>
      </VStack>
    </FormProvider>
  );
}

function PopularRecipesList({ form }: { form: UseFormReturn<RecipeSection> }) {
  const recipes = useWatch({ control: form.control, name: 'recipes' });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <VStack
      spacing={2}
      align="stretch"
      maxW="container.xl"
      w="full"
      overflow="auto"
    >
      {recipes.map((recipe) => (
        <Flex
          key={recipe.id}
          maxWidth="inherit"
          alignItems="center"
          gap={2}
          p={4}
          shadow="sm"
          borderWidth="1px"
          backgroundColor="gray.50"
        >
          <Flex
            bg="gray.33"
            w="40px"
            borderRadius="md"
            minH="40px"
            gap={2}
            opacity="0.2"
          >
            <Center w="40px" h="40px" color="gray.75" borderRadius="md">
              <DragHandle />
            </Center>
          </Flex>
          <Spacer />
          <HStack key={recipe.id ?? recipe.title} w="full">
            <Text flex="1" color="gray.75">
              {recipe.title}
            </Text>
            <IconButton
              icon={<DeleteOutlined />}
              aria-label="Hapus recipe"
              color="white"
              _disabled={{ opacity: 0.2, bg: 'gray.33', color: 'gray.75' }}
              isDisabled
            />
          </HStack>
        </Flex>
      ))}
    </VStack>
  );
}
