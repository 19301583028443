import { ParagraphPlugin } from '@udecode/plate-core/react';
import { ListItemContentPlugin } from '@udecode/plate-list/react';
import {
  type PlaceholderProps,
  createNodeHOC,
  createNodesHOC,
  usePlaceholderState,
} from '@udecode/plate-utils/react';
import React from 'react';

import { TipsPlugin } from '../plugins/lib';

export const Placeholder = (props: PlaceholderProps) => {
  const { children, nodeProps, placeholder } = props;

  const { enabled } = usePlaceholderState(props);

  return React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      className: child.props.className,
      nodeProps: {
        ...nodeProps,
        _before: enabled
          ? {
              position: 'absolute',
              cursor: 'text',
              opacity: 0.3,
              content: 'attr(placeholder)',
            }
          : undefined,

        placeholder,
      },
    });
  });
};

export const withPlaceholder = createNodeHOC(Placeholder);

export const withPlaceholdersPrimitive = createNodesHOC(Placeholder);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withPlaceholders = (components: any) =>
  withPlaceholdersPrimitive(components, [
    {
      key: ParagraphPlugin.key,
      hideOnBlur: true,
      placeholder: 'Masukkan instruksi memasak',
      query: {
        maxLevel: 1,
      },
    },
    {
      key: ListItemContentPlugin.key,
      hideOnBlur: true,
      placeholder: 'Masukkan instruksi memasak',
      query: {
        maxLevel: 3,
      },
    },
  ]);
