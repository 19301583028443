import {
  Box,
  HStack,
  IconButton,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BackofficeRecipeSectionDetailResponse } from '@diamond/shared/types';
import { DropResult, Sortable, SortableItem } from '@diamond/shared/ui';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { DeleteOutlined } from '@mui/icons-material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense } from 'react';
import {
  useFieldArray,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';

import {
  getRecipeList,
  recipeListQueryOptions,
  useRecipeList,
} from '../../../api';
import { useDebouncedPromise } from '../../../hooks';
import { SelectRecipeField } from './select-recipe-field';

export type RecipeItemsField = {
  recipes: BackofficeRecipeSectionDetailResponse['recipes'];
};

export type RecipeOptions = {
  label: string;
  value: string;
  index: number;
};

type SortableCallbacks = {
  onSelect: (
    options: RecipeOptions,
    fieldArray: UseFieldArrayReturn<RecipeItemsField, 'recipes', 'item_id'>
  ) => void;
  onDelete: (id: string) => void;
  onReorder: (id: string, index: number) => void;
};

export function SortableSelectRecipe<T extends RecipeItemsField>({
  form,
  onSelect,
  onDelete,
  onReorder,
}: {
  form: UseFormReturn<T>;
} & SortableCallbacks) {
  usePrefetchRecipeList();
  return (
    <Suspense fallback={<Spinner />}>
      <InnerSortableSelectRecipe
        form={form as unknown as UseFormReturn<RecipeItemsField>}
        onSelect={onSelect}
        onDelete={onDelete}
        onReorder={onReorder}
      />
    </Suspense>
  );
}

function InnerSortableSelectRecipe({
  form,
  onSelect,
  onDelete,
  onReorder,
}: {
  form: UseFormReturn<RecipeItemsField>;
} & SortableCallbacks) {
  const fieldArray = useFieldArray({
    control: form.control,
    name: 'recipes',
    keyName: 'item_id',
  });
  const token = useAuthStore((s) => s.access_token);
  const { data: defaultOptions } = useSuspenseQuery({
    ...recipeListQueryOptions(token, defaultRecipeListQuery),
    select: mapToOptions,
  });

  const loadOptions = useDebouncedPromise(
    async (search: string) =>
      getRecipeList(token, {
        page: 1,
        size: 10,
        search_query: search,
        only_published: true,
      }).then(mapToOptions),
    1000
  );

  const items: SortableItem[] = fieldArray.fields.map((item, i) => ({
    id: item.id ?? '',
    originalIndex: item.index,
    children: (
      <HStack key={item.id}>
        <Text flex="1">{item.title}</Text>
        <IconButton
          icon={<DeleteOutlined />}
          aria-label="Hapus recipe"
          color="white"
          bgColor="red"
          _hover={{ bgColor: 'maroon' }}
          _active={{ bgColor: 'red' }}
          onClick={() => {
            fieldArray.remove(i);
            onDelete(item.id ?? '');
          }}
        />
      </HStack>
    ),
  }));

  const handleIndexChange = (result: DropResult, items: SortableItem[]) => {
    if (!result.destination) return;
    const destinationIdx = items[result.destination.index].originalIndex; // Target original index rather than draggable index
    if (typeof destinationIdx === 'undefined') return;
    onReorder(result.draggableId, destinationIdx);
  };

  return (
    <VStack w="full">
      <SelectRecipeField
        form={form}
        name="recipes"
        label="Pilih Resep"
        placeholder="Cari resep..."
        value={fieldArray.fields.map((v) => ({
          label: v.title,
          value: v.id ?? '',
          index: v.index,
        }))}
        filterOption={(option) => {
          return fieldArray.fields.every((v) => v.title !== option.label);
        }}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        handleOnSelect={(value) => {
          if (value) {
            onSelect(value, fieldArray);
          }
        }}
      />

      <Box w="inherit" overflow="auto" maxH="410px">
        <Sortable
          data={items}
          handleReorderIndex={fieldArray.move}
          handleChange={handleIndexChange}
        />
      </Box>
    </VStack>
  );
}

const defaultRecipeListQuery = { page: 1, size: 10, only_published: true };

function usePrefetchRecipeList() {
  return useRecipeList(defaultRecipeListQuery);
}

function mapToOptions(response: Awaited<ReturnType<typeof getRecipeList>>) {
  return response.data.map((opt) => ({
    value: opt.id,
    label: opt.title,
    index: opt.index,
  }));
}
