import { Spinner, VStack } from '@chakra-ui/react';

import { useRecipeHomepageSection } from '../api/get-recipe-section';
import { EditRecipeSectionHomepageAction } from './edit-recipe-section-homepage-action';
import { RecipeSectionHeading } from './recipe-section-heading';
import { RecipeSectionListItem } from './recipe-section-list-item';

export function RecipeSectionHomepageList() {
  const { data, isPending } = useRecipeHomepageSection();

  if (isPending) {
    return <Spinner />;
  }

  return (
    <>
      <RecipeSectionHeading />
      <VStack spacing={2} align="stretch" maxW="container.xl">
        {data?.data.map((section) => (
          <RecipeSectionListItem
            key={section.id}
            section={section}
            action={<EditRecipeSectionHomepageAction section={section} />}
          />
        ))}
      </VStack>
    </>
  );
}
