import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { format as formatDate } from 'date-fns';
import { useState } from 'react';
import { DayPicker, DayPickerSingleProps } from 'react-day-picker';
import { useFormContext } from 'react-hook-form';

import InputDate from '../input-date';

export type SingleDatePickerProps = DayPickerSingleProps & {
  name: string;
  format?: string;
  mode?: 'single';
};

export function SingleDatePicker({
  name,
  mode = 'single',
  format = 'dd/MM/yyyy',
}: SingleDatePickerProps) {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const handleDaySelect = (date: Date | undefined) => {
    setSelectedDate(date);
    if (!date) {
      setValue(name, '');
      onOpen();
    } else {
      setValue(name, formatDate(date, format));
      onClose();
    }
  };
  return (
    <Popover
      placement="bottom-start"
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
    >
      <PopoverTrigger>
        <Box flex="1">
          <InputDate name={name} register={register} errors={errors} />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <DayPicker
            mode={mode}
            selected={selectedDate}
            onSelect={handleDaySelect}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
export default SingleDatePicker;
