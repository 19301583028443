import { SlateEditor } from '@udecode/plate-core';
import {
  BaseListItemContentPlugin,
  BaseListItemPlugin,
} from '@udecode/plate-list';
import { PathApi, TElement } from '@udecode/slate';

export const listSoftBreak = (
  editor: SlateEditor,
  { defaultType = editor.getType(BaseListItemContentPlugin) }
) => {
  if (!editor.selection) return;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectionPath = editor.api.path(editor.selection)!;
  const slicedPath = selectionPath.slice(0, 3);

  const insertPath = PathApi.next(slicedPath);

  editor.tf.insertNodes(
    editor.api.create.block({ children: [{ text: '' }], type: defaultType }),
    {
      at: insertPath,
      select: true,
    }
  );

  return true;
};

/** Insert list item if selection in li>p.
 * A modified version of: https://github.com/udecode/plate/blob/main/packages/list/src/lib/transforms/insertListItem.ts
 */
export const insertListItem = (editor: SlateEditor): boolean => {
  const liType = editor.getType(BaseListItemPlugin);
  const licType = editor.getType(BaseListItemContentPlugin);

  if (!editor.selection) {
    return false;
  }

  const licEntry = editor.api.above({ match: { type: licType } });

  if (!licEntry) return false;

  const [, paragraphPath] = licEntry;

  const listItemEntry = editor.api.parent(paragraphPath);

  if (!listItemEntry) return false;

  const [listItemNode, listItemPath] = listItemEntry;

  if (listItemNode.type !== liType) return false;

  let success = false;

  editor.tf.withoutNormalizing(() => {
    if (!editor.api.isCollapsed()) {
      editor.tf.delete();
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const isStart = editor.api.isStart(editor.selection!.focus, paragraphPath);
    const isEnd = editor.api.isEmpty(editor.selection, { after: true });

    const nextParagraphPath = PathApi.next(paragraphPath);
    const nextListItemPath = PathApi.next(listItemPath);

    /** If start, insert a list item before */
    if (isStart && isEnd) {
      const marks = editor.api.marks() || {};
      editor.tf.insertNodes(
        {
          children: [{ children: [{ text: '', ...marks }], type: licType }],
          type: liType,
        },
        { at: nextListItemPath }
      );
      editor.tf.select(nextListItemPath);
      success = true;

      return;
    }

    /** If start, insert a list item before */
    if (isStart) {
      editor.tf.insertNodes(
        {
          children: [{ children: [{ text: '' }], type: licType }],
          type: liType,
        },
        { at: listItemPath }
      );

      success = true;

      return;
    }
    /**
     * If not end, split nodes, wrap a list item on the new paragraph and move
     * it to the next list item
     */
    if (isEnd) {
      /** If end, insert a list item after and select it */
      const marks = editor.api.marks() || {};
      editor.tf.insertNodes(
        {
          children: [{ children: [{ text: '', ...marks }], type: licType }],
          type: liType,
        },
        { at: nextListItemPath }
      );
      editor.tf.select(nextListItemPath);
    } else {
      editor.tf.withoutNormalizing(() => {
        editor.tf.splitNodes();
        editor.tf.wrapNodes<TElement>(
          {
            children: [],
            type: liType,
          },
          { at: nextParagraphPath }
        );
        editor.tf.moveNodes({
          at: nextParagraphPath,
          to: nextListItemPath,
        });
        editor.tf.select(nextListItemPath);
        editor.tf.collapse({
          edge: 'start',
        });
      });
    }

    success = true;
  });

  return success;
};
