/* eslint-disable react-hooks/rules-of-hooks */
import { useAuthentication } from '@diamond/sol-admin/features/auth';
import React, { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';

/**
 * A higher-order wrapper for the conditional route component
 * Can be used directly, or used as a building block for more
 * specific components like `withLoggedIn` or `withAdmin`
 */
export function withCondition(
  Component: FunctionComponent,
  condition: boolean,
  redirectTo: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function InnerComponent(props?: any) {
    return condition ? (
      <Component {...props} />
    ) : (
      <Navigate to={redirectTo} replace />
    );
  };
}

/** A higher-order component implementation for Admin-only restricted pages */
export const withAdmin = (Component: FunctionComponent) => {
  const { loggedIn, isAdmin } = useAuthentication();
  return withCondition(Component, loggedIn && isAdmin, '/login');
};

/** A higher-order wrapper, binding the "user logged in" condition and redirect */
export const withLoggedIn = (Component: React.FunctionComponent) => {
  const { loggedIn } = useAuthentication();
  return withCondition(Component, loggedIn, '/login');
};

/** The inverse, showing a page only if a user is logged OUT */
export const withLoggedOut = (Component: React.FunctionComponent) => {
  const { loggedIn } = useAuthentication();
  return withCondition(Component, !loggedIn, '/');
};
