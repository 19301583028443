import { Box, Image } from '@chakra-ui/react';

import {
  HeadingOne,
  HeadingTwo,
  List,
  ListItem,
  ListItemContent,
  Paragraph,
  Tips,
} from './element-primitives';

type Leaf = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
};

type Align = 'left' | 'right' | 'center' | 'justify';

type Elements =
  | { type: 'p'; align: Align; children: Array<Leaf> }
  | { type: 'h1'; align: Align; children: Array<Leaf> }
  | { type: 'h2'; align: Align; children: Array<Leaf> }
  | { type: 'h3'; align: Align; children: Array<Leaf> }
  | { type: 'ol'; children: Array<Nodes> }
  | { type: 'li'; children: Array<Nodes> }
  | { type: 'lic'; children: Array<Leaf> }
  | { type: 'img'; url: string; children: Array<Leaf> }
  | { type: 'tips'; url: string; children: Array<Leaf> };

export type Nodes = Elements | Leaf;

type RecipeInstructionBodyProps = {
  body: Array<Record<string, unknown>>;
};

export function PlateSerializer({ body }: RecipeInstructionBodyProps) {
  return (
    <Box>
      {body.map((node) =>
        serializeToJSX({} as Nodes, node as unknown as Nodes)
      )}
    </Box>
  );
}

function isText(node: Nodes): node is Leaf {
  return typeof (node as Leaf)?.text === 'string';
}

function serializeToJSX(parentNode: Nodes, nodes: Nodes) {
  if (isText(nodes)) {
    return (
      <span
        style={{
          fontWeight:
            nodes.bold ||
            ['h1', 'h2', 'h3'].includes((parentNode as Elements).type)
              ? 'bold'
              : 'normal',
          fontStyle: nodes.italic ? 'italic' : 'normal',
          textDecoration: nodes.underline ? 'underline' : 'normal',
        }}
      >
        {nodes.text || <>&#xFEFF;</>}
      </span>
    );
  }

  const children = nodes.children.map((n) => serializeToJSX(nodes, n));

  switch (nodes.type) {
    case 'p':
      return (
        <Paragraph mb="1" textAlign={nodes.align}>
          {children}
        </Paragraph>
      );
    case 'h1':
      return (
        <HeadingOne mb="4" textAlign={nodes.align}>
          {children}
        </HeadingOne>
      );
    case 'h2':
      return (
        <HeadingTwo mb="4" textAlign={nodes.align}>
          {children}
        </HeadingTwo>
      );
    case 'ol': {
      return <List>{children}</List>;
    }
    case 'li': {
      return <ListItem>{children}</ListItem>;
    }
    case 'lic':
      return <ListItemContent mb="1">{children}</ListItemContent>;
    case 'img':
      return (
        <Box mb="1">
          <Box
            as="figure"
            position="relative"
            m="0"
            contentEditable={false}
            role="group"
          >
            <Image
              alt=""
              display="block"
              w="100%"
              maxW="100%"
              cursor="pointer"
              objectFit="cover"
              px="0"
              borderRadius="sm"
              src={nodes.url}
            />
          </Box>
        </Box>
      );
    case 'tips':
      return <Tips py="2">{children}</Tips>;
    default:
      return null;
  }
}
