import { BackofficeDetailPromoManagement } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

export class BackofficePromoError extends Error {
  status: number | undefined;
  subtitle: string;
  constructor(status: number | undefined, message: string, subtitle: string) {
    super(message);
    this.status = status;
    this.subtitle = subtitle;
  }
}

export async function getPromoManagementDetail(
  access_token: string,
  promo_id: string
) {
  try {
    const { data } = await apiInstance(
      access_token
    ).get<BackofficeDetailPromoManagement>(`/backoffice/promo/${promo_id}`);
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new BackofficePromoError(
        error.response?.data.statusCode,
        error.response?.data.message,
        'Silahkan coba lagi'
      );
    }
    throw new BackofficePromoError(
      500,
      'Terjadi Kesalahan',
      'Silahkan coba lagi'
    );
  }
}

export const BACKOFFICE_DETAIL_PROMO_QUERY_KEY = 'PromoDetail';

export function usePromoManagementDetail(promo_id: string) {
  const access_token = useAuthStore((s) => s.access_token);
  return useQuery<BackofficeDetailPromoManagement, BackofficePromoError>({
    queryKey: [BACKOFFICE_DETAIL_PROMO_QUERY_KEY, promo_id],
    queryFn: () => getPromoManagementDetail(access_token, promo_id),
  });
}
