import { DropResult, Sortable, SortableItem } from '@diamond/shared/ui';

import {
  useRecipeSectionList,
  useRecipeSectionReorderMutation,
} from '../../api';
import { RecipeSectionLayout } from '../../components';
import { CreateRecipeSectionRecipeListAction } from '../../components/create-recipe-section-recipe-list';
import { EditRecipeSectionRecipeListAction } from '../../components/edit-recipe-section-list';
import { RecipeSectionHeading } from '../../components/recipe-section-heading';
import { RecipeSectionListItem } from '../../components/recipe-section-list-item';

export function RecipeSectionRecipeList() {
  const { data } = useRecipeSectionList({
    page: 1,
    size: 20,
    is_homepage: false,
  });
  const reorderMutation = useRecipeSectionReorderMutation();

  const handleIndexChange = (result: DropResult, items: SortableItem[]) => {
    if (!result.destination) return;
    const destinationIdx = items[result.destination.index].originalIndex; // Target original index rather than draggable index
    if (typeof destinationIdx === 'undefined') return;
    reorderMutation.mutate({
      id: result.draggableId,
      destinationIndex: destinationIdx,
    });
  };

  const sorTableItems =
    data?.data.map<SortableItem>((section) => ({
      id: section.id,
      originalIndex: section.index,
      children: (
        <RecipeSectionListItem
          section={section}
          key={section.title}
          isDeletable={!section.is_for_homepage && section.type === 'manual'}
          isSortable
          action={<EditRecipeSectionRecipeListAction section={section} />}
        />
      ),
    })) || [];

  return (
    <RecipeSectionLayout
      section="recipe"
      extra={<CreateRecipeSectionRecipeListAction />}
    >
      <RecipeSectionHeading />
      <Sortable
        data={sorTableItems}
        handleChange={handleIndexChange}
        showIndexingButton
      />
    </RecipeSectionLayout>
  );
}
