import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Breadcrumbs } from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MouseEvent, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BACKOFFICE_RECIPE_QUERY_KEY,
  deleteRecipe,
  useIsUpdateRecipeMutating,
  useRecipeDetail,
  useTogglePublishRecipeMutation,
} from '../../api';
import { RecipeLivePreview } from '../recipe-live-preview';
import { RecipeStepper } from '../recipe-stepper';

type RecipeFormLayoutProps = {
  handleNext?: (e: MouseEvent<HTMLButtonElement>) => void;
  handlePrev?: (e: MouseEvent<HTMLButtonElement>) => void;
  handlePublish?: (e: MouseEvent<HTMLButtonElement>) => void;
  showSaveButton?: boolean;
  section?: {
    title: string;
    link: string;
    index: number;
  };
};

export function RecipeFormLayout({
  children,
  section,
  handleNext,
  handlePrev,
  handlePublish,
  showSaveButton = true,
}: React.PropsWithChildren<RecipeFormLayoutProps>) {
  const params = useParams();
  const recipeId = params?.['recipeId'];
  const { data } = useRecipeDetail();
  const title = data?.title;
  const isRecipeFormMutating = useIsUpdateRecipeMutating(recipeId || '');
  const togglePublishedMutation = useTogglePublishRecipeMutation(
    recipeId || ''
  );

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Recipe Management',
      link: '/recipe',
    },
    {
      title: 'Recipe List Management',
      link: '/recipe/list',
    },
    ...(recipeId
      ? [
          {
            title: title,
            link: `/recipe/list/${recipeId}`,
          },
        ]
      : []),
    ...(section
      ? [
          {
            title: section.title,
            link: `/recipe/list/${recipeId}/${section.link}`,
          },
        ]
      : []),
  ];

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Flex justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Text fontSize="2xl" fontWeight="bold" mb="0">
            Resep Detail
          </Text>
          {data ? (
            <Tag
              bg={data?.is_published ? 'green' : undefined}
              color={data?.is_published ? 'white' : undefined}
            >
              {data?.is_published ? 'Published' : 'Draft'}
            </Tag>
          ) : null}
        </Stack>
        {showSaveButton && !data?.is_published ? (
          <Button
            type="submit"
            size="sm"
            variant="outline"
            isLoading={isRecipeFormMutating[isRecipeFormMutating.length - 1]}
          >
            Simpan sebagai draft
          </Button>
        ) : data?.is_published ? (
          <Button
            onClick={() => togglePublishedMutation.mutate()}
            size="sm"
            variant="outline"
            isLoading={togglePublishedMutation.isPending}
          >
            Edit
          </Button>
        ) : null}
      </Flex>
      <RecipeStepper currentIndex={section?.index || 0} />
      <Divider my="6" />
      <Flex flexDir={{ base: 'column-reverse', md: 'column' }} gap="4">
        <Grid
          templateColumns="1fr 1fr"
          mt={{ base: '2', md: '0' }}
          mb={{ base: '0', md: '2' }}
          gap="4"
        >
          <GridItem>{children}</GridItem>
          <GridItem>
            <RecipeLivePreview />
          </GridItem>
        </Grid>

        <Flex flexDirection="row-reverse" gap="2">
          {handlePublish && !data?.is_published ? (
            <Button
              size="lg"
              isLoading={isRecipeFormMutating[isRecipeFormMutating.length - 1]}
              onClick={handlePublish}
            >
              Terbitkan
            </Button>
          ) : null}
          {handleNext ? (
            <Button
              size="lg"
              isLoading={isRecipeFormMutating[isRecipeFormMutating.length - 1]}
              onClick={handleNext}
            >
              Selanjutnya
            </Button>
          ) : null}
          {handlePrev ? (
            <Button
              size="lg"
              isLoading={isRecipeFormMutating[isRecipeFormMutating.length - 1]}
              onClick={handlePrev}
              variant="outline"
            >
              Kembali
            </Button>
          ) : null}
          <DeleteRecipeButton />
        </Flex>
      </Flex>
    </AdminLayout>
  );
}

function DeleteRecipeButton() {
  const recipeId = useParams()['recipeId'];
  const token = useAuthStore((s) => s.access_token);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteRecipeMutation = useMutation({
    mutationFn: () => deleteRecipe(token, recipeId || ''),
    onSuccess: () => {
      onClose();
      navigate('/recipe/list');
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'list'],
      });
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  if (!recipeId) return null;

  return (
    <>
      <Button
        variant="ghost"
        size="lg"
        onClick={onOpen}
        color="red"
        bg="transparent"
        _hover={{
          bg: 'transparent',
        }}
      >
        Hapus
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Hapus Resep?
            </AlertDialogHeader>

            <AlertDialogBody>
              Apakah Anda yakin? Anda tidak bisa membatalkan tindakan ini
              setelahnya.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                isLoading={deleteRecipeMutation.isPending}
                ref={cancelRef}
                onClick={onClose}
                variant="outline"
              >
                Batal
              </Button>
              <Button
                data-testid="DeleteRecipe"
                isLoading={deleteRecipeMutation.isPending}
                colorScheme="red"
                onClick={() => {
                  deleteRecipeMutation.mutate();
                }}
                ml={3}
                color="white"
                bg="red"
                _hover={{
                  bg: 'red',
                }}
              >
                Hapus
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
