import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  StackDirection,
  StackDivider,
  StackProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

export interface RadioButtonGroupProps<TFields extends FieldValues>
  extends Omit<RadioGroupProps, 'children'> {
  form: UseFormReturn<TFields>;
  name: Path<TFields>;
  label?: string;
  options: {
    label: string;
    value: string | number;
    children?: ReactNode;
  }[];
  size?: string;
  direction?: StackDirection;
  stackProps?: StackProps;
}

export function RadioButtonGroup<TFields extends FieldValues>({
  form,
  options,
  size = 'md',
  direction = 'column',
  name,
  label,
  ...props
}: RadioButtonGroupProps<TFields>) {
  const {
    register,
    formState: { errors },
  } = form;
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';
  return (
    <FormControl id={props.id} isInvalid={isInvalid}>
      {label && (
        <FormLabel aria-label={label} htmlFor={props.id}>
          {label}
        </FormLabel>
      )}
      <RadioGroup aria-label={label} {...props}>
        <Stack
          direction={direction}
          divider={<StackDivider borderColor="gray.200" borderWidth="1px" />}
          gap={4}
          {...props.stackProps}
        >
          {options.map((option, index) => (
            <Radio
              key={`${props.id}-${index}`}
              id={index === 0 ? `{${props.id}-${index}}` : undefined}
              m={4}
              value={option.value.toString()}
              size={size}
              borderColor="gray.75"
              _checked={{ borderColor: 'blue', borderWidth: '4px' }}
              {...register(name)}
            >
              {option.children ? option.children : option.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      <FormErrorMessage color="red">{errorMessage as string}</FormErrorMessage>
    </FormControl>
  );
}
