import {
  BackofficeRecipeListQuery,
  BackofficeRecipeListResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { queryOptions, useQuery } from '@tanstack/react-query';

export const getRecipeList = async (
  token: string,
  query: BackofficeRecipeListQuery
) => {
  const url = `/backoffice/recipe`;
  const { data } = await apiInstance(token).get<BackofficeRecipeListResponse>(
    url,
    { params: query }
  );

  return data;
};

export const BACKOFFICE_RECIPE_QUERY_KEY = 'recipes';

export function recipeListQueryOptions(
  token: string,
  query: BackofficeRecipeListQuery
) {
  return queryOptions({
    queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, query],
    queryFn: () => getRecipeList(token, query),
  });
}

export function useRecipeList(
  query: Omit<BackofficeRecipeListQuery, 'only_published'> & { only_published?: boolean } = {
    page: 1,
    size: 20,
  }
) {
  const token = useAuthStore((s) => s.access_token);
  return useQuery(recipeListQueryOptions(token, { ...query, only_published: query.only_published ?? false }));
}
