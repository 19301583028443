import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputRightAddon,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { STOCK_UI } from '@diamond/shared/decimal-util-web';
import { BackofficeUpdateRecipeListBody } from '@diamond/shared/types';
import { TextField } from '@diamond/shared/ui';
import { adminCurrencyFormatter } from '@diamond/shared/utils';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import { dinero, toDecimal } from 'dinero.js';
import { useCallback, useContext } from 'react';
import { get, UseFormReturn, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDebounceCallback } from 'usehooks-ts';

import { useRecipeDetail, useUpdateRecipeMutation } from '../../../api';
import { useIngredientProdutcs } from '../../../hooks';
import { SelectProductField } from '../fields';
import { AlternativeProductsContext } from './alternative-products-context';
import { ProductModeContext } from './product-mode-context';
import { RecipeIngredientsFields } from './types';
import {
  copyRecommendedProductsToAlternative,
  removeAlternativeProducts,
} from './utils';

type IngredientsFieldArrayProps = {
  form: UseFormReturn<RecipeIngredientsFields>;
  ingredientGroupIndex: number;
  ingredientIndex: number;
  handleRemove: () => void;
  shouldHideRemoveBtn: boolean;
};

export function IngredientsFieldArray({
  form,
  ingredientGroupIndex,
  ingredientIndex,
  handleRemove,
  shouldHideRemoveBtn,
}: IngredientsFieldArrayProps) {
  const { data, isRefetching } = useRecipeDetail();
  const isDisabled = data?.is_published;

  const productModeType = useContext(ProductModeContext);
  const hasAlternativeProducts = useContext(AlternativeProductsContext);
  const recommended_products = useWatch({
    control: form.control,
    name: `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.recommended_products` as const,
  });

  const {
    defaultOptions,
    loadOptions,
    value,
    valueFromData,
    onSubmit,
    optimisticIngredientsUpdate,
    isMutationPending,
  } = useIngredientProdutcs({
    type: productModeType,
    ingredientGroupIndex,
    ingredientIndex,
  });

  const recipeId = useParams()['recipeId'] as string;
  const updateRecipeMutation = useUpdateRecipeMutation(recipeId);

  // Debounce submit and skip validation
  const submit = useCallback(() => {
    const data = removeAlternativeProducts(form.getValues());
    updateRecipeMutation.mutate(
      data as unknown as BackofficeUpdateRecipeListBody
    );
  }, [form, updateRecipeMutation]);
  const debouncedSubmit = useDebounceCallback(submit, 600);

  const isIngredientsLoading =
    isMutationPending || updateRecipeMutation.isPending || isRefetching;

  const amountName =
    `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.amount` as const;
  const amountError = get(form.formState.errors, amountName)?.message;

  const uomName =
    `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.amount_unit` as const;
  const uomError = get(form.formState.errors, uomName)?.message;

  return (
    <Box>
      <Flex alignItems="flex-start" gap="2">
        <Box flex="0 0 250px">
          <TextField
            register={form.register}
            name={`ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.title`}
            label="Nama Bahan"
            placeholder="Masukkan nama bahan"
            isDisabled={
              productModeType === 'alternative_products' || isDisabled
            }
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
            errors={form.formState.errors}
          />
        </Box>
        <Box flex="1 0 30px">
          <TextField
            register={form.register}
            name={amountName}
            label="Takaran Resep"
            registerOptions={{
              valueAsNumber: true,
              onChange: debouncedSubmit,
            }}
            type="number"
            errors={form.formState.errors}
            placeholder="-"
            isDisabled={
              productModeType === 'alternative_products' || isDisabled
            }
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
            rightAddon={
              <InputRightAddon px="0">
                <Select
                  isDisabled={
                    productModeType === 'alternative_products' || isDisabled
                  }
                  border="none"
                  {...form.register(uomName)}
                >
                  {['gr', 'ml', 'tbsp', 'tsp', 'pcs'].map((uom) => (
                    <option>{uom}</option>
                  ))}
                </Select>
              </InputRightAddon>
            }
            customIsInvalid={Boolean(amountError || uomError)}
            extra={
              <FormErrorMessage textColor="red">
                {amountError} {uomError}
              </FormErrorMessage>
            }
          />
        </Box>
        <Flex direction="column">
          <Box h="21px" mb="2" />
          <Button
            type="button"
            flex="0 0 auto"
            variant="outline"
            name={`ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.add_product`}
            leftIcon={<Icon fontSize="md" as={AddOutlined} />}
            isDisabled={
              recommended_products.length > 0 ||
              productModeType === 'alternative_products' ||
              isDisabled
            }
            onClick={async () => {
              const isValid = await form.trigger([
                `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.title`,
                `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.amount`,
              ]);
              if (isValid) {
                form.setValue(
                  `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.recommended_products` as const,
                  ['']
                );
              }
            }}
          >
            Produk
          </Button>
        </Flex>
        <Flex direction="column">
          <Box h="21px" mb="2" />
          <IconButton
            onClick={handleRemove}
            isDisabled={
              productModeType === 'alternative_products' ||
              shouldHideRemoveBtn ||
              isDisabled
            }
            icon={<Icon as={CloseOutlined} />}
            variant="ghost"
            bg="white"
            color="gray.75"
            aria-label="Delete"
          />
        </Flex>
      </Flex>
      {recommended_products.length > 0 ? (
        <Flex
          mt="3"
          alignItems="flex-start"
          gap="2"
          data-testid={`ProductIngredients-${ingredientGroupIndex}-${ingredientIndex}-${productModeType}`}
        >
          <Box flex="0 0 250px">
            <SelectProductField
              control={form.control}
              name={`ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.${productModeType}.0`}
              label="Produk"
              labelProps={{
                color: 'gray.75',
                fontSize: 'sm',
              }}
              defaultOptions={defaultOptions}
              value={value}
              loadOptions={loadOptions}
              handleOnSelect={(value) => {
                optimisticIngredientsUpdate(
                  value,
                  ingredientGroupIndex,
                  ingredientIndex,
                  productModeType
                );
                const values = copyRecommendedProductsToAlternative(
                  form.getValues(),
                  productModeType,
                  hasAlternativeProducts
                );
                onSubmit(values);
              }}
              isDisabled={isDisabled || isIngredientsLoading}
            />
          </Box>
          <Box flex="0 0 90px">
            <FormControl>
              <FormLabel color="gray.75" fontSize="sm">
                qty.cart
              </FormLabel>
              {isIngredientsLoading ? (
                <Flex alignItems="center" justifyContent="center" h="40px">
                  <Spinner />
                </Flex>
              ) : (
                <Input
                  type="text"
                  isDisabled
                  color="black"
                  value={
                    valueFromData
                      ? Number(
                          toDecimal(
                            dinero({
                              amount: valueFromData.minimum_order_quantity,
                              currency: STOCK_UI,
                            })
                          )
                        )
                      : '-'
                  }
                />
              )}
            </FormControl>
          </Box>
          <Box h="69px" flex="1">
            <Text
              color="black"
              fontSize="sm"
              fontWeight="medium"
              textAlign="center"
            >
              HPP Per Porsi
            </Text>
            {isIngredientsLoading ? (
              <Flex alignItems="center" justifyContent="center" h="52px">
                <Spinner />
              </Flex>
            ) : (
              <Text
                flex="1 0 auto"
                variant="outline"
                h="40px"
                fontWeight="bold"
                fontSize="lg"
                textAlign="center"
                lineHeight="3rem"
              >
                {valueFromData
                  ? adminCurrencyFormatter(valueFromData.cogs_per_portion)
                  : '-'}
              </Text>
            )}
          </Box>
          <Flex direction="column">
            <Box h="15px" mb="2" />
            <IconButton
              isDisabled={
                productModeType === 'alternative_products' || isDisabled
              }
              onClick={() => {
                const fieldName =
                  `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.recommended_products` as const;
                /**
                 * Resets this field to prevent it from reappearing when data is invalidated.
                 * This is necessary because the parent form hook includes the option:
                 *
                 * resetOptions: {
                 *     keepDirtyValues: true,
                 * }
                 */
                form.resetField(fieldName);
                form.setValue(fieldName, []);
                submit();
              }}
              icon={<Icon as={CloseOutlined} />}
              variant="ghost"
              bg="white"
              color="gray.75"
              aria-label="Delete"
            />
          </Flex>
        </Flex>
      ) : null}
      <Divider mt="4" />
    </Box>
  );
}
