import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { BackofficeRecipeSectionListResponse } from '@diamond/shared/types';
import { showToast } from '@diamond/shared/ui';
import { EditOutlined } from '@mui/icons-material';

import { useRecipeSectionDetail, useRecipeSectionUpdate } from '../api';
import {
  RecipeSection,
  RecipeSectionHomepageForm,
} from './forms/recipe-section-homepage-form';

type EditRecipeSectionActionProps = {
  section: BackofficeRecipeSectionListResponse['data'][number];
};

export function EditRecipeSectionHomepageAction({
  section,
}: EditRecipeSectionActionProps) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: updateRecipeSection } = useRecipeSectionUpdate();

  const { data, dataUpdatedAt } = useRecipeSectionDetail(section.id, section);

  const showSuccessToast = (title: string) => {
    showToast(toast, 'success', title);
    onClose();
  };

  const showErrorToast = () => {
    showToast(toast, 'error', 'Gagal Menyimpan Section');
  };

  const handleUpdateTitle = (data: RecipeSection) => {
    updateRecipeSection(
      {
        params: { id: section.id },
        body: {
          title: data.title,
          type: data.type || 'manual',
        },
      },
      {
        onSuccess: () => showSuccessToast('Berhasil Menyimpan Section'),
        onError: showErrorToast,
      }
    );
  };

  const handleSubmit = async (data: RecipeSection) => {
    handleUpdateTitle(data);
  };

  return (
    <>
      <IconButton
        aria-label="Edit section"
        onClick={onOpen}
        icon={<EditOutlined />}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="lg">
          <ModalHeader>
            <Text>Edit Section</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RecipeSectionHomepageForm
              key={dataUpdatedAt}
              section={data}
              onSubmit={handleSubmit}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
