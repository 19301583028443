import {
  BackofficeCreateNotification,
  BackofficeRecipeNotificationResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation } from '@tanstack/react-query';

export const createRecipeNotification = async (
  token: string,
  payload: BackofficeCreateNotification
) => {
  const url = `/backoffice/recipe/notifications`;
  return apiInstance(token).post<BackofficeRecipeNotificationResponse>(
    url,
    payload
  );
};

type UseCreateRecipeNotificationMutationArgs = {
  onSuccess: () => void;
  onSettled: () => void;
};

export function useCreateRecipeNotificationMutation({
  onSuccess,
  onSettled,
}: UseCreateRecipeNotificationMutationArgs) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: (payload: BackofficeCreateNotification) =>
      createRecipeNotification(token, payload),
    onSuccess,
    onSettled,
  });
}
