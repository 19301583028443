import {
  API_URL,
  DEV_BUYER_HOSTNAME,
  PROD_BUYER_HOSTNAME,
  STAGING_BUYER_HOSTNAME,
} from '@diamond/shared/environments';

export const constructSlug = (slug: string) => {
  if (API_URL.includes('dev')) {
    return `https://${DEV_BUYER_HOSTNAME}/recipes/${slug}`;
  }
  if (API_URL.includes('staging')) {
    return `https://${STAGING_BUYER_HOSTNAME}/recipes/${slug}`;
  }
  if (API_URL.includes('127.0.0.1')) {
    return `http://localhost:4200/recipes/${slug}`;
  }
  return `https://${PROD_BUYER_HOSTNAME}/recipes/${slug}`;
};
