import {
  BackofficeRecipeTagQuery,
  BackofficeRecipeTagsResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { BACKOFFICE_RECIPE_QUERY_KEY } from './get-recipe-list';

export const getRecipeTags = async (
  token: string,
  params: BackofficeRecipeTagQuery
) => {
  const url = `/backoffice/recipe/tag`;
  const { data } = await apiInstance(token).get<BackofficeRecipeTagsResponse>(
    url,
    { params }
  );
  return data;
};

function selectRecipeTags(data: BackofficeRecipeTagsResponse) {
  return {
    ...data,
    options: data.data.map((v) => ({
      label: v.name,
      value: v.id,
    })),
  };
}

function recipeTagsQueryOptions(
  token: string,
  query: BackofficeRecipeTagQuery
) {
  return queryOptions({
    queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'tags', query],
    queryFn: () => getRecipeTags(token, query),
    select: selectRecipeTags,
    staleTime: 10 * 60 * 1000, // 10 Minutes,
  });
}

export function useRecipeTags(
  query: BackofficeRecipeTagQuery = {
    page: 1,
    size: 20,
  }
) {
  const token = useAuthStore((s) => s.access_token);
  return useQuery(recipeTagsQueryOptions(token, query));
}
