import {
  BackofficePromoQuery,
  BackofficePromoResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useInfiniteQuery } from '@tanstack/react-query';

export const getPromoList = async (
  token: string,
  status: BackofficePromoQuery,
  page: number,
  search_query: string,
  size: number
) => {
  const url = `/backoffice/promo`;
  const { data } = await apiInstance(token).get<BackofficePromoResponse>(url, {
    params: {
      status: status,
      page,
      size,
      search_query: search_query,
    },
  });

  return {
    data: data.data,
    nextPage: page < data.pagination.pages ? page + 1 : null,
  };
};

export const BACKOFFICE_PROMO_QUERY_KEY = 'BackofficePromo';

export function usePromoList(
  status: BackofficePromoQuery,
  search_query: string,
  size: number
) {
  const { access_token } = useAuthStore();
  return useInfiniteQuery({
    queryKey: [BACKOFFICE_PROMO_QUERY_KEY, status, search_query, size],
    queryFn: ({ pageParam }) =>
      getPromoList(access_token, status, pageParam, search_query, size),
    initialPageParam: 1,
    getNextPageParam: ({ nextPage }) => nextPage,
    gcTime: 0,
  });
}
