/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { PatchDataWrapper } from '@diamond/shared/data-access';
import { magnifyValue, STOCK_UI } from '@diamond/shared/decimal-util-web';
import {
  BackofficeActivitiesOrderItem,
  BackofficeActivitiesOrderItems,
  BackofficeOrderUpdate,
  BackofficeRevalidateUpdate,
} from '@diamond/shared/types';
import { LabelPromo, PromoPrice, showToast } from '@diamond/shared/ui';
import { adminCurrencyFormatter } from '@diamond/shared/utils';
import { IndeterminateCheckbox } from '@diamond/sol-admin/components';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import {
  useStorageType,
  useValuationType,
} from '@diamond/sol-admin/features/product';
import CloseIcon from '@mui/icons-material/Close';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { CellContext, HeaderContext, Row } from '@tanstack/react-table';
import { dinero, toDecimal } from 'dinero.js';
import { useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { ORDER_CHANGELOG_QUERY_KEY } from '../api';
import {
  useUpdateActivitiesDetailColumn,
  useUpdateActivitiesDetailQueryData,
} from '../hooks';
import { ChangeQuantityModal } from './change-quantity-modal';

const columnHelper = createColumnHelper<BackofficeActivitiesOrderItems[0]>();

const API_URL = 'backoffice/order';

export const infoColumns = [
  columnHelper.accessor('item_code', {
    cell: (info) => {
      return (
        <Text>
          {info.row.original.product_item_code
            ? info.row.original.product_item_code
            : info.getValue()}
        </Text>
      );
    },
    header: 'Kode Item',
  }),
  columnHelper.accessor('images_medium', {
    cell: (info) => {
      const imageData = info.row.original.images_medium;
      const productImage = info.row.original.product_images;
      const image = productImage
        ? productImage[productImage.length - 1]
        : imageData
        ? imageData[imageData.length - 1]
        : 'assets/images/product_placeholder.png';
      return (
        <Image
          src={image}
          alt={''}
          objectFit="cover"
          borderRadius="md"
          border="1px"
          borderColor="gray.33"
          w="10"
          h="10"
          loading="lazy"
        />
      );
    },
    header: 'Gambar',
  }),
  columnHelper.accessor('title', {
    cell: (info) => (
      <Box>
        <Text mb="1">{info.getValue()}</Text>
        {info.row.original.is_discount === true ? (
          <Flex>
            <Stack mr="2"></Stack>
            <LabelPromo type="discount" />
          </Flex>
        ) : info.row.original.is_discount === false ? (
          <Flex>
            <Stack mr="2"></Stack>
            <LabelPromo type="cashback" />
          </Flex>
        ) : undefined}
      </Box>
    ),
    header: 'Nama Produk',
  }),
  columnHelper.accessor('quantity', {
    cell: (info) => {
      const quantityItem = info.row.original.quantity_free_goods
        ? info.row.original.quantity_free_goods
        : Number(info.getValue());
      const multiplier = Number(
        toDecimal(
          dinero({
            amount: info.row.original.order_quantity_multiple,
            currency: STOCK_UI,
          })
        )
      );
      const jumlahCheckout = Number(
        toDecimal(
          dinero({
            amount: quantityItem,
            currency: STOCK_UI,
          })
        )
      );
      if (multiplier > 0 && multiplier !== 1) {
        return (
          <VStack alignItems="flex-start">
            <Text>{jumlahCheckout}</Text>
            <Text color="gray">(Kelipatan: {multiplier})</Text>
          </VStack>
        );
      } else {
        return jumlahCheckout;
      }
    },
    header: 'Jumlah Checkout',
  }),
];

export const quantityColumns = [
  columnHelper.accessor('processed_quantity', {
    cell: (info) => {
      const quantityItem = info.row.original.quantity_free_goods
        ? info.row.original.quantity_free_goods
        : Number(info.getValue());
      if (info.getValue() !== 0) {
        return Number(
          toDecimal(
            dinero({
              amount: quantityItem,
              currency: STOCK_UI,
            })
          )
        );
      } else {
        return quantityItem;
      }
    },
    header: 'Diproses',
  }),
  columnHelper.accessor('delivered_quantity', {
    cell: (info) => {
      const quantityItem = info.row.original.quantity_free_goods
        ? info.row.original.quantity_free_goods
        : Number(info.getValue());
      if (info.getValue() !== 0) {
        return Number(
          toDecimal(
            dinero({
              amount: quantityItem,
              currency: STOCK_UI,
            })
          )
        );
      } else {
        return info.getValue();
      }
    },
    header: 'Dikirim',
  }),
  columnHelper.accessor('received_quantity', {
    cell: (info) => {
      const quantityItem = info.row.original.quantity_free_goods
        ? info.row.original.quantity_free_goods
        : Number(info.getValue());
      if (info.getValue() !== 0) {
        return Number(
          toDecimal(
            dinero({
              amount: quantityItem,
              currency: STOCK_UI,
            })
          )
        );
      } else {
        return info.getValue();
      }
    },
    header: 'Diterima',
  }),
];

type BackOfficeCustomBadge = {
  is_new_product: boolean;
};

type CustomColumnType = BackofficeActivitiesOrderItem & BackOfficeCustomBadge;

export function EditableColumns(
  setSelectedData: (value: Row<BackofficeActivitiesOrderItem>[]) => void,
  selectedData: Row<BackofficeActivitiesOrderItem>[],
  refetch: () => void,
  activitiesId: string,
  cancelReasonData: { label: string; value: string }[],
  isDisable: boolean,
  queryKey: QueryKey = []
) {
  const session = useAuthStore();
  const toast = useToast();
  const { patchData } = PatchDataWrapper<
    BackofficeOrderUpdate & BackofficeRevalidateUpdate
  >(API_URL, session.access_token, 'admin');

  function handleSelectAll(
    values: Row<BackofficeActivitiesOrderItem>[],
    isChecked: boolean
  ) {
    let list = [];
    if (!isChecked) {
      for (let i = 0; i < values.length; i++) {
        const el = values[i];
        list.push(el);
      }
    } else {
      list = [];
    }
    setSelectedData(list);
  }

  function handleSelectOneRow(
    value: Row<BackofficeActivitiesOrderItem>,
    isCheck: boolean
  ) {
    const list = [...selectedData];
    if (list.length > 0 && isCheck) {
      list.splice(list.indexOf(value), 1);
      setSelectedData(list);
    } else {
      setSelectedData([...selectedData, value]);
    }
  }

  const checkboxColumn = [
    {
      id: 'select',
      header: ({ table }: HeaderContext<CustomColumnType, unknown>) => {
        return (
          <IndeterminateCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            onClick={() => {
              handleSelectAll(
                table.getRowModel().rows,
                table.getIsAllRowsSelected()
              );
            }}
            disabled={isDisable}
          />
        );
      },
      cell: ({ row }: CellContext<CustomColumnType, unknown>) => {
        const isNewProduct = row.original.is_new_product;
        const freeGood = row.original.quantity_free_goods;
        return (
          <VStack alignItems="flex-start">
            {isNewProduct && (
              <span
                style={{
                  display: 'inline-block',
                  backgroundColor: 'red',
                  padding: '0.25rem 0.5rem',
                  fontSize: '75%',
                  fontWeight: 600,
                  lineHeight: 1,
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  verticalAlign: 'baseline',
                  borderRadius: '0.25rem',
                  color: 'white',
                }}
              >
                New
              </span>
            )}
            {freeGood ? (
              <LabelPromo type="free-item" isFreeGoods />
            ) : (
              <IndeterminateCheckbox
                checked={row.getIsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
                onClick={() => {
                  handleSelectOneRow(row, row.getIsSelected());
                }}
                disabled={isDisable}
              />
            )}
          </VStack>
        );
      },
    },
  ];

  const editableColumns = [
    columnHelper.accessor('confirmed_quantity', {
      cell: (info) => {
        const [OpenModalPromo, setOpenModalPromo] = useState<boolean>(false);
        const [values, setValues] = useState<string>('');
        const quantityItem =
          info.row.original.quantity_free_goods && Number(info.getValue()) > 0
            ? info.row.original.quantity_free_goods
            : Number(info.getValue());
        const jumlahDikonfimasi = Number(
          toDecimal(
            dinero({
              amount: quantityItem,
              currency: STOCK_UI,
            })
          )
        );
        if (info.row.getIsSelected() === true) {
          // eslint-disable-next-line
          const setQueryData = useUpdateActivitiesDetailQueryData({
            queryKey,
            info,
            name: 'confirmed_quantity',
          });
          // eslint-disable-next-line
          const queryClient = useQueryClient();
          // eslint-disable-next-line
          const setValue = useUpdateActivitiesDetailColumn({
            onUpdate(value) {
              const confirmQty = magnifyValue(value, 'stock');
              setQueryData(confirmQty.magnifiedValue);
              patchData(
                { confirmed_quantity: confirmQty.magnifiedValue },
                '',
                `${activitiesId}/item/${info.row.original.id}`
              ).then(async (res) => {
                if (res?.empty_stock.status === true) {
                  showToast(
                    toast,
                    'error',
                    'mohon maaf stok barang sudah habis, hubungi admin SAP'
                  );
                }
                refetch();
                await queryClient.invalidateQueries({
                  queryKey: [ORDER_CHANGELOG_QUERY_KEY],
                });
              });
            },
          });

          return (
            <div className="px-1">
              <NumericFormat
                thousandSeparator=","
                style={{
                  borderColor: 'gray',
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 4,
                }}
                allowNegative={false}
                defaultValue={jumlahDikonfimasi}
                onValueChange={(values) => {
                  if (values.value !== '') {
                    patchData(
                      '',
                      `${activitiesId}/item/${info.row.original.id}/revalidate`
                    ).then(async (res) => {
                      if (res?.data.is_valid === true) {
                        setValue(values.value);
                      } else {
                        setValues(values.value);
                        setOpenModalPromo(true);
                      }
                    });
                  }
                }}
              />
              <ChangeQuantityModal
                isOpen={OpenModalPromo}
                onClose={() => setOpenModalPromo(false)}
                text={info.row.original.mechanism ?? ''}
                duration={''}
                onSubmit={() => setValue(values)}
                item={{
                  id: info.row.original.product_id
                    ? info.row.original.product_id
                    : '',
                  img: info.row.original.product_images
                    ? info.row.original.product_images[0]
                    : '',
                  price: info.row.original.price,
                  qty: (info.row.original.quantity_free_goods || 0) / 1000,
                  uom: info.row.original.sales_unit_desc,
                }}
                isDiscount={
                  info.row.original.is_discount === undefined ? false : true
                }
              />
            </div>
          );
        } else {
          return (
            <NumericFormat
              value={jumlahDikonfimasi}
              displayType={'text'}
              thousandSeparator=","
            />
          );
        }
      },
      header: 'Jumlah Dikonfirmasi',
    }),
    columnHelper.accessor('price', {
      cell: (info) => {
        const quantityItem = info.row.original.quantity_free_goods;

        return (
          <Stack>
            <Text>
              {info.row.original.is_discount
                ? adminCurrencyFormatter(
                    info.row.original.price_after_discount ?? 0
                  )
                : quantityItem
                ? undefined
                : adminCurrencyFormatter(info.getValue())}
            </Text>
            {info.row.original.is_discount ? (
              <PromoPrice
                formattedPrice={adminCurrencyFormatter(info.getValue())}
                discountAmount={info.row.original.deduction_amount}
                isActive
                isFreeGoods
              />
            ) : quantityItem ? (
              <PromoPrice
                formattedPrice={adminCurrencyFormatter(info.row.original.price)}
                isFreeGoods
              />
            ) : undefined}
          </Stack>
        );
      },
      header: 'Harga Checkout',
    }),
    columnHelper.accessor('latest_price', {
      cell: (info) => {
        if (
          info.row.getIsSelected() === true &&
          info.row.original.can_overwrite_price
        ) {
          // eslint-disable-next-line
          const setQueryData = useUpdateActivitiesDetailQueryData({
            queryKey,
            info,
            name: 'latest_price',
          });
          // eslint-disable-next-line
          const queryClient = useQueryClient();
          // eslint-disable-next-line
          const setValue = useUpdateActivitiesDetailColumn({
            onUpdate(value) {
              setQueryData(value);
              patchData(
                { latest_price: value },
                '',
                `${activitiesId}/item/${info.row.original.id}`
              ).then(async () => {
                refetch();
                await queryClient.invalidateQueries({
                  queryKey: [ORDER_CHANGELOG_QUERY_KEY],
                });
              });
            },
          });

          return (
            <div className="px-1">
              <NumericFormat
                prefix="Rp."
                thousandSeparator=","
                style={{
                  borderColor: 'gray',
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 4,
                }}
                allowNegative={false}
                defaultValue={
                  info.row.original.is_discount
                    ? adminCurrencyFormatter(
                        info.row.original.price_after_discount ?? 0
                      )
                    : adminCurrencyFormatter(info.getValue())
                }
                onValueChange={(values) => setValue(values.value)}
              />
            </div>
          );
        }
        if (!info.row.original.can_overwrite_price) {
          return info.row.original.is_discount === true
            ? adminCurrencyFormatter(
                info.row.original.price_after_discount ?? 0
              )
            : info.row.original.quantity_free_goods
            ? 0
            : adminCurrencyFormatter(info.getValue());
        } else {
          return info.row.original.is_discount === true
            ? adminCurrencyFormatter(
                info.row.original.price_after_discount ?? 0
              )
            : info.row.original.quantity_free_goods
            ? 0
            : adminCurrencyFormatter(info.getValue());
        }
      },
      header: 'Harga Akhir',
    }),
    columnHelper.accessor('subtotal', {
      cell: (info) =>
        info.row.original.quantity_free_goods
          ? 0
          : adminCurrencyFormatter(info.getValue()),
      header: 'Total Harga',
    }),
    columnHelper.accessor('note', {
      cell: (info) => {
        if (info.row.getIsSelected() === true) {
          // eslint-disable-next-line
          const setQueryData = useUpdateActivitiesDetailQueryData({
            queryKey,
            info,
            name: 'note',
          });
          // eslint-disable-next-line
          const setValue = useUpdateActivitiesDetailColumn({
            onUpdate(value) {
              setQueryData(value);
              patchData(
                { note: value },
                '',
                `${activitiesId}/item/${info.row.original.id}`
              ).then(async () => {
                refetch();
              });
            },
            allowEmpty: true,
          });

          return (
            <div className="px-1">
              <input
                style={{
                  borderColor: 'gray',
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 4,
                }}
                className={'cursor-pointer'}
                type="text"
                defaultValue={info.getValue() ?? ''}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </div>
          );
        } else {
          return info.getValue();
        }
      },
      header: 'Catatan Produk',
    }),
    columnHelper.accessor('is_not_processed', {
      cell: (info) => {
        // eslint-disable-next-line
        const setQueryDataCheck = useUpdateActivitiesDetailQueryData({
          queryKey,
          info,
          name: 'is_not_processed',
        });
        // eslint-disable-next-line
        const setQueryData = useUpdateActivitiesDetailQueryData({
          queryKey,
          info,
          name: 'confirmed_quantity',
        });
        const isChecked = info.row.original.is_not_processed;
        // eslint-disable-next-line
        const handleClick = useCallback(() => {
          if (!isChecked) {
            setQueryDataCheck(true);
            setQueryData(0);
            patchData(
              { confirmed_quantity: 0 },
              '',
              `${activitiesId}/item/${info.row.original.id}`
            ).then(async () => {
              refetch();
            });
          }
        }, []);
        return (
          <div className="px-1">
            <input
              type="checkbox"
              checked={isChecked}
              onClick={handleClick}
              className={'cursor-pointer'}
            />
          </div>
        );
      },
      header: 'Tidak Diproses',
    }),
    columnHelper.accessor('is_updated', {
      cell: (info) =>
        info.getValue() === true
          ? `Diubah ${
              info.row.original.update_origin === 'admin'
                ? 'oleh Admin'
                : 'oleh SAP'
            }`
          : '-',
      header: 'Pernah Diubah',
    }),
    columnHelper.accessor('product_storage', {
      cell: (info) => {
        if (info.row.getIsSelected() === true) {
          // eslint-disable-next-line
          const { data } = useStorageType();
          const emptyItem = {
            label: '',
            value: '',
          };
          const storageTypeItems = data
            ? [
                emptyItem,
                ...data.map((value) => {
                  return {
                    label: value,
                    value: value,
                  };
                }),
              ]
            : [emptyItem];

          // eslint-disable-next-line
          const setQueryData = useUpdateActivitiesDetailQueryData({
            queryKey,
            info,
            name: 'product_storage',
          });

          // eslint-disable-next-line
          const handleEdit = useCallback((value: string) => {
            if (value) {
              setQueryData(value);
              patchData(
                { product_storage: value },
                '',
                `${activitiesId}/item/${info.row.original.id}`
              ).then(async () => {
                refetch();
              });
            }
          }, []);

          return (
            <div className="px-1">
              {storageTypeItems.length > 1 && (
                <select
                  name="product_storage"
                  id="product_storage"
                  style={{ padding: '4px' }}
                  onChange={(e) => handleEdit(e.target.value)}
                  defaultValue={info.row.original.product_storage ?? []}
                >
                  {storageTypeItems.map((value, index) => {
                    return (
                      <option value={value.value} key={index}>
                        {value.label}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          );
        } else {
          return info.getValue();
        }
      },
      header: 'Tempat Penyimpanan',
    }),
    columnHelper.accessor('valuation_type', {
      cell: (info) => {
        if (info.row.getIsSelected() === true) {
          // eslint-disable-next-line
          const { valuationTypeData } = useValuationType(
            info.row.original.product_id,
            { size: 50, page: 1 }
          );
          const emptyItem = {
            label: '',
            value: '',
          };
          const valuationTypeItems = valuationTypeData
            ? [
                emptyItem,
                ...valuationTypeData.data.map((value) => {
                  return {
                    label: value.valuation_type,
                    value: value.valuation_type,
                  };
                }),
              ]
            : [emptyItem];

          // eslint-disable-next-line
          const setQueryData = useUpdateActivitiesDetailQueryData({
            queryKey,
            info,
            name: 'valuation_type',
          });

          // eslint-disable-next-line
          const handleEdit = useCallback((value: string) => {
            if (value) {
              setQueryData(value);
              patchData(
                { valuation_type: value },
                '',
                `${activitiesId}/item/${info.row.original.id}`
              ).then(async () => {
                refetch();
              });
            }
          }, []);
          return (
            <div className="px-1">
              {valuationTypeItems.length > 1 && (
                <select
                  name="valuation_type"
                  id="valuation_type"
                  style={{ padding: '4px' }}
                  onChange={(e) => handleEdit(e.target.value)}
                  defaultValue={info.row.original.valuation_type ?? ''}
                >
                  {valuationTypeItems.map((value, index) => {
                    return (
                      <option value={value.value} key={index}>
                        {value.label}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          );
        } else {
          return info.getValue();
        }
      },
      header: 'Valuation Type',
    }),

    columnHelper.accessor('cancel_reason_id', {
      cell: (info) => {
        if (info.row.getIsSelected() === true) {
          // eslint-disable-next-line
          const setQueryData = useUpdateActivitiesDetailQueryData({
            queryKey,
            info,
            name: 'cancel_reason_id',
          });
          // eslint-disable-next-line
          const [value, setValue] = useState<string>();
          // eslint-disable-next-line
          const [deleteData, setDeleteData] = useState<boolean>(false);
          // eslint-disable-next-line
          useEffect(() => {
            if (value) {
              setQueryData(value);
              patchData(
                { cancel_reason_id: value },
                '',
                `${activitiesId}/item/${info.row.original.id}`
              ).then(async () => {
                refetch();
              });
            }
            if (deleteData) {
              setQueryData(null);
              patchData(
                { cancel_reason_id: null },
                '',
                `${activitiesId}/item/${info.row.original.id}`
              ).then(async () => {
                refetch();
              });
            }
          }, [value]);

          return (
            <HStack>
              <select
                name="cancel_reason_id"
                id="cancel_reason_id"
                style={{
                  padding: '4px',
                  borderWidth: '1px',
                  borderColor: 'grey',
                  borderRadius: '4px',
                  background: 'white',
                }}
                onChange={(e) => setValue(e.target.value)}
                defaultValue={info.row.original.cancel_reason_id ?? ''}
              >
                {cancelReasonData.map((value, index) => {
                  return (
                    <option value={value.value} key={index}>
                      {value.label}
                    </option>
                  );
                })}
              </select>
              <IconButton
                aria-label="x"
                size="xs"
                p="2px"
                icon={<CloseIcon />}
                onClick={() => {
                  setValue('');
                  setDeleteData(true);
                }}
              />
            </HStack>
          );
        } else {
          const cancelReason = cancelReasonData.filter(
            (value) => value.value === info.getValue()
          );
          return cancelReason[0] ? cancelReason[0].label : '-';
        }
      },
      header: 'Alasan Pembatalan',
    }),
  ];

  return { checkboxColumn, editableColumns };
}
