// export * from './lib/shared-theme';
import { extendTheme } from '@chakra-ui/react';

import {
  alertTheme,
  badgeTheme,
  Button,
  Checkbox,
  Input,
  Select,
  Stepper,
  Table,
  Tag,
} from './lib/components';
import * as foundations from './lib/foundations';

export const theme = extendTheme({
  ...foundations,
  components: {
    Input,
    Checkbox,
    Tag,
    Badge: badgeTheme,
    Alert: alertTheme,
    Table,
    Button,
    Link: {
      baseStyle: {
        '&:hover': { textDecoration: 'none' },
      },
    },
    Select,
    Stepper,
  },
});
