import {
  BackofficeGetProductList,
  BackofficeRecipeDetailResponse,
  BackofficeUpdateRecipeListBody,
  BackofficeUpdateRecipeListResponseRecommendedProducts,
  RecipeIngredientsSectionPayload,
} from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { getProducts } from '@diamond/sol-admin/features/product';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';

import {
  BACKOFFICE_RECIPE_QUERY_KEY,
  useRecipeDetail,
  useUpdateRecipeMutation,
} from '../api';
import { ProductModeType } from '../types';
import { useDebouncedPromise } from './use-debounced-promise';

type UseIngredientProduct = {
  type: 'recommended_products' | 'alternative_products';
  ingredientGroupIndex: number;
  ingredientIndex: number;
  index?: number;
};

export function useIngredientProdutcs({
  type,
  ingredientGroupIndex,
  ingredientIndex,
  index = 0,
}: UseIngredientProduct) {
  const params = useParams();
  const recipeId = params['recipeId'] as string;

  const token = useAuthStore((s) => s.access_token);

  const promiseOptions = useDebouncedPromise(
    async (search: string) =>
      getProducts(token, {
        page: 1,
        size: 10,
        search_query: search,
      }).then((products) => products.data.map(transformToOption)),
    1000
  );

  const queryClient = useQueryClient();
  const recipeDetailQuery = useRecipeDetail();

  const productOptionsQuery = useQuery({
    queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'products', { page: 1, size: 100 }],
    queryFn: () => getProducts(token, { page: 1, size: 100 }),
    select: (products) => products.data.map(transformToOption),
  });

  const valueRecommendedFromData: BackofficeUpdateRecipeListResponseRecommendedProducts =
    get(
      recipeDetailQuery.data,
      `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.recommended_products.${index}` as const
    );

  const valueFromAlternativeData: BackofficeUpdateRecipeListResponseRecommendedProducts =
    get(
      recipeDetailQuery.data,
      `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.alternative_products.${index}` as const
    );

  const valueFromData =
    type === 'recommended_products'
      ? valueRecommendedFromData
      : // Fallback to recommended values (Case when creating new alternative product)
        valueFromAlternativeData || valueRecommendedFromData;

  const updateRecipeMutation = useUpdateRecipeMutation(recipeId);

  const onSubmit = async (data: RecipeIngredientsSectionPayload) =>
    updateRecipeMutation.mutate(
      data as unknown as BackofficeUpdateRecipeListBody
    );

  const optimisticIngredientsUpdate = (
    value: SingleValue<Option>,
    ingredientGroupIndex: number,
    ingredientIndex: number,
    productModeType: ProductModeType
  ) => {
    const { item_code, title } = parseOption(value);
    queryClient.setQueryData<BackofficeRecipeDetailResponse>(
      [BACKOFFICE_RECIPE_QUERY_KEY, 'list', recipeId],
      (old): BackofficeRecipeDetailResponse | undefined => {
        if (old === undefined) return old;
        return {
          ...old,
          ingredient_groups: old.ingredient_groups.map((group, idxGroup) => {
            if (idxGroup === ingredientGroupIndex) {
              return {
                ...group,
                ingredients: group.ingredients.map((ig, idxIngredients) => {
                  if (idxIngredients === ingredientIndex) {
                    return {
                      ...ig,
                      [productModeType]: ig[productModeType].map((pm) => ({
                        ...pm,
                        item_code,
                        title,
                      })),
                    };
                  }
                  return ig;
                }),
              };
            }
            return group;
          }),
        };
      }
    );
  };

  return {
    defaultOptions: productOptionsQuery.data,
    value: valueFromData ? transformToOption(valueFromData) : undefined,
    loadOptions: promiseOptions,
    onSubmit,
    valueFromData,
    optimisticIngredientsUpdate,
    isMutationPending: updateRecipeMutation.isPending,
  };
}

type ProductItem = BackofficeGetProductList['data'][number];
type Option = {
  label: string;
  value: string;
};

function transformToOption<
  T extends Pick<ProductItem, 'item_code' | 'title' | 'id'>
>(v: T): Option {
  return {
    label: `${v.item_code} - ${v.title}`,
    value: v.id,
  };
}

function parseOption(v: SingleValue<ReturnType<typeof transformToOption>>) {
  if (!v)
    return {
      item_code: '',
      title: '',
      id: '',
    };

  const [item_code, title] = v.label.split(' - ');
  return {
    item_code,
    title,
    id: v.value,
  };
}
