import {
  BackofficeCreateNewRecipeListBody,
  BackofficeCreateNewRecipeListResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

export const createRecipe = async (
  token: string,
  payload: BackofficeCreateNewRecipeListBody
) => {
  const url = `/backoffice/recipe`;
  return apiInstance(token).post<BackofficeCreateNewRecipeListResponse>(
    url,
    payload
  );
};

type UseCreateRecipeMutationArgs = {
  onSuccess?: (data: BackofficeCreateNewRecipeListResponse) => void;
  onError?: (error: string) => void;
};

export function useCreateRecipeMutation({
  onSuccess,
  onError,
}: UseCreateRecipeMutationArgs) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: (payload: BackofficeCreateNewRecipeListBody) =>
      createRecipe(token, payload),
    onSuccess(data) {
      onSuccess?.(data.data);
    },
    onError(error) {
      if (isAxiosError(error)) {
        onError?.(error.response?.data.message);
      } else if (error instanceof Error) {
        onError?.(error.message);
        return;
      } else {
        onError?.('Unexpected Error');
      }
    },
  });
}
