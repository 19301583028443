import { Box, Fade, Link, Stack, useDisclosure } from '@chakra-ui/react';
import {
  ADDITIONAL_CLIENT_HEADERS,
  API_URL,
  DEFAULT_API_HEADERS,
} from '@diamond/shared/environments';
import {
  Alert,
  Button,
  Divider,
  PasswordField,
  Text,
} from '@diamond/shared/ui';
import { ResetPasswordValidationSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import shallow from 'zustand/shallow';

import { useAuthentication, useAuthStore } from '../context';

export function CreatePasswordPage() {
  const params = useParams();
  const navigate = useNavigate();
  const token = params['token'] as string;

  const [access_token] = useAuthStore((s) => [s.access_token], shallow);
  const { logout } = useAuthentication();

  const { isOpen: isVisible, onClose, onOpen } = useDisclosure();
  const [status, setStatus] = useState<boolean>(false);
  const [statusDesc, setStatusDesc] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Yup.InferType<typeof ResetPasswordValidationSchema>>({
    resolver: yupResolver(ResetPasswordValidationSchema),
  });

  const handleResponse = (
    success: boolean,
    message: string,
    redirectToLogin: boolean
  ) => {
    setStatus(success);
    setStatusDesc(message);
    if (redirectToLogin) {
      setTimeout(() => {
        onClose();
        navigate('/login');
      }, 2000);
    }
    setTimeout(() => onClose(), 3000);
    onOpen();
  };

  const onSubmit = async (
    formData: Yup.InferType<typeof ResetPasswordValidationSchema>
  ) => {
    fetch(`${API_URL}/auth/create-password`, {
      method: 'PATCH',
      body: JSON.stringify({ ...formData, token }),
      headers: { ...DEFAULT_API_HEADERS, ...ADDITIONAL_CLIENT_HEADERS },
    })
      .then((response) => response.json())
      .then(({ message }) => {
        if (message.toLowerCase() !== 'successfully create your password') {
          return handleResponse(false, message, true);
        }
        // Make sure this is a fresh session
        if (access_token) {
          logout(token);
        }
        handleResponse(true, message, true);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <>
      <Box py="8">
        <Text fontSize="3xl" fontWeight="bold">
          Buat Sandi Baru
        </Text>
        <Divider />
      </Box>
      <Box
        position="fixed"
        w={{ base: '350px', lg: '480px' }}
        hidden={!isVisible}
      >
        <Fade in={isVisible}>
          <Alert
            status={status ? 'success' : 'error'}
            description={statusDesc}
            onClose={onClose}
          />
        </Fade>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <PasswordField
            name="password"
            label="Kata Sandi Baru"
            register={register}
            errors={errors}
          />
          <PasswordField
            name="confirm_password"
            label="Konfirmasi Kata Sandi Baru"
            register={register}
            errors={errors}
          />
        </Stack>
        <Stack mt="4">
          <Button
            bg="blue"
            color="white"
            variant="solid"
            _hover={{
              bg: 'navy',
            }}
            type="submit"
            py="24px"
            isLoading={isSubmitting}
          >
            <span>Lanjutkan</span>
          </Button>
        </Stack>
        <Stack
          flex={1}
          flexDirection="column"
          justifyContent="center"
          spacing="-1"
          fontSize={{ base: '12px', md: '16px' }}
        >
          <Text textAlign="center" mt="4">
            Dengan menekan tombol Lanjutkan, Anda telah menyetujui
          </Text>
          <Text textAlign="center">
            <Link
              href="https://open.sukandaonelink.com/privacy-policy"
              fontWeight="semibold"
              color="red"
              isExternal
            >
              Kebijakan Privasi{' '}
            </Link>
            &
            <Link
              href="https://open.sukandaonelink.com/cookie-policy"
              fontWeight="semibold"
              color="red"
              isExternal
            >
              {' '}
              Ketentuan Cookies
            </Link>
          </Text>
        </Stack>
      </form>
    </>
  );
}
