import { apiInstance } from '@diamond/shared/utils';

export const reorderPromo = async (
  token: string,
  id: string,
  index: number
) => {
  return apiInstance(token).patch(
    `/backoffice/promo/${id}/reorder`,
    JSON.stringify({ index })
  );
};
