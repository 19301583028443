import { BackofficeUpdateRecipeListResponse } from '@diamond/shared/types';

import { CarouselAspectRatio } from '../carousel-aspect-ratio/carousel-aspect-ratio';

type RecipeImagesSlider<
  TData extends Omit<BackofficeUpdateRecipeListResponse, 'cg_3_business_types'>
> = {
  recipe: TData;
};

export function RecipeImagesSlider<
  TData extends Omit<BackofficeUpdateRecipeListResponse, 'cg_3_business_types'>
>({ recipe }: RecipeImagesSlider<TData>) {
  return (
    <CarouselAspectRatio
      data-testid="RecipeImagesSlider"
      ratio={1 / 1}
      imageData={recipe.medias.map((media) => ({
        image: media.url,
        url: '',
        id: '',
        name: '',
      }))}
      dotProps={{ bottom: 12 }}
      autoPlay={false}
    />
  );
}
