import {
  BackofficeRecipeSectionListQuery,
  BackofficeRecipeSectionListResponse,
  BackofficeRecipeSectionQuery,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { queryOptions, useQuery } from '@tanstack/react-query';

export const GetRecipeSectionList = async (
  token: string,
  query: BackofficeRecipeSectionListQuery
) => {
  const url = `/backoffice/recipe/section`;
  const { data } = await apiInstance(
    token
  ).get<BackofficeRecipeSectionListResponse>(url, { params: query });
  return data;
};

export const BACKOFFICE_RECIPE_QUERY_SECTION_KEY = 'recipeSection';

export function recipeSectionListQueryOption(
  token: string,
  query: BackofficeRecipeSectionListQuery
) {
  return queryOptions({
    queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY, query],
    queryFn: () => GetRecipeSectionList(token, query),
  });
}

export function useRecipeSectionList(
  query: BackofficeRecipeSectionListQuery = {
    page: 1,
    size: 20,
    is_homepage: false,
  }
) {
  const token = useAuthStore((s) => s.access_token);
  return useQuery(recipeSectionListQueryOption(token, query));
}
export function useRecipeHomepageSection(
  query: BackofficeRecipeSectionQuery = {
    page: 1,
    size: 20,
    is_homepage: true, // Always true for Recipe Homepage
  }
) {
  const token = useAuthStore((s) => s.access_token);
  return useQuery(recipeSectionListQueryOption(token, query));
}
