import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  Image as ChakraImage,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { BackofficeRecipeNotificationListResponse } from '@diamond/shared/types';
import { showToast } from '@diamond/shared/ui';
import { adminDateFormatter } from '@diamond/shared/utils';
import { useQueryClient } from '@tanstack/react-query';

import {
  BACKOFFICE_RECIPE_NOTIFICATION_QUERY_KEY,
  useUpdateRecipeNotificationMutation,
} from '../api';

export interface RecipeNotificationItemProps {
  data: BackofficeRecipeNotificationListResponse['data'][0];
  isNotifPending: boolean;
}

export function RecipeNotificationItem({
  data,
  isNotifPending,
}: RecipeNotificationItemProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const createBlastNotification = useUpdateRecipeNotificationMutation({
    onSuccess: () => {
      showToast(toast, 'success', 'Berhasil Mengubah Status');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_NOTIFICATION_QUERY_KEY],
      });
    },
  });
  const handleBlasNotif = async () => {
    await createBlastNotification.mutateAsync({ id: data.id });
  };
  return (
    <Flex
      data-testid="RecipeNotificationItem"
      gap={2}
      p={4}
      alignItems="center"
      shadow="sm"
      borderWidth="1px"
      backgroundColor="gray.50"
      mb={2}
    >
      {isNotifPending ? (
        <Center mt="8">
          <Spinner />
        </Center>
      ) : (
        <Flex
          alignItems="center"
          fontSize="sm"
          gap="2"
          w="full"
          fontWeight="bold"
        >
          <Stack w="20%">
            <Box rounded="4xl" h="100px" mr="3">
              <AspectRatio ratio={1312 / 512} w="full" h="full">
                <ChakraImage
                  rounded="md"
                  w="100%"
                  objectFit="cover"
                  mt={1}
                  alt="logo"
                  src={
                    data.thumbnail
                      ? data.thumbnail
                      : '/assets/images/admin-banner-home.png'
                  }
                />
              </AspectRatio>
            </Box>
          </Stack>
          <Stack w="19%" color="#0000A3">
            <Text>{data.title}</Text>
          </Stack>

          <Stack w="19%" color="#0000A3">
            <Text fontSize="sm">{adminDateFormatter(data.updated_at)}</Text>
          </Stack>
          <Stack w="20%" fontWeight="normal">
            <Text
              fontSize="sm"
              p="2"
              textColor={data.status === 'success' ? '#000000' : '#FF0000'}
              bgColor={data.status === 'success' ? '#FFF2E2' : '#FFDADA'}
              w="50%"
              textAlign="center"
              borderRadius="md"
              textTransform="capitalize"
            >
              {data.status}
            </Text>
          </Stack>
          <Stack w="20%">
            <Button w="80%" onClick={handleBlasNotif}>
              Blast Notification
            </Button>
          </Stack>
        </Flex>
      )}
    </Flex>
  );
}
