import { Flex, Image, Text } from '@chakra-ui/react';
import {
  BackofficeUpdateRecipeListResponse,
  RecipeHomepageTags,
} from '@diamond/shared/types';

type RecipeTagProps = {
  mode?: 'small_icon' | 'large_icon';
  tag: BackofficeUpdateRecipeListResponse['tags'][number] | RecipeHomepageTags;
};

export function RecipeTag({ tag, mode = 'small_icon' }: RecipeTagProps) {
  return (
    <Flex
      data-recipe-tag
      alignItems="center"
      gap={{ base: '0.5', md: '1' }}
      backgroundColor={tag.bg_color}
      color={tag.text_color}
      rounded="full"
      py={{ base: '0.5', md: '1' }}
      px={{ base: '1.5', md: '3' }}
    >
      <Image
        w="1rem"
        h="1rem"
        src={mode === 'small_icon' ? tag.small_icon_url : tag.big_icon_url}
      />
      <Text
        maxW="12ch"
        textOverflow="ellipsis"
        fontSize="xs"
        whiteSpace="nowrap"
        overflow="hidden"
        fontWeight="medium"
      >
        {tag.name}
      </Text>
    </Flex>
  );
}
