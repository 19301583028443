import {
  Box,
  Center,
  useBreakpointValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { PatchDataWrapper } from '@diamond/shared/data-access';
import {
  Breadcrumbs,
  Button,
  Card,
  PasswordField,
  showToast,
  Text,
} from '@diamond/shared/ui';
import { ChangePasswordValidationSchema } from '@diamond/shared/validation';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

export function ChangePasswordPage() {
  const isDesktop = useBreakpointValue({ base: false, md: true, lg: true });
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(ChangePasswordValidationSchema) });
  const session = useAuthStore();

  const { patchData } = PatchDataWrapper(
    'auth/change-password',
    session.access_token
  );

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Ubah Kata Sandi',
      link: '/change-password',
    },
  ];

  const editPassword = useMutation({
    mutationFn: async (payload: unknown) => {
      const idToast = 'change-pass';
      patchData(payload)
        .then(() => {
          if (!toast.isActive(idToast)) {
            showToast(toast, 'success', 'Kata sandi berhasil diubah!', idToast);
          }
          reset();
        })
        .catch((error) => {
          if (!toast.isActive(idToast)) {
            showToast(toast, 'error', error.message, idToast);
          }
        });
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    editPassword.mutate(data);
  };

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Card p="4" border="1px" borderColor="gray.33" shadow="base" rounded="lg">
        <Text fontWeight="semibold" fontSize="24px">
          Ubah Kata Sandi
        </Text>
        <Center py="6.5rem">
          <Card minW={isDesktop ? '350px' : 'unset'}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={10}>
                <PasswordField
                  name="old_password"
                  label="Kata Sandi Sekarang"
                  placeholder="Masukkan Kata Sandi"
                  register={register}
                  errors={errors}
                  width="40rem"
                />
                <PasswordField
                  name="new_password"
                  label="Kata Sandi Baru"
                  placeholder="Masukkan Kata Sandi"
                  register={register}
                  errors={errors}
                  width="40rem"
                />
                <PasswordField
                  name="confirm_new_password"
                  label="Konfirmasi Kata Sandi Baru"
                  placeholder="Masukkan Kata Sandi"
                  register={register}
                  errors={errors}
                  width="40rem"
                />
                <Center>
                  <Button
                    bg="blue"
                    color="white"
                    variant="solid"
                    w={{ base: '100%', md: 'auto' }}
                    _hover={{
                      bg: 'navy',
                    }}
                    type="submit"
                  >
                    Simpan
                  </Button>
                </Center>
              </VStack>
            </form>
          </Card>
        </Center>
      </Card>
    </AdminLayout>
  );
}
