import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { BackofficeRecipeSectionListResponse } from '@diamond/shared/types';
import { showToast } from '@diamond/shared/ui';
import { EditOutlined } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';

import {
  BACKOFFICE_RECIPE_QUERY_SECTION_KEY,
  useRecipeSectionDetail,
  useUpdateRecipeSectionList,
} from '../api';
import {
  RecipeSectionRecipeList,
  RecipeSectionRecipeListForm,
} from './forms/recipe-section-recipe-list-form';

type EditRecipeSectionRecipeListActionProps = {
  section: BackofficeRecipeSectionListResponse['data'][number];
};

export function EditRecipeSectionRecipeListAction({
  section,
}: EditRecipeSectionRecipeListActionProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: sectionDetail } = useRecipeSectionDetail(section.id, section);

  const updateRecipeSectionMutation = useUpdateRecipeSectionList({
    onSuccess: () => {
      showToast(toast, 'success', 'Berhasil Menyimpan Section');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY],
      });
    },
  });

  const onSubmit = async (data: RecipeSectionRecipeList) => {
    await updateRecipeSectionMutation.mutate({
      params: { id: data.id },
      body: {
        title: data.title,
      },
    });
  };

  return (
    <>
      <IconButton
        aria-label="Edit section"
        onClick={onOpen}
        icon={<EditOutlined />}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="lg">
          <ModalHeader>
            <Text>Edit Section</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RecipeSectionRecipeListForm
              type="edit"
              section={sectionDetail}
              onSubmit={(data) => {
                onSubmit(data);
                onClose();
              }}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
