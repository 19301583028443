import '@udecode/plate/react';

import { Box, Flex, Image, Spinner } from '@chakra-ui/react';
import { ImageOutlined } from '@mui/icons-material';
import { useEditorPlugin, withHOC } from '@udecode/plate-core/react';
import { TPlaceholderElement } from '@udecode/plate-media';
import {
  FilePlugin,
  ImagePlugin,
  PlaceholderPlugin,
  PlaceholderProvider,
  // updateUploadHistory,
} from '@udecode/plate-media/react';
import { withRef } from '@udecode/react-utils';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useFilePicker } from 'use-file-picker';

import { useUploadFile } from '../hooks/use-upload-file';
import { PlateElement } from './plate-element';

const CONTENT: Record<
  string,
  {
    accept: string[];
    content: ReactNode;
    icon: ReactNode;
  }
> = {
  [ImagePlugin.key]: {
    accept: ['image/*'],
    content: 'Add an image',
    icon: <ImageOutlined />,
  },
};

export const MediaPlaceholderElement = withHOC(
  PlaceholderProvider,
  withRef<typeof PlateElement>(({ children, ...props }, ref) => {
    const editor = props.editor;
    const element = props.element as TPlaceholderElement;
    const elementId = element['id'] as string;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { api } = useEditorPlugin(PlaceholderPlugin);

    const { isUploading, progress, uploadFile, uploadedFile, uploadingFile } =
      useUploadFile();

    const loading = isUploading && uploadingFile;

    const currentContent = CONTENT[element.mediaType];

    const isImage = element.mediaType === ImagePlugin.key;

    const imageRef = useRef<HTMLImageElement>(null);

    const replaceCurrentPlaceholder = useCallback(
      (file: File) => {
        void uploadFile(file);
        api.placeholder.addUploadingFile(elementId, file);
      },
      [api.placeholder, elementId, uploadFile]
    );

    const { openFilePicker } = useFilePicker({
      accept: currentContent.accept,
      multiple: true,
      onFilesSelected: ({ plainFiles: updatedFiles }) => {
        const firstFile = updatedFiles[0];
        const restFiles = updatedFiles.slice(1);

        replaceCurrentPlaceholder(firstFile);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        restFiles.length > 0 && (editor as any).tf.insert.media(restFiles);
      },
    });

    useEffect(() => {
      if (!uploadedFile) return;

      const path = editor.api.findPath(element);

      editor.tf.withoutSaving(() => {
        editor.tf.removeNodes({ at: path });

        const node = {
          children: [{ text: '' }],
          initialHeight: imageRef.current?.height,
          initialWidth: imageRef.current?.width,
          isUpload: true,
          name: element.mediaType === FilePlugin.key ? uploadedFile.name : '',
          placeholderId: elementId,
          type: element.mediaType,
          url: uploadedFile.url,
        };

        editor.tf.insertNodes(node, { at: path });

        // updateUploadHistory(editor, node);
      });

      api.placeholder.removeUploadingFile(elementId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFile, elementId]);

    // React dev mode will call useEffect twice
    const isReplaced = useRef(false);

    /** Paste and drop */
    useEffect(() => {
      if (isReplaced.current) return;

      isReplaced.current = true;
      const currentFiles = api.placeholder.getUploadingFile(elementId);

      if (!currentFiles) return;

      replaceCurrentPlaceholder(currentFiles);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReplaced]);

    return (
      <PlateElement ref={ref} {...props}>
        {(!loading || !isImage) && (
          <Flex cursor="pointer" onClick={() => loading && openFilePicker()}>
            <Flex position="relative" marginRight="3">
              {currentContent.icon}
            </Flex>
            <Box>
              <Box>{loading ? uploadingFile.name : currentContent.content}</Box>
            </Box>
          </Flex>
        )}
        {isImage && loading && (
          <ImageProgress
            file={uploadingFile}
            imageRef={imageRef}
            progress={progress}
          />
        )}
        {children}
      </PlateElement>
    );
  })
);

export function ImageProgress({
  file,
  imageRef,
  progress = 0,
}: {
  file: File;
  imageRef?: React.RefObject<HTMLImageElement>;
  progress?: number;
}) {
  const [objectUrl, setObjectUrl] = useState<string | null>(null);

  useEffect(() => {
    const url = URL.createObjectURL(file);
    setObjectUrl(url);
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file]);

  if (!objectUrl) return null;

  return (
    <Box contentEditable={false} position="relative">
      <Image
        h="auto"
        w="full"
        rounded="sm"
        objectFit="cover"
        ref={imageRef}
        alt={file.name}
        src={objectUrl}
      />
      {progress < 100 && (
        <Flex
          position="absolute"
          bottom="3"
          right="3"
          alignItems="center"
          rounded="lg"
          bg="blackAlpha.500"
          px="1"
          py="0.5"
          gap="2"
        >
          <Spinner />
        </Flex>
      )}
    </Box>
  );
}
