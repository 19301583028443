import { Box, Button, Flex, useRadio, UseRadioProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

export type RadioButtonCardOption = {
  value: string;
  label: string;
};

type RecipeListRadioProps = Omit<RadioButtonCardOption, 'value'> &
  UseRadioProps;

export const RadioButtonCard = forwardRef<
  HTMLInputElement,
  RecipeListRadioProps
>(({ label, ...props }, ref) => {
  const {
    getRootProps,
    state,
    getInputProps,
    getCheckboxProps: getRadioProps,
    htmlProps,
    getLabelProps,
  } = useRadio(props);

  return (
    <Box as="label" {...getRootProps()} {...htmlProps}>
      <input {...getInputProps({ ref })} />
      <Button
        variant="unstyled"
        as="div"
        cursor={state.isDisabled ? 'not-allowed' : 'pointer'}
        opacity={state.isDisabled ? '0.4' : undefined}
        rounded="lg"
        px="4"
        py="3"
        border="1px solid"
        bg="white"
        borderColor={state.isChecked ? 'azure' : 'gray.75'}
        color={state.isChecked ? 'azure' : 'gray.75'}
        display="flex"
        alignItems="center"
        gap="2"
        {...getRadioProps()}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          rounded="full"
          border="1px solid"
          color={state.isChecked ? 'azure' : 'gray.75'}
          w="15px"
          h="15px"
        >
          {state.isChecked && <Box rounded="full" bg="azure" w="8px" h="8px" />}
        </Flex>
        <Box fontSize="md" textAlign="center" {...getLabelProps()}>
          {label}
        </Box>
      </Button>
    </Box>
  );
});

RadioButtonCard.displayName = 'RadioButtonCard';
