import { HeadingOne, HeadingTwo } from '@diamond/shared/ui';
import { withRef } from '@udecode/react-utils';

import { PlateElement } from './plate-element';

export const HeadingOneElement = withRef<typeof PlateElement>(
  ({ children, ...props }, ref) => {
    return (
      <PlateElement asChild ref={ref} {...props}>
        <HeadingOne mb="4">{children}</HeadingOne>
      </PlateElement>
    );
  }
);

export const HeadingTwoElement = withRef<typeof PlateElement>(
  ({ children, ...props }, ref) => {
    return (
      <PlateElement asChild ref={ref} {...props}>
        <HeadingTwo mb="4">{children}</HeadingTwo>
      </PlateElement>
    );
  }
);
