import {
  AspectRatio,
  Box,
  ButtonGroup,
  Center,
  Flex,
  IconButton,
  Image as ChakraImage,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { BackofficeRecipeTagResponse } from '@diamond/shared/types';
import { Heading, Modal } from '@diamond/shared/ui';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { EditRecipeCategory } from './edit-recipe-category';

export interface CategoryItemsProps {
  item: BackofficeRecipeTagResponse['data'][0];
  onDelete: () => void;
}

export function RecipeCategoryItem(props: CategoryItemsProps) {
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  return (
    <Flex
      gap={2}
      p={4}
      alignItems="center"
      shadow="sm"
      borderWidth="1px"
      backgroundColor="gray.50"
      mb={2}
    >
      <Spacer />
      <Flex alignItems="center" fontSize="sm" gap="2" w="full">
        <Stack w={{ lg: '21%', xl: '28%' }} ml={8}>
          <Box rounded="4xl" h="100px" mr="3">
            <AspectRatio ratio={1 / 1} w="100px" h="full">
              <ChakraImage
                rounded="md"
                w="100%"
                objectFit="cover"
                mt={1}
                alt="logo"
                src={
                  props.item.big_icon_url === ''
                    ? '/assets/images/admin-banner-home.png'
                    : props.item.big_icon_url
                }
              />
            </AspectRatio>
          </Box>
        </Stack>
        <Stack
          w={{ lg: '21%', xl: '20%' }}
          fontWeight="bold"
          fontSize="lg"
          color="#0000A3"
          px="3"
        >
          <Text fontSize="sm">{props.item.name}</Text>
        </Stack>
        <Stack w={{ lg: '23%', xl: '26%' }}>
          <Box rounded="4xl" h="100px" mr="3">
            <AspectRatio ratio={1 / 1} w="100px" h="full">
              <ChakraImage
                rounded="md"
                w="full"
                objectFit="cover"
                mt={1}
                alt="logo"
                src={
                  props.item.big_icon_url === ''
                    ? '/assets/images/admin-banner-home.png'
                    : props.item.small_icon_url
                }
              />
            </AspectRatio>
          </Box>
        </Stack>
        <Stack w={{ lg: '21%', xl: '26%' }} px={5}>
          <Box
            rounded="4xl"
            w="100px"
            h="100px"
            mr="3"
            bgColor={props.item.bg_color}
          ></Box>
        </Stack>
        <Stack w={{ lg: '17%', xl: '12%' }}>
          <ButtonGroup>
            <IconButton
              aria-label="Edit"
              icon={<CreateOutlinedIcon />}
              fontSize="xs"
              variant="solid"
              borderColor="gray.33"
              onClick={onEditOpen}
            />
            <IconButton
              aria-label="Delete"
              icon={<DeleteOutlinedIcon />}
              fontSize="xs"
              variant="solid"
              borderColor="gray.33"
              background="red"
              color="white"
              onClick={onDeleteOpen}
            />
          </ButtonGroup>
        </Stack>
      </Flex>
      <Spacer />
      <EditRecipeCategory
        key={props.item.id}
        isOpen={isEditOpen}
        onClose={onEditClose}
        recipeData={props.item}
      />
      <Modal
        name="delete-kategori"
        labelSubmit="Hapus"
        labelCancel="Kembali"
        buttonColor="red"
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSubmit={() => {
          props.onDelete();
          onDeleteClose();
        }}
        isCentered
      >
        <Center py={4}>
          <Heading as="h3">Hapus Tag ?</Heading>
        </Center>
      </Modal>
    </Flex>
  );
}
