import { apiInstance } from '@diamond/shared/utils';

export async function toggleShowPromoHomepage(
  token: string,
  id: string,
  is_display_on_homepage: boolean
) {
  return apiInstance(token).patch(`/backoffice/promo/${id}/show-on-homepage`, {
    is_display_on_homepage: is_display_on_homepage,
  });
}
