export function adminCurrencyFormatter(value?: number) {
  if (value) {
    return `Rp${value.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
  } else {
    return 'Rp0';
  }
}

export function buyerCurrencyFormatter(value?: number) {
  if (value) {
    return `Rp${value.toLocaleString('id-ID')}`;
  } else {
    return 'Rp0';
  }
}

export function numberSeparator(value: number | string) {
  if (value) {
    return `${value.toLocaleString('id-ID')}`;
  } else {
    return `-`;
  }
}

export function numberWithSeparator(val: number | string) {
  const parts = val.toString().split('.');
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  const decimalResult = decimalPart ? ',' + decimalPart : '';
  return numberPart.replace(thousands, '.') + decimalResult;
}

export function convertToFloat(value: string) {
  const sanitizedValue = value.replace(/\./g, '').replace(',', '.');
  const floatValue = parseFloat(sanitizedValue);
  return floatValue;
}
