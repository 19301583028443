import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BACKOFFICE_RECIPE_QUERY_KEY } from './get-recipe-list';

export const togglePublish = async (access_token: string, recipeId: string) => {
  return apiInstance(access_token).patch(
    `/backoffice/recipe/${recipeId}/publish`
  );
};

export function useTogglePublishRecipeMutation(
  recipeId: string,
  {
    onSuccess,
  }: {
    onSuccess?: () => void;
  } = {}
) {
  const token = useAuthStore((s) => s.access_token);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => togglePublish(token, recipeId),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'list', recipeId],
      });
      onSuccess?.();
    },
  });
}
