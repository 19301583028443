import { Paragraph } from '@diamond/shared/ui';
import { withRef } from '@udecode/react-utils';

import { PlateElement } from './plate-element';

export const ParagraphElement = withRef<typeof PlateElement>(
  ({ children, ...props }, ref) => {
    return (
      <PlateElement asChild ref={ref} {...props}>
        <Paragraph>{children}</Paragraph>
      </PlateElement>
    );
  }
);
