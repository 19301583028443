import { BackofficePromoBannerUploadResponse } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';

export async function uploadPromoBanner<T>(access_token: string, payload: T) {
  const { data } = await apiInstance(access_token, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${access_token}`,
    },
  }).post<BackofficePromoBannerUploadResponse>(
    '/backoffice/promo/upload',
    payload
  );
  return data;
}
