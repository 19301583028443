import { tagAnatomy } from '@chakra-ui/anatomy';
import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
} from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tagAnatomy.keys
);

export const Tag: ComponentStyleConfig = defineMultiStyleConfig({
  variants: {
    recipe: {
      container: {
        bg: '#0000A310',
      },
      closeButton: {
        color: 'red',
      },
      label: {
        color: 'black',
      },
    },
  },
});
