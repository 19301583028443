import { QueryWrapper } from '@diamond/shared/data-access';
import { AccountContactTransaction } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/features/auth';

type QueryStringType = {
  size: number;
  page: number;
  search_query?: string;
};
export const useAccountTransactionList = (
  userId: string,
  shipToId: string,
  queryString?: QueryStringType
) => {
  const session = useAuthStore();

  const query = QueryWrapper('/backoffice/account', session.access_token);

  const {
    data: accountTranscation,
    refetch: refetchTransaction,
    total: totalTransaction,
    pageCount: countTransaction,
  } = query.useQueryGetByIdPager<AccountContactTransaction>(
    `${userId}/ship-to/${shipToId}/transaction-list`,
    'AccountContactTransaction',
    queryString,
    false
  );

  return {
    accountTranscation,
    refetchTransaction,
    totalTransaction,
    countTransaction,
  };
};
