import { ComponentStyleConfig } from '@chakra-ui/react';

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      borderRadius: 'sm',
      borderColor: 'gray.75',
      borderWidth: '1px',
      _checked: {
        bg: 'azure',
        borderColor: 'azure',
      },
      _indeterminate: {
        bg: 'azure',
        borderColor: 'azure',
      },
    },
  },
  variants: {
    recipe: {
      control: {
        borderRadius: 'md',
        boxSize: '20px',
        borderColor: 'gray.75',
        borderWidth: '1px',
        _checked: {
          bg: 'azure',
          borderColor: 'azure',
        },
        _indeterminate: {
          bg: 'azure',
          borderColor: 'azure',
        },
      },
      label: {
        color: 'black',
      },
    },
  },
};
