import {
  AspectRatio,
  Badge,
  Button,
  Flex,
  FlexProps,
  Icon,
  Image,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { BackofficeRecipeListResponse } from '@diamond/shared/types';
import { LaunchOutlined, RemoveRedEyeOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { Link } from 'react-router-dom';

import { constructSlug } from '../utils';

type RecipeListItemProps = {
  recipe: BackofficeRecipeListResponse['data'][number];
};

export function RecipeListItem({ recipe }: RecipeListItemProps) {
  return (
    <LinkBox alignItems="center" maxW="container.xl" w="full">
      <Flex
        data-testid="RecipeListItem"
        w="full"
        flex="1"
        alignItems="center"
        gap="4"
        justifyContent="space-between"
      >
        <Stack flex="none" w="100px" h="100px">
          <AspectRatio ratio={1 / 1} h="full" position="relative">
            <Image
              position="absolute"
              rounded="md"
              h="full"
              objectFit="cover"
              alt="Thumbnail"
              src={recipe.thumbnail || '/assets/images/admin-banner-home.png'}
            />
          </AspectRatio>
        </Stack>
        <Stack flex="1 0 5%">
          <Badge
            w="fit-content"
            px="2"
            py="1"
            rounded="4px"
            fontWeight="normal"
            color={recipe.is_published ? 'white' : 'black'}
            bg={recipe.is_published ? 'green' : 'gray.33'}
          >
            {recipe.is_published ? 'Published' : 'Draft'}
          </Badge>
          <LinkOverlay as={Link} to={`/recipe/list/${recipe.id}`}>
            <Text
              as="span"
              fontSize={{ md: 'xs', xl: 'sm' }}
              fontWeight="bold"
              textDecoration="underline"
            >
              {recipe.title}
            </Text>
          </LinkOverlay>
        </Stack>
        {/* <Stack flex="1 0 10%">
          <Text fontSize={{ md: 'xs', xl: 'sm' }}>SECTION PLACEHOLDER</Text>
        </Stack> */}
        <Stack flex="1 0 7%">
          <Text fontSize={{ md: 'xs', xl: 'sm' }}>
            {recipe.tags.join(', ')}
          </Text>
        </Stack>
        <Stack flex="1 0 200px">
          <Button
            alignSelf="flex-start"
            pr="8"
            color="black"
            size="sm"
            fontWeight="normal"
            textAlign="left"
            variant="link"
            wordBreak="break-word"
            fontSize={{ md: 'xs', xl: 'sm' }}
            leftIcon={
              <Icon fontSize={{ md: 'xs', xl: 'sm' }} as={LaunchOutlined} />
            }
            css={{
              textWrap: 'wrap',
            }}
            as="a"
            href={constructSlug(recipe.slug)}
            target="_blank"
            rel="noreferer noopener"
          >
            {constructSlug(recipe.slug)}
          </Button>
        </Stack>
        <Stack flex="1 0 5%" direction="row" alignItems="center">
          <Icon as={RemoveRedEyeOutlined} fontSize={{ md: 'md' }} />
          <Text fontSize={{ md: 'xs', xl: 'sm' }}>{recipe.views} views</Text>
        </Stack>
        <Stack flex="1 0 5%" direction="row" alignItems="center">
          <Text fontSize={{ md: 'xs', xl: 'sm' }}>
            {format(recipe.created_at, 'dd/MM/yyyy HH:mm', { locale: id })}
          </Text>
        </Stack>
      </Flex>
    </LinkBox>
  );
}

export function RecipeListHeader(props: FlexProps) {
  return (
    <Flex
      alignItems="center"
      maxW="container.xl"
      w="full"
      px="5"
      py="6"
      bg="#0000A310"
      borderTopRadius="xl"
      {...props}
    >
      <Flex
        w="full"
        flex="1"
        alignItems="center"
        gap="4"
        justifyContent="space-between"
      >
        <Stack flex="0 0 80px"></Stack>
        <Stack flex="0 0 100px">
          <Text fontWeight="semibold" fontSize="sm">
            Gambar
          </Text>
        </Stack>
        <Stack flex="1 0 5%">
          <Text fontWeight="semibold" fontSize="sm">
            Judul Resep
          </Text>
        </Stack>
        {/* <Stack flex="1 0 10%">
          <Text fontWeight="semibold" fontSize="sm">
            Section // MVP Belum ada `Section`
          </Text>
        </Stack> */}
        <Stack flex="1 0 7%">
          <Text fontWeight="semibold" fontSize="sm">
            Kategori
          </Text>
        </Stack>
        <Stack flex="1 0 200px">
          <Text fontWeight="semibold" fontSize="sm">
            URL
          </Text>
        </Stack>
        <Stack flex="1 0 5%">
          <Text fontWeight="semibold" fontSize="sm">
            View
          </Text>
        </Stack>
        <Stack flex="1 0 5%">
          <Text fontWeight="semibold" fontSize="sm">
            Tanggal Dibuat
          </Text>
        </Stack>
      </Flex>
    </Flex>
  );
}
