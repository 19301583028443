import {
  BackofficeCreateRecipeSectionBody,
  BackofficeCreateRecipeSectionResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation } from '@tanstack/react-query';

export const createRecipeSection = async (
  token: string,
  payload: BackofficeCreateRecipeSectionBody
) => {
  const url = `/backoffice/recipe/section`;
  return apiInstance(token).post<BackofficeCreateRecipeSectionResponse>(
    url,
    payload
  );
};
type UseCreateRecipeSectionMutationArgs = {
  onSuccess: () => void;
  onSettled: () => void;
};
export function useCreateRecipeSectionMutation({
  onSuccess,
  onSettled,
}: UseCreateRecipeSectionMutationArgs) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: (payload: BackofficeCreateRecipeSectionBody) =>
      createRecipeSection(token, payload),
    onSuccess,
    onSettled,
  });
}
