import { CustomerTypes } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const getCustomerTypes = async (token: string) => {
  const { data } = await apiInstance(token).get<CustomerTypes>(
    `/backoffice/shipto/customer-type`
  );
  return data;
};

export const useCustomerTypes = () => {
  const token = useAuthStore((s) => s.access_token);

  const query = useQuery({
    queryKey: ['Customer Types'],
    queryFn: () => getCustomerTypes(token),
    select(data) {
      return data.data.filter(
        (it) => it.cg_1_business_segmentation_desc !== ''
      );
    },
    staleTime: 60000,
    retry: false,
  });

  const options = useMemo(() => {
    return (query.data || []).map((it) => ({
      label: it.cg_1_business_segmentation_desc,
      value: it.cg_1_business_segmentation,
    }));
  }, [query.data]);

  const maps = useMemo(() => {
    return options.reduce((p: Record<string, string>, c) => {
      p[c.value] = c.label;
      return p;
    }, {});
  }, [options]);

  return {
    ...query,
    options,
    maps,
  };
};
