import { Box, BoxProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

export const Tips = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BoxProps>
>(({ children, ...props }, ref) => {
  return (
    <Box
      as="blockquote"
      ref={ref}
      px="4"
      py="0.5"
      my="4"
      rounded="md"
      data-tips
      display="flex"
      bg="#34C75920"
      gap="4"
      fontSize="sm"
      {...props}
    >
      <Box fontWeight="bold" contentEditable="false" flex="0 0 auto">
        Tips:
      </Box>
      <Box>{children}</Box>
    </Box>
  );
});

Tips.displayName = 'Tips';
