import {
  BackofficeRecipeSectionUpdateBody,
  BackofficeRecipeSectionUpdateParams,
  BackofficeRecipeSectionUpdateResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation } from '@tanstack/react-query';

export const updateRecipeSectionList = async (
  token: string,
  params: BackofficeRecipeSectionUpdateParams,
  body: BackofficeRecipeSectionUpdateBody
) => {
  const url = `/backoffice/recipe/section/${params.id}`;
  const { data } = await apiInstance(
    token
  ).put<BackofficeRecipeSectionUpdateResponse>(url, body);
  return data;
};

type useUpdateRecipeSectionMutationArgs = {
  onSuccess: () => void;
  onSettled: () => void;
};

export function useUpdateRecipeSectionList({
  onSuccess,
  onSettled,
}: useUpdateRecipeSectionMutationArgs) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: BackofficeRecipeSectionUpdateParams;
      body: BackofficeRecipeSectionUpdateBody;
    }) => updateRecipeSectionList(token, params, body),

    onSuccess,
    onSettled,
  });
}
