import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  IconButton,
  Image as ChakraImage,
  Link,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { Banner } from '@diamond/shared/types';
import { Heading, Modal, Text } from '@diamond/shared/ui';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { EditBanner } from './edit-banner';

interface BannerItemProps {
  item: Banner[0];
  onDelete: () => void;
}

export function BannerItem({ item, onDelete }: BannerItemProps) {
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  return (
    <Flex alignItems="center" gap={4} px={2} maxW="container.xl">
      <Box rounded="4xl" w={{ base: '77px', md: '307px' }}>
        <ChakraImage
          src={item.image}
          h={{ base: '32px', md: '106px' }}
          w={{ base: '77px', md: '307px' }}
          rounded="md"
          alt="logo"
        />
      </Box>
      <Spacer />
      <Flex alignItems="center" gap="2" w="full" maxW="full">
        <Box flexBasis={240}>
          <Text>{item.title}</Text>
        </Box>
        <Box flex="1">
          <Link href={item?.url as string} isExternal wordBreak="break-all">
            {item?.url}
          </Link>
        </Box>
      </Flex>
      <Spacer />
      <ButtonGroup>
        <IconButton
          aria-label="Edit"
          icon={<CreateOutlinedIcon />}
          fontSize="xs"
          variant="solid"
          borderColor="gray.33"
          onClick={onEditOpen}
        />
        <IconButton
          aria-label="Delete"
          icon={<DeleteOutlinedIcon />}
          fontSize="xs"
          variant="solid"
          borderColor="gray.33"
          background="red"
          color="white"
          onClick={onDeleteOpen}
        />
      </ButtonGroup>

      <EditBanner isOpen={isEditOpen} onClose={onEditClose} bannerData={item} />

      <Modal
        name="delete-banner"
        labelSubmit="Hapus"
        labelCancel="Kembali"
        buttonColor="red"
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSubmit={onDelete}
        isCentered
      >
        <Center py={4}>
          <Heading as="h3">Hapus Banner?</Heading>
        </Center>
      </Modal>
    </Flex>
  );
}
