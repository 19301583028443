import { Heading, HeadingProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

export const HeadingOne = forwardRef<
  React.ElementRef<typeof Heading>,
  React.PropsWithChildren<HeadingProps>
>(({ children, ...props }, ref) => {
  return (
    <Heading as="h1" size="lg" {...props} ref={ref}>
      {children}
    </Heading>
  );
});

HeadingOne.displayName = 'HeadingOne';

export const HeadingTwo = forwardRef<
  React.ElementRef<typeof Heading>,
  React.PropsWithChildren<HeadingProps>
>(({ children, ...props }, ref) => {
  return (
    <Heading as="h2" size="md" {...props} ref={ref}>
      {children}
    </Heading>
  );
});

HeadingTwo.displayName = 'HeadingTwo';
