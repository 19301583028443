import { NodeApi } from '@udecode/plate';
import { type PlateEditor, useEditorRef } from '@udecode/plate-core/react';
import { useEffect } from 'react';

import { TPlateCypressAdapter } from './types';

const EDITABLE_TO_EDITOR = new WeakMap<HTMLElement, PlateEditor>();

const plateCypressAdapter: TPlateCypressAdapter = {
  EDITABLE_TO_EDITOR,
  getNode: NodeApi.get,
};

declare global {
  interface Window {
    plateCypressAdapter: TPlateCypressAdapter;
  }
}

export const useCypressAdapter = () => {
  const editor = useEditorRef();

  useEffect(() => {
    window.plateCypressAdapter = plateCypressAdapter;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const editable = editor.api.toDOMNode(editor)!;
    EDITABLE_TO_EDITOR.set(editable, editor);

    return () => {
      EDITABLE_TO_EDITOR.delete(editable);
    };
  }, [editor]);

  return null;
};
