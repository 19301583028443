import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { showToast } from '@diamond/shared/ui';
import { RecipeSectionRecipeListCreateSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddOutlined } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import {
  BACKOFFICE_RECIPE_QUERY_SECTION_KEY,
  useCreateRecipeSectionMutation,
} from '../api';
import { RecipeSectionRecipeListForm } from './forms/recipe-section-recipe-list-form';

export function CreateRecipeSectionRecipeListAction() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const methods = useForm({
    resolver: yupResolver(RecipeSectionRecipeListCreateSchema),
    shouldUnregister: false,
  });

  const createRecipeSection = useCreateRecipeSectionMutation({
    onSuccess: () => {
      showToast(toast, 'success', 'Berhasil Menambah section');
      onClose();
      methods.reset();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY],
      });
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    await createRecipeSection.mutate({
      title: values.title,
      recipes: values.recipes.map((data: { id: string }) => data.id),
    });
  };
  return (
    <>
      <Button onClick={onOpen} leftIcon={<Icon as={AddOutlined} />}>
        Tambah Section
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="lg">
          <ModalHeader>
            <Text>Create Section</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RecipeSectionRecipeListForm
              type="create"
              onSubmit={(data) => {
                onSubmit(data);
                onClose();
              }}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
