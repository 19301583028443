import {
  BackofficeRecipeSectionUpdateBody,
  BackofficeRecipeSectionUpdateParams,
  BackofficeRecipeSectionUpdateResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BACKOFFICE_RECIPE_QUERY_SECTION_KEY } from './get-recipe-section';

export const updateRecipeSection = async (
  token: string,
  params: BackofficeRecipeSectionUpdateParams,
  body: BackofficeRecipeSectionUpdateBody
) => {
  const url = `/backoffice/recipe/section/${params.id}`;
  const { data } = await apiInstance(
    token
  ).put<BackofficeRecipeSectionUpdateResponse>(url, body);
  return data;
};

export const BACKOFFICE_RECIPE_SECTION_UPDATE_QUERY_KEY =
  'backoffice-recipe-section-update';

export function useRecipeSectionUpdate() {
  const queryClient = useQueryClient();
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: BackofficeRecipeSectionUpdateParams;
      body: BackofficeRecipeSectionUpdateBody;
    }) => updateRecipeSection(token, params, body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_SECTION_UPDATE_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY],
      });
    },
  });
}
