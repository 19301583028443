import {
  HStack,
  Stack,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { PatchDataWrapper } from '@diamond/shared/data-access';
import { ProductKeyword } from '@diamond/shared/types';
import {
  Button,
  Divider,
  Modal,
  showToast,
  TextField,
} from '@diamond/shared/ui';
import { isValidUrl } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { CustomerTypeInput } from '@diamond/sol-admin/features/shipto';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import validator from 'validator';

interface EditKeywordProps {
  data: ProductKeyword[0];
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function EditKeyword({
  data,
  isOpen = false,
  onClose,
  onSuccess,
}: EditKeywordProps) {
  const token = useAuthStore((s) => s.access_token);
  const toast = useToast();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useFormContext();

  const { patchData } = PatchDataWrapper(`/backoffice/product/keyword`, token);

  const onSubmit: SubmitHandler<any> = (values) => {
    const urlForSubmit = !validator.contains(values.url, 'http')
      ? `https://${values.url}`
      : values.url;

    if (!isValidUrl(urlForSubmit)) {
      return showToast(toast, 'error', 'URL tidak sesuai');
    }

    delete values.url;
    const newData = {
      ...values,
      url: urlForSubmit.replace(/%20/g, '+'),
      distribution_channel:
        values.distribution_channel?.map((val: any) => val.value) ?? [],
      business_segmentation:
        values.business_segmentation?.map((val: any) => val.value) ?? [],
    };
    patchData(newData, values.id)
      .then(() => {
        showToast(toast, 'success', 'Keyword berhasil disimpan');
        onClose();
        onSuccess();
      })
      .catch((err) => {
        showToast(toast, 'error', err.message);
      });
  };

  const titLeLength =
    watch('name') === undefined ? 0 : String(watch('name')).length;

  return (
    <Modal
      size="xl"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      name="form-edit-keyword"
      title="Edit Keyword"
      hideAction
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack py={4}>
          <TextField
            name="name"
            label="Nama Keyword"
            placeholder="Masukkan nama keyword"
            maxLength={30}
            register={register}
            errors={errors}
          />
          <Text fontSize="sm" color="gray" textAlign="right">
            {titLeLength}/30
          </Text>
          <CustomerTypeInput
            selectedBusinessSegmentation={data.business_segmentation}
            selectedDistributionChannel={data.distribution_channel}
          />
          <TextField
            name="url"
            label="URL"
            placeholder="Masukkan URL"
            register={register}
            errors={errors}
          />
        </Stack>
        <Divider borderColor="black" />
        <Stack py={4}>
          <Wrap justify="end">
            <WrapItem>
              <HStack>
                <Button
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Tutup
                </Button>
                <Button type="submit" variant="solid">
                  Simpan
                </Button>
              </HStack>
            </WrapItem>
          </Wrap>
        </Stack>
      </form>
    </Modal>
  );
}
