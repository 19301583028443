import { RecipeInformationSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  recipeResponseToState,
  useRecipeDetail,
  useUpdateRecipeMutation,
} from '../../api';
import { RecipeInformationForm } from '../../components/forms/recipe-information-form';
import { RecipeFormLayout } from '../../components/layouts/recipe-form-layout';

export function RecipeInformationDetail() {
  const params = useParams();
  const recipeId = params['recipeId'] as string;
  const { data } = useRecipeDetail(recipeId);
  const navigate = useNavigate();

  const form = useForm({
    // https://react-hook-form.com/faqs#Howtoinitializeformvalues
    values: data ? recipeResponseToState(data) : undefined,
    resetOptions: {
      keepDirtyValues: true,
    },
    resolver: yupResolver(RecipeInformationSchema),
  });

  const updateRecipeMutation = useUpdateRecipeMutation(recipeId);

  const onSubmit =
    (cb?: () => void) =>
    async (data: ReturnType<typeof recipeResponseToState>) => {
      await updateRecipeMutation.mutateAsync(data);
      cb?.();
    };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit())}>
        <RecipeFormLayout
          section={{
            title: 'Informasi Resep',
            link: 'information',
            index: 0,
          }}
          handleNext={form.handleSubmit(
            onSubmit(() => navigate(`/recipe/list/${recipeId}/description`))
          )}
        >
          <RecipeInformationForm form={form} />
        </RecipeFormLayout>
      </form>
    </FormProvider>
  );
}
