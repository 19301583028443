import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { youtubeRegex } from '@diamond/shared/validation';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import { PlayButtonIcon } from './play-button-icon';

type YoutubePlayerProps = {
  videoUrl: string;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
} & ButtonProps;

export function YoutubePlayer({
  handleClose,
  handleOpen,
  isOpen,
  videoUrl,
  ...props
}: YoutubePlayerProps) {
  const getIdFromVideoUrl = (url: string) => {
    const match = url.match(youtubeRegex);
    return match && match[6].length === 11 ? match[6] : '';
  };

  return (
    <>
      <Button
        display="flex"
        w={{ lg: 'auto', xl: 'auto' }}
        onClick={handleOpen}
        leftIcon={<PlayButtonIcon />}
        variant="outline"
        size={{ md: 'sm', xl: 'md' }}
        mt={1}
        {...props}
      >
        Lihat Tutorial
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW="1280px">
          <ModalHeader textAlign="center">Lihat Tutorial</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <LiteYouTubeEmbed
              id={getIdFromVideoUrl(videoUrl)}
              title="Video Tutorial"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={handleClose}>
              Kembali
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
