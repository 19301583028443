import { BackofficeDeleteRecipeSectionResponse } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation } from '@tanstack/react-query';
export const DeleteRecipeSection = async (token: string, id: string) => {
  const url = `/backoffice/recipe/section/${id}`;
  return apiInstance(token).delete<BackofficeDeleteRecipeSectionResponse>(url);
};

type useDeleteRecipeSectionMutationArgs = {
  onSuccess: () => void;
  onSettled: () => void;
};
export function useDeleteRecipeSectionMutation({
  onSettled,
  onSuccess,
}: useDeleteRecipeSectionMutationArgs) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: (id: string) => DeleteRecipeSection(token, id),
    onSuccess,
    onSettled,
  });
}
