import {
  AspectRatio,
  Box,
  Button,
  Center,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { TextField } from '@diamond/shared/ui';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  calculateColorShade,
  DefaultColor,
  determineDefaultColor,
} from './color-shade';

const colors = ['#006BD1', '#B80000', '#85EBFF', '#00A34B', '#FF5C5C'];

type ColorTextFieldProps = {
  defaultValue?: DefaultColor;
};

export function ColorTextField({
  defaultValue = { bg_color: '#006BD1', text_color: '#006BD1' },
}: ColorTextFieldProps) {
  const methods = useFormContext();
  const [color, setColor] = useState(determineDefaultColor(defaultValue));

  const hues = calculateColorShade(color);

  return (
    <>
      <Text>Warna Kategori</Text>
      <Box w="full" h="150px" mb="2" bg={hues['bg_color']}>
        <Center h="150px" color={hues['text_color']}>
          {color}
        </Center>
      </Box>
      <SimpleGrid columns={5} spacing={2} mb="2">
        {colors.map((c) => (
          <AspectRatio ratio={1}>
            <Button
              key={c}
              aria-label={c}
              background={c}
              padding={0}
              minWidth="unset"
              borderRadius={3}
              _hover={{ background: 'gray.70' }}
              onClick={() => {
                setColor(c);
                methods.setValue('color', c);
              }}
            />
          </AspectRatio>
        ))}
      </SimpleGrid>
      <TextField
        size="sm"
        value={color}
        name="color"
        register={methods.register}
        registerOptions={{
          onChange(event) {
            setColor(event.target.value);
          },
        }}
        defaultValue={color}
        errors={methods.formState.errors}
      />
    </>
  );
}
