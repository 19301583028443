import { Box, Flex, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import { PostDataWrapper } from '@diamond/shared/data-access';
import { API_URL } from '@diamond/shared/environments';
import {
  Breadcrumbs,
  Button,
  Divider,
  Dropzone,
  showToast,
} from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const UPLOAD_FILE_URL = '/backoffice/user/admin/upload';

export function UserPage() {
  const session = useAuthStore();
  const methods = useForm();
  const toast = useToast();
  const [isReady, setIsReady] = useState<boolean>(false);

  const { postData } = PostDataWrapper(
    UPLOAD_FILE_URL,
    session.access_token,
    true,
    'admin'
  );

  const stringError = methods.formState;

  const formData = new FormData();
  const fileData = methods.watch('file-user');

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'User Management',
      link: '/user',
    },
  ];

  const onSubmit = methods.handleSubmit(() => {
    if (fileData) {
      formData.append('file', fileData[0], fileData[0].name);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${session.access_token}`,
      },
    };

    postData(formData, config)
      .then(() => {
        showToast(toast, 'success', 'User Anda berhasil disimpan!');
        methods.reset();
      })
      .catch((error) => {
        console.log('GALAT', error);
        showToast(toast, 'error', error.message);
        methods.reset();
      });
  });

  const downloadTemplate = async () => {
    const windowUrl = window.URL || window.webkitURL;
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    fetch(`${API_URL}/backoffice/user/admin/download/template`, {
      headers: { Authorization: `Bearer ${session.access_token}` },
    })
      .then((response) => response.blob())
      .then((blobby) => {
        const objectUrl = windowUrl.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = 'template_user.xlsx';
        anchor.click();
        windowUrl.revokeObjectURL(objectUrl);
      })
      .catch((error) => {
        showToast(toast, 'error', `${error.message}`);
      });
  };

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Flex>
              <Text fontSize="2xl" as="b">
                User
              </Text>
              <Spacer />
              <Button variant="solid" type="submit" isDisabled={!isReady}>
                Simpan
              </Button>
            </Flex>
            <Stack pt={4} pb={8}>
              <Divider />
            </Stack>
            <Stack
              border="1px"
              borderRadius="md"
              borderColor="gray.33"
              px={4}
              py={6}
            >
              <Text fontSize="xl" as="b">
                Pilih File
              </Text>
              <Stack py={4}>
                <Divider variant="dashed" />
              </Stack>
              <Stack border="1px" borderRadius="md" borderColor="gray.33" p={6}>
                <Dropzone
                  name="file-user"
                  data-testid="upload-file-excel-user"
                  accept={{
                    'application/vnd.ms-excel': ['.xlsx'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                      ['.xlsx'],
                  }}
                  errors={stringError.errors}
                  onEmptied={() => setIsReady(false)}
                  setIsFileAttached={(val) => setIsReady(val)}
                />
                <Flex>
                  <Spacer />
                  <Text as="sub">Hanya menerima format .XLSX</Text>
                </Flex>
              </Stack>
            </Stack>
            <Stack
              mt={8}
              p={4}
              border="1px"
              borderRadius="md"
              borderColor="gray.33"
              alignItems="flex-start"
            >
              <Text as="b" fontSize="xl">
                Tolong gunakan template dibawah ini
              </Text>
              <Button
                variant="solid"
                bgColor="azure"
                onClick={() => downloadTemplate()}
              >
                Download Template Excel
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </AdminLayout>
  );
}
