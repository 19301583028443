import {
  BackofficeUpdateRecipeListResponse,
  RecipeState,
} from '@diamond/shared/types';
import { useWatch } from 'react-hook-form';

import { useRecipeDetail, useRecipeTags } from '../api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emptyArr: any[] = [];

export function useLivePreview() {
  const clientState = useWatch<RecipeState>();
  const serverState = useRecipeDetail();
  const tagsState = useRecipeTags();

  return {
    serverState,
    state: {
      allergens: clientState.allergens || emptyArr,
      author: clientState.author || '',
      body: clientState.body || emptyArr,
      category: clientState.category || 'food',
      created_at: serverState.data?.created_at || 0,
      id: serverState.data?.id || '',
      index: serverState.data?.index || 0,
      ingredient_groups: clientState.ingredient_groups
        ? clientState.ingredient_groups?.map((group) => {
            const serverIngredientGroup =
              serverState.data?.ingredient_groups.find(
                (ig) =>
                  ig.title === group.title &&
                  ig.ingredients.length === group.ingredients?.length
              );
            return {
              id: group.id,
              index: 0,
              ingredients: group.ingredients?.map((ig) => {
                const serverIngredient =
                  serverIngredientGroup?.ingredients.find(
                    (serverIg) =>
                      serverIg.title === ig.title &&
                      serverIg.amount ===
                        (!Number.isNaN(Number(ig.amount))
                          ? Number(ig.amount) * 1000
                          : 0) &&
                      serverIg.amount_unit === ig.amount_unit
                  );
                return {
                  title: ig.title,
                  amount: ig.amount,
                  amount_unit: ig.amount_unit,
                  recommended_products:
                    serverIngredient?.recommended_products || emptyArr,
                  alternative_products:
                    serverIngredient?.alternative_products || emptyArr,
                  id: ig.id,
                };
              }),
              is_optional: group.is_optional,
              title: group.title,
            };
          })
        : emptyArr,
      is_published: serverState.data?.is_published || false,
      making_duration: clientState.making_duration || 0,
      medias: clientState.medias || emptyArr,
      portion_size: clientState.portion_size || 0,
      short_desc: clientState.short_desc || '',
      slug: clientState.slug || '',
      tags: clientState.tags
        ? clientState.tags?.map((tagId) => {
            const tag = tagsState.data?.data.find((t) => t.id === tagId);
            return tag;
          })
        : emptyArr,
      thumbnail: serverState.data?.thumbnail || '',
      title: clientState.title || '',
      updated_at: serverState.data?.updated_at || 0,
      video: clientState.video?.trim() || null,
      views: 999,
    } as BackofficeUpdateRecipeListResponse,
  };
}
