import { Box, Flex, Spacer } from '@chakra-ui/react';

interface HighlightItemProps {
  data: { id: string; title: string };
  ActionButton: () => JSX.Element;
}
export function HighlightItem({ data, ActionButton }: HighlightItemProps) {
  return (
    <Flex
      minWidth="inherit"
      alignItems="center"
      gap={4}
      justifyContent="space-between"
      w="inherit"
    >
      <Box width={300}>{data?.title}</Box>
      <Spacer />
      <ActionButton />
    </Flex>
  );
}
