import { Text } from '@chakra-ui/react';
import { Control, FieldValues, Path, useWatch } from 'react-hook-form';

type CountCharacterFieldProps<
  TFields extends FieldValues,
  TName extends Path<TFields>
> = {
  control: Control<TFields>;
  name: TName;
  maxLength: number;
};

export function CountCharacterField<
  TFields extends FieldValues,
  TName extends Path<TFields>
>({ control, name, maxLength }: CountCharacterFieldProps<TFields, TName>) {
  const value = useWatch({ control, name });
  if (typeof value === 'number') return null;
  return (
    <Text textAlign="right" fontSize="xs" color="gray.75" w="full" mt="0">
      {(value as string)?.length || 0} / {maxLength}
    </Text>
  );
}
