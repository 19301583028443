import { Divider, Flex } from '@chakra-ui/react';
import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  // FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  ImageOutlined,
  LocalParkingOutlined,
  // Looks3Outlined,
  LooksOneOutlined,
  LooksTwoOutlined,
  TipsAndUpdatesOutlined,
} from '@mui/icons-material';
import {
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { ParagraphPlugin } from '@udecode/plate-core/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import {
  // BulletedListPlugin,
  NumberedListPlugin,
} from '@udecode/plate-list/react';

import { AlignButton } from './align-button';
import { BlockButton } from './block-button';
import { ImageButton } from './image-button';
import { ListButton } from './list-button';
import { MarkButton } from './mark-button';
import { TipsButton } from './tips-button';

export function Toolbar() {
  return (
    <Flex w="100%" justifyContent="space-between" bg="white" zIndex="30">
      <Flex gap="1">
        <MarkButton nodeType={BoldPlugin.key} icon={<FormatBold />} />
        <MarkButton nodeType={ItalicPlugin.key} icon={<FormatItalic />} />
        <MarkButton
          nodeType={UnderlinePlugin.key}
          icon={<FormatUnderlined />}
        />
        <Divider mx="1" orientation="vertical" />
        <BlockButton blockType={HEADING_KEYS.h1} icon={<LooksOneOutlined />} />
        <BlockButton blockType={HEADING_KEYS.h2} icon={<LooksTwoOutlined />} />
        {/* <BlockButton blockType={HEADING_KEYS.h3} icon={<Looks3Outlined />} /> */}
        <BlockButton
          blockType={ParagraphPlugin.key}
          icon={<LocalParkingOutlined />}
        />
        <Divider mx="1" orientation="vertical" />
        <TipsButton icon={<TipsAndUpdatesOutlined />} />
        <Divider mx="1" orientation="vertical" />
        <ImageButton icon={<ImageOutlined />} />
        <Divider mx="1" orientation="vertical" />
        <AlignButton alignType="left" icon={<FormatAlignLeft />} />
        <AlignButton alignType="center" icon={<FormatAlignCenter />} />
        <AlignButton alignType="right" icon={<FormatAlignRight />} />
        <AlignButton alignType="justify" icon={<FormatAlignJustify />} />
        <Divider mx="1" orientation="vertical" />
        {/* <ListButton
          listType={BulletedListPlugin.key}
          icon={<FormatListBulleted />}
        /> */}
        <ListButton
          listType={NumberedListPlugin.key}
          icon={<FormatListNumbered />}
        />
      </Flex>
    </Flex>
  );
}
