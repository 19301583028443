import { Button } from '@chakra-ui/react';
import { type Alignment, setAlign } from '@udecode/plate-alignment';
import { useEditorRef } from '@udecode/plate-core/react';
import { useSelectionFragmentProp } from '@udecode/plate-utils/react';
import { withRef } from '@udecode/react-utils';

type AlignButtonProps = {
  alignType: Alignment;
  icon: React.ReactNode;
};

export const AlignButton = withRef<typeof Button, AlignButtonProps>(
  ({ alignType, icon, ...rest }, ref) => {
    const editor = useEditorRef();

    const value = useSelectionFragmentProp({
      defaultValue: 'start',
      getProp: (node) => node['align'],
    }) as Alignment;

    const handleClick = () => {
      setAlign(editor, { value: alignType });
      editor.tf.focus();
    };

    const pressed = value === alignType;

    return (
      <Button
        w="30px"
        size="sm"
        px="2"
        variant={pressed ? 'toolbarSolid' : 'toolbar'}
        onClick={handleClick}
        ref={ref}
        {...rest}
      >
        {icon}
      </Button>
    );
  }
);
