import { PlateEditor } from '@udecode/plate-core/react';
import { NodeEntry, Path, PathApi, TElement } from '@udecode/slate';

export const insertBlock = (editor: PlateEditor, type: string) => {
  editor.tf.withoutNormalizing(() => {
    const block = editor.api.block();

    if (!block) return;

    editor.tf.insertNodes(editor.api.create.block({ type }), {
      at: PathApi.next(block[1]),
      select: true,
    });

    if (getBlockType(block[0]) !== type) {
      editor.tf.removeNodes({ previousEmptyBlock: true });
    }
  });
};

export const setBlockType = (
  editor: PlateEditor,
  type: string,
  { at }: { at?: Path } = {}
) => {
  editor.tf.withoutNormalizing(() => {
    const setEntry = (entry: NodeEntry<TElement>) => {
      const [node, path] = entry;
      if (node.type !== type) {
        editor.tf.setNodes({ type }, { at: path });
      }
    };

    if (at) {
      const entry = editor.api.node<TElement>(at);
      if (entry) {
        setEntry(entry);
        return;
      }
    }

    const entries = editor.api.blocks({ mode: 'lowest' });

    entries.forEach((entry) => setEntry(entry));
  });
};

export const getBlockType = (block: TElement) => {
  return block.type;
};
