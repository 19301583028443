import { useToast } from '@chakra-ui/react';
import { RecipeInformationSectionPayload } from '@diamond/shared/types';
import { showToast } from '@diamond/shared/ui';
import { RecipeInformationSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useCreateRecipeMutation } from '../api';
import { RecipeInformationForm } from '../components/forms/recipe-information-form';
import { RecipeFormLayout } from '../components/layouts/recipe-form-layout';

const defaultValues: RecipeInformationSectionPayload = {
  author: '',
  category: 'food',
  portion_size: 0,
  slug: '',
  tags: [],
  title: '',
  cg_3_business_types: [],
};

export function RecipeListCreatePage() {
  const toast = useToast();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues,
    resolver: yupResolver(RecipeInformationSchema),
  });

  const createRecipeMutation = useCreateRecipeMutation({
    onSuccess: (data) => {
      navigate(`/recipe/list/${data.id}/description`);
    },
    onError(error) {
      showToast(toast, 'error', error);
    },
  });

  const onSubmit = (data: RecipeInformationSectionPayload) => {
    createRecipeMutation.mutate(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <RecipeFormLayout
          showSaveButton={false}
          handleNext={form.handleSubmit(onSubmit)}
        >
          <RecipeInformationForm form={form} />
        </RecipeFormLayout>
      </form>
    </FormProvider>
  );
}
