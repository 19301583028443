import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { AddOutlined } from '@mui/icons-material';
import { useContext } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { useRecipeDetail } from '../../../api';
import { IngredientsFieldArray } from './ingredients-field-array';
import { ProductModeContext } from './product-mode-context';
import { RecipeIngredientsFields } from './types';
import { emptyArr } from './utils';

type IngredientsGroupFieldArrayProps = {
  form: UseFormReturn<RecipeIngredientsFields>;
  title: string;
  index: number;
};

export function IngredientsGroupFieldArray({
  form,
  title,
  index,
}: IngredientsGroupFieldArrayProps) {
  const productModeType = useContext(ProductModeContext);
  const { data } = useRecipeDetail();
  const isDisabled = data?.is_published;

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: `ingredient_groups.${index}.ingredients`,
  });

  return (
    <Card
      size="sm"
      w="full"
      variant="unstyled"
      border="none"
      mb="8"
      _last={{ mb: 0 }}
    >
      <CardHeader display="flex" justifyContent="space-between">
        <Heading size="md">{title}</Heading>
        <FormControl display="flex" alignItems="center" flex="0" gap="2">
          <FormLabel
            htmlFor="optional_ingredients"
            mb="0"
            mr="0"
            fontWeight="normal"
            color="gray.75"
          >
            Optional
          </FormLabel>
          <Switch
            id="optional_ingredients"
            colorScheme="linkedin"
            {...form.register(`ingredient_groups.${index}.is_optional`)}
            isDisabled={isDisabled}
          />
          <FormLabel htmlFor="optional_ingredients" mb="0" mr="0">
            Wajib
          </FormLabel>
        </FormControl>
      </CardHeader>
      <CardBody pt="4">
        <VStack spacing="3" alignItems="flex-start">
          {fields.map((f, i) => (
            <VStack alignItems="flex-start" key={`${f.id}${productModeType}`}>
              <IngredientsFieldArray
                form={form}
                ingredientGroupIndex={index}
                ingredientIndex={i}
                handleRemove={() => remove(i)}
                shouldHideRemoveBtn={fields.length <= 1}
              />
            </VStack>
          ))}
          {productModeType === 'recommended_products' && !isDisabled ? (
            <Button
              onClick={() =>
                append(emptyArr, {
                  focusName: `ingredient_groups.${index}.ingredients.${fields.length}.title`,
                })
              }
              variant="outline"
              leftIcon={<Icon fontSize="md" as={AddOutlined} />}
            >
              Bahan
            </Button>
          ) : null}
        </VStack>
      </CardBody>
    </Card>
  );
}
