import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ContentCopy as CopyIcon } from '@mui/icons-material';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import { showToast } from '../toast/toast';

type ShareButtonPopoverProps = {
  children: React.ReactNode;
  title: string;
  url: string;
  textToShare: string;
  onWhatsappShare?: () => void;
  onTelegramShare?: () => void;
  onFacebookShare?: () => void;
  onTwitterShare?: () => void;
  onCopyShare?: () => void;
};

export function ShareButtonPopover({
  children,
  title,
  url,
  textToShare,
  onWhatsappShare,
  onTelegramShare,
  onFacebookShare,
  onTwitterShare,
  onCopyShare,
}: ShareButtonPopoverProps) {
  const toast = useToast();

  const handleCopyLink = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (error) {
      console.error('Failed to copy text:', error);
    } finally {
      showToast(toast, 'success', 'Link berhasil disalin');
    }
  };

  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent
          w="200px"
          border="none"
          zIndex="1"
          filter="drop-shadow(0 3px 10px rgba(0,0,0,.14))"
        >
          <PopoverArrow border="none" />
          <PopoverBody>
            <Box textAlign="center">
              <Text fontSize="md" textAlign="start" mb="2">
                {title}
              </Text>
              <Flex justify="space-between" align="center">
                <WhatsappShareButton
                  url={url}
                  title={textToShare}
                  onClick={() => onWhatsappShare?.()}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TelegramShareButton
                  url={url}
                  title={textToShare}
                  onClick={() => onTelegramShare?.()}
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <FacebookShareButton
                  url={url}
                  onClick={() => onFacebookShare?.()}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={url}
                  title={textToShare}
                  onClick={() => onTwitterShare?.()}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <Button
                  aria-label="Copy"
                  size="sm"
                  borderRadius="50%"
                  width="32px"
                  height="32px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => {
                    handleCopyLink(`${textToShare} ${url}`);
                    onCopyShare?.();
                  }}
                >
                  <CopyIcon fontSize="small" />
                </Button>
              </Flex>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
