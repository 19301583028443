import { chakra } from '@chakra-ui/react';
import { Tips } from '@diamond/shared/ui';
import { withRef } from '@udecode/react-utils';

import { PlateElement } from './plate-element';

const PlateElementChakra = chakra(PlateElement);

export const TipsElement = withRef<typeof PlateElement>(
  ({ children, ...props }, ref) => {
    return (
      <PlateElementChakra asChild ref={ref} {...props}>
        <Tips>{children}</Tips>
      </PlateElementChakra>
    );
  }
);
