import { Button } from '@chakra-ui/react';
import { ParagraphPlugin } from '@udecode/plate-core/react';
import { useEditorRef } from '@udecode/plate-core/react';
import { useSelectionFragmentProp } from '@udecode/plate-utils/react';
import { withRef } from '@udecode/react-utils';
import { TElement } from '@udecode/slate';

import { getBlockType, setBlockType } from '../../transforms';

type BlockButtonProps = {
  blockType: string;
  icon: React.ReactNode;
};

export const BlockButton = withRef<typeof Button, BlockButtonProps>(
  ({ blockType, icon, ...rest }, ref) => {
    const editor = useEditorRef();

    const value = useSelectionFragmentProp({
      defaultValue: ParagraphPlugin.key,
      getProp: (node) => getBlockType(node as TElement),
    });

    const handleClick = () => {
      setBlockType(editor, blockType);
      editor.tf.focus();
    };

    const pressed = blockType === value;

    return (
      <Button
        w={typeof icon !== 'string' ? '30px' : 'auto'}
        size="sm"
        px="2"
        variant={pressed ? 'toolbarSolid' : 'toolbar'}
        ref={ref}
        onClick={handleClick}
        {...rest}
      >
        {icon}
      </Button>
    );
  }
);
