import {
  Box,
  Button,
  Center,
  Divider,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { usePaginationAdmin } from '@diamond/shared/context';
import { Breadcrumbs, Pagination } from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useCleanSearchParams } from '@diamond/sol-admin/hooks';
import { AddOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import { useRecipeList } from '../api';
import { RecipeList } from '../components/recipe-list';

const links = [
  {
    title: 'Beranda',
    link: '/',
  },
  {
    title: 'Recipe Management',
    link: '/recipe',
  },
  {
    title: 'Recipe List Management',
    link: '/recipe/list',
  },
];

export function RecipeListManagementPage() {
  const { paramsEntries, setParams } = useCleanSearchParams();
  const currentPage = !isNaN(Number(paramsEntries['page']))
    ? Number(paramsEntries['page'])
    : 1;

  const { data, isPending, refetch } = useRecipeList({
    search_query: paramsEntries['query'] || '',
    page: currentPage,
    size: 20,
  });

  const { data: firstTwoData } = useRecipeList({
    page: 1,
    size: 2,
  });

  const firstTwoDataIndex = firstTwoData?.data.map((item) => item.index);

  const pageCount = data?.pagination.pages || 1;

  const { nextPage, previousPage, lastPage, firstPage } = usePaginationAdmin(
    currentPage,
    (value) => setParams({ page: `${value}` }),
    refetch,
    pageCount
  );

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box py={4}>
        <Stack
          spacing={8}
          direction="row"
          justifyContent="space-between"
          mb={8}
        >
          <Text fontSize="2xl" fontWeight="bold">
            Menu Resep Management
          </Text>
          <Button
            as={Link}
            leftIcon={<Icon as={AddOutlined} />}
            to="/recipe/list/new"
          >
            Tambah Resep
          </Button>
        </Stack>
        <Divider />
        <RecipeList
          recipes={data}
          isRecipePending={isPending}
          firstTwoIndex={firstTwoDataIndex}
        />
        <Center my="8">
          <Pagination
            currentPage={currentPage}
            totalPage={pageCount}
            nextPage={nextPage}
            firstPage={firstPage}
            lastPage={lastPage}
            previousPage={previousPage}
          />
        </Center>
      </Box>
    </AdminLayout>
  );
}
