import { Box, Center, Flex, FlexProps, Spacer } from '@chakra-ui/react';
import type { DraggableProvided } from '@hello-pangea/dnd';
import { KeyboardDoubleArrowUp } from '@mui/icons-material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, { type FC } from 'react';

import type { SortableItem } from './types';

interface Props {
  item: SortableItem;
  isDragging: boolean;
  provided: DraggableProvided;
  isGroupedOver?: boolean;
  index?: number;
  props?: FlexProps;
  handleClickIndexing?: (id: string) => void;
  pageLoadedIndex?: number[];
  showIndexingButton?: boolean;
}

const SortableCard: FC<Props> = (props) => {
  const { item, isDragging, isGroupedOver, provided, index } = props;
  const originalIndex =
    item.originalIndex === undefined ? 0 : item.originalIndex;
  const idItem = item.id ? item.id : '';

  return (
    <Flex
      // minWidth="max-content"
      maxWidth="inherit"
      alignItems="center"
      gap={2}
      p={4}
      shadow="sm"
      borderWidth="1px"
      backgroundColor={
        isDragging || Boolean(isGroupedOver) ? 'gray.100' : 'gray.50'
      }
      aria-label={`Item ${item.id}`}
      data-is-dragging={isDragging}
      data-testid={item.id}
      data-index={index}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...props.props}
    >
      {props.handleClickIndexing &&
      !props.pageLoadedIndex?.includes(originalIndex) &&
      props.showIndexingButton ? (
        <Flex
          bg="white"
          w="25px"
          borderRadius="md"
          minH="40px"
          {...provided.dragHandleProps}
          mr={1}
        >
          <Center
            w="25px"
            h="40px"
            bg="white"
            color="blue"
            borderRadius="md"
            onClick={() => {
              if (props.handleClickIndexing) {
                props.handleClickIndexing(idItem);
              }
            }}
          >
            <KeyboardDoubleArrowUp />
          </Center>
        </Flex>
      ) : null}
      <Flex
        bg="white"
        w="40px"
        borderRadius="md"
        minH="40px"
        ml={
          props.handleClickIndexing &&
          props.pageLoadedIndex?.includes(originalIndex) &&
          props.showIndexingButton
            ? '35'
            : 0
        }
        {...provided.dragHandleProps}
        gap={2}
      >
        <Center w="40px" h="40px" bg="white" color="blue" borderRadius="md">
          <DragHandleIcon />
        </Center>
      </Flex>
      <Spacer />
      <Box maxWidth="inherit" width="full">
        {item.children}
      </Box>
    </Flex>
  );
};

export default React.memo<Props>(SortableCard);
