import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  HStack,
  useRadioGroup,
} from '@chakra-ui/react';
import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  useController,
} from 'react-hook-form';

import {
  RadioButtonCard,
  RadioButtonCardOption,
} from '../radio-button-card/radio-button-card';

export interface RadioButtonCardGroupProps<
  T extends FieldValues = FieldValues
> {
  label?: string;
  id?: string;
  options: Array<RadioButtonCardOption>;
  name: Path<T>;
  control: Control<T>;
  errors?: FieldErrors<T>;
  labelProps?: FormLabelProps;
  defaultValue?: PathValue<T, Path<T>>;
  isDisabled?: boolean;
}

export function RadioButtonCardGroup<TField extends FieldValues = FieldValues>({
  id,
  name,
  label,
  options,
  control,
  errors,
  labelProps,
  defaultValue,
  isDisabled,
}: RadioButtonCardGroupProps<TField>) {
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';
  const { field } = useController({
    name,
    control,
    defaultValue,
  });

  const { getRadioProps, getRootProps } = useRadioGroup({
    ...field,
  });

  return (
    <FormControl id={id} isInvalid={isInvalid}>
      {label && (
        <FormLabel aria-label={label} htmlFor={id} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <HStack {...getRootProps()}>
        {options.map((opt) => (
          <RadioButtonCard
            key={opt.value}
            label={opt.label}
            {...getRadioProps({
              value: opt.value,
            })}
            isDisabled={isDisabled}
          />
        ))}
      </HStack>
      <FormErrorMessage color="red">{errorMessage as string}</FormErrorMessage>
    </FormControl>
  );
}
