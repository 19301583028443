import chroma from 'chroma-js';

export function calculateColorShade(hex: string) {
  if (!chroma.valid(hex))
    return {
      bg_color: '',
      text_color: '',
    };

  const color = chroma(hex);
  const [h, s] = color.hsl();

  const luminance = color.luminance();

  if (luminance > 0.5) {
    return {
      bg_color: color.hex(),
      text_color: chroma.hsl(h, s, 1 - 0.91).hex(),
    };
  }

  return {
    bg_color: chroma.hsl(h, s, 0.91).hex(),
    text_color: color.hex(),
  };
}

export type DefaultColor = {
  bg_color: string;
  text_color: string;
};

export function determineDefaultColor({ bg_color, text_color }: DefaultColor) {
  const bgLuminance = chroma(bg_color).luminance();
  const textLuminance = chroma(text_color).luminance();
  return bgLuminance < textLuminance ? bg_color : text_color;
}
