import {
  Box,
  ButtonProps,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { BackofficeUpdateRecipeListResponse } from '@diamond/shared/types';

import { PlateSerializer } from './plate-serializer';
import { RecipeDescriptionCard } from './recipe-description-card';
import { RecipeImagesSlider } from './recipe-images-slider';
import { RecepiesMaterialAndProductCard } from './recipe-material-and-product-card';
import { RecipeTag } from './recipe-tag';
import { YoutubePlayer } from './youtube-player';

type RecipeDetailType<TData extends BackofficeUpdateRecipeListResponse> = {
  data: TData;
};

export const RecipeDetailMobile = <
  TData extends BackofficeUpdateRecipeListResponse
>({
  data,
}: RecipeDetailType<TData>) => {
  const hasAlternativeProducts = data.ingredient_groups.some((ig) =>
    ig.ingredients.some((e) => e.alternative_products.length > 0)
  );
  return (
    <Box isolation="isolate">
      <Stack>
        <Box
          flex="none"
          isolation="isolate"
          position="relative"
          borderRadius="lg"
          overflow="hidden"
        >
          {data.medias ? <RecipeImagesSlider recipe={data} /> : null}
          <Player
            data={data}
            position="absolute"
            left="50%"
            bottom="15%"
            transform="translateX(-50%)"
            textColor="white"
            bg="rgb(0,0,0,0.3)"
            borderColor="white"
            p={5}
          />
        </Box>
      </Stack>
      <Stack position="relative" spacing={3} px="4" mt={-8} zIndex="999">
        <Stack>
          <RecipeDescriptionCard
            recipe={data}
            player={data.video ? <Player data={data} /> : null}
            isLivePreview
          />
          {data.ingredient_groups.length > 0 ? (
            <Box borderRadius="lg" overflow="hidden" p={2} bg="white">
              <Flex p={2} justifyContent="space-between">
                <Text fontSize="xl" fontWeight="bold">
                  Bahan & Produk
                </Text>
                {data.portion_size ? (
                  <RecipeTag
                    tag={{
                      id: '',
                      bg_color: '#FFF2E2',
                      name: `${data.portion_size} Porsi`,
                      text_color: '#A26E2C',
                      big_icon_url: '',
                      small_icon_url: '/assets/icons/cutlery.svg',
                    }}
                  />
                ) : null}
              </Flex>
              <Tabs borderColor="gray.75" isFitted>
                {hasAlternativeProducts ? (
                  <TabList borderColor="transparent">
                    <Tab
                      bg="white"
                      px={1}
                      borderColor="#D9D9D9"
                      borderBottomWidth="4px"
                      _selected={{
                        borderColor: '#B80000',
                      }}
                    >
                      Rekomendasi
                    </Tab>

                    <Tab
                      bg="white"
                      px={1}
                      borderColor="#D9D9D9"
                      borderBottomWidth="4px"
                      _selected={{
                        borderColor: '#B80000',
                      }}
                    >
                      Alternatif
                    </Tab>
                  </TabList>
                ) : null}
                <TabPanels>
                  <TabPanel pr="2">
                    <RecepiesMaterialAndProductCard
                      recipe={data}
                      type="recommended_products"
                    />
                  </TabPanel>
                  {hasAlternativeProducts ? (
                    <TabPanel pr="2">
                      <RecepiesMaterialAndProductCard
                        recipe={data}
                        type="alternative_products"
                      />
                    </TabPanel>
                  ) : null}
                </TabPanels>
              </Tabs>
            </Box>
          ) : null}
          {Array.isArray(data.body) && data.body.length > 0 ? (
            <Stack>
              <Box borderRadius="lg" overflow="hidden" p={4} bg="white" pb="0">
                <Flex mb="6" justifyContent="space-between">
                  <Text fontSize="xl" fontWeight="bold">
                    Instruksi Pembuatan
                  </Text>
                  {data.portion_size ? (
                    <RecipeTag
                      tag={{
                        id: '',
                        bg_color: '#D6F4D7',
                        name: `${data.making_duration} Menit`,
                        text_color: '#0D4949',
                        big_icon_url: '',
                        small_icon_url: '/assets/icons/acute.svg',
                      }}
                    />
                  ) : null}
                </Flex>
                <PlateSerializer body={data.body} />
              </Box>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </Box>
  );
};

export function Player<TData extends BackofficeUpdateRecipeListResponse>({
  data,
  ...props
}: Pick<RecipeDetailType<TData>, 'data'> & ButtonProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return data.video ? (
    <YoutubePlayer
      handleOpen={onOpen}
      handleClose={onClose}
      isOpen={isOpen}
      videoUrl={data.video}
      {...props}
    />
  ) : null;
}
