import { Box, Flex, FlexProps, HStack, Image, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export interface CarouselProps extends FlexProps {
  duration?: number;
  imageData: { image: string; url?: string }[];
  isHomeHiglight?: boolean;
}

const ANIMATION_DIRECTION = 'right';

export function Carousel({
  imageData,
  w = 'full',
  h = '500',
  duration = 5000,
  isHomeHiglight = false,
  ...props
}: CarouselProps) {
  const arrowStyles = {
    cursor: 'pointer',
    pos: 'absolute',
    top: '50%',
    w: 'auto',
    mt: '-22px',
    p: '16px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    transition: '0.6s ease',
    borderRadius: '0 3px 3px 0',
    userSelect: 'none',
    _hover: {
      opacity: 0.8,
      bg: 'black',
    },
  } as const;

  const [currentSlide, setCurrentSlide] = useState(0);
  const router = useRouter();

  const slidesCount = imageData?.length;

  const isProductDetail = router.pathname.includes('/product/');

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };
  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };
  const setSlide = (slide: number) => {
    setCurrentSlide(slide);
  };
  const carouselStyle = {
    transition: 'all .5s',
    ml: `-${currentSlide * 100}%`,
  };

  useEffect(() => {
    const prevSlide = () => {
      setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };

    const nextSlide = () => {
      setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };

    const automatedSlide = setInterval(() => {
      ANIMATION_DIRECTION.toLowerCase() === 'left' ? prevSlide() : nextSlide();
    }, duration);
    return () => clearInterval(automatedSlide);
  }, [duration, slidesCount]);

  return (
    <Flex w="full" overflow="hidden" pos="relative">
      <Flex h={h} w={w} {...carouselStyle} {...props}>
        {imageData?.map((slide, sid) => (
          <Box key={`slide-${sid}`} boxSize="full" shadow="md" flex="none">
            <Text
              color="gray.75"
              fontSize="xs"
              p="8px 12px"
              pos="absolute"
              top="0"
            >
              {sid + 1} / {slidesCount}
            </Text>
            <Image
              src={slide.image ?? '/images/placeholder_homepage.png'}
              alt="carousel image"
              boxSize="full"
              backgroundSize="cover"
              rounded="lg"
              backgroundColor={isProductDetail ? 'white' : '#F4F4F4'}
              cursor={slide.url !== '' ? 'pointer' : 'default'}
              objectPosition="center"
              objectFit={isProductDetail ? 'scale-down' : 'fill'}
              fallbackSrc={
                isProductDetail
                  ? '/images/product_placeholder.png'
                  : '/images/placeholder_homepage.png'
              }
              style={{
                transform: 'scale(1)',
              }}
              onClick={
                slide.url ? () => window.open(slide.url, '_newtab') : undefined
              }
            />
          </Box>
        ))}
      </Flex>
      {slidesCount > 1 ? (
        <>
          <Text {...arrowStyles} left="0" onClick={prevSlide}>
            &#10094;
          </Text>
          <Text {...arrowStyles} right="0" onClick={nextSlide}>
            &#10095;
          </Text>
        </>
      ) : null}
      <HStack
        justify={{ base: 'center', md: 'right' }}
        pos="absolute"
        bottom={{ base: 2, md: 4 }}
        right={{ base: 0, md: 12 }}
        w="full"
        spacing="1"
        display={slidesCount > 1 ? 'flex' : 'none'}
      >
        {Array.from({ length: slidesCount }).map((_, slide) => (
          <Box
            key={`dots-${slide}`}
            cursor="pointer"
            boxSize={isHomeHiglight ? '2' : { base: 2, md: 4 }}
            bg={currentSlide === slide ? 'blue' : 'gray.33'}
            borderRadius="100%"
            display="inline-block"
            transition="background-color 0.6s ease"
            _hover={{ bg: 'blackAlpha.800' }}
            onClick={() => setSlide(slide)}
          />
        ))}
      </HStack>
    </Flex>
  );
}

export default Carousel;
