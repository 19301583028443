import { BackofficeRecipeMediaUploadResponse } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation } from '@tanstack/react-query';

export const uploadRecipeMedia = async <T>(
  access_token: string,
  payload: T
) => {
  const { data } = await apiInstance(access_token, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${access_token}`,
    },
  }).post<BackofficeRecipeMediaUploadResponse>(
    '/backoffice/recipe/upload',
    payload
  );
  return data;
};

type UseUploadRecipeMutationProps = {
  onSuccessUpload: (uploadedFiles: BackofficeRecipeMediaUploadResponse) => void;
};

export function useUploadRecipeMediaMutation({
  onSuccessUpload,
}: UseUploadRecipeMutationProps) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: ({ files }: { files: File[] }) => {
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(`file-${i}`, file, file.name);
      });

      return uploadRecipeMedia(token, formData);
    },
    onSuccess(data) {
      onSuccessUpload(data);
    },
  });
}
