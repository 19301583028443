import { Card, CardBody } from '@chakra-ui/react';
import { AlignPlugin } from '@udecode/plate-alignment/react';
import {
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { ExitBreakPlugin, SoftBreakPlugin } from '@udecode/plate-break/react';
import {
  ParagraphPlugin,
  Plate,
  PlateContent,
  usePlateEditor,
} from '@udecode/plate-core/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { HeadingPlugin } from '@udecode/plate-heading/react';
import {
  BulletedListPlugin,
  ListItemContentPlugin,
  ListItemPlugin,
  ListPlugin,
  NumberedListPlugin,
} from '@udecode/plate-list/react';
import { ImagePlugin, PlaceholderPlugin } from '@udecode/plate-media/react';
import { TrailingBlockPlugin } from '@udecode/plate-trailing-block';
import { Value } from '@udecode/slate';

import {
  BlockquoteElement,
  BoldLeaf,
  HeadingOneElement,
  HeadingTwoElement,
  ItalicLeaf,
  ListElement,
  ListItemElement,
  MediaPlaceholderElement,
  ParagraphElement,
  UndelineLeaf,
  UnorderedListElement,
} from './components';
import { ImageElement } from './components/image-element';
import { MediaUploadToast } from './components/media-upload-toast';
import { withPlaceholders } from './components/placeholder';
import { TipsElement } from './components/tips-element';
import { Toolbar } from './components/toolbar';
import {
  autoformatListPlugin,
  ListSoftBreakPlugin,
  TipsPlugin,
} from './plugins/lib';
import { CypressPlugin } from './plugins/lib/cypress/cypress-plugin';

type EditorProps = {
  defaultValue?: unknown;
  handleChange?: (value: Value) => void;
  isDisabled?: boolean;
};

export function Editor({
  defaultValue,
  handleChange,
  isDisabled,
}: EditorProps) {
  const editor = usePlateEditor({
    value: defaultValue as Value,
    plugins: [
      ParagraphPlugin,
      HeadingPlugin,
      BlockquotePlugin,
      BoldPlugin,
      ItalicPlugin,
      UnderlinePlugin,
      AlignPlugin.configure({
        inject: {
          targetPlugins: [
            ParagraphPlugin.key,
            HEADING_KEYS.h1,
            HEADING_KEYS.h2,
            HEADING_KEYS.h3,
          ],
        },
      }),
      ListPlugin.configure({
        options: {
          validLiChildrenTypes: [
            TipsPlugin.key,
            ImagePlugin.key,
            PlaceholderPlugin.key,
          ],
        },
      }),
      TipsPlugin,
      autoformatListPlugin,
      ListSoftBreakPlugin,
      SoftBreakPlugin.configure({
        options: {
          rules: [
            {
              hotkey: 'shift+enter',
              query: {
                exclude: [TipsPlugin.key],
              },
            },
            {
              hotkey: 'enter',
              query: {
                allow: [BlockquotePlugin.key, TipsPlugin.key],
              },
            },
          ],
        },
      }),
      ExitBreakPlugin.configure({
        options: {
          rules: [
            {
              hotkey: 'mod+enter',
            },
            {
              before: true,
              hotkey: 'mod+shift+enter',
            },
          ],
        },
      }),
      ImagePlugin,
      PlaceholderPlugin.configure({
        render: { afterEditable: MediaUploadToast },
      }),
      TrailingBlockPlugin.configure({
        options: {
          type: 'p',
        },
      }),
      CypressPlugin.configure({
        enabled: process.env.NODE_ENV !== 'production',
      }),
    ],
    override: {
      components: withPlaceholders({
        [ParagraphPlugin.key]: ParagraphElement,
        [BoldPlugin.key]: BoldLeaf,
        [ItalicPlugin.key]: ItalicLeaf,
        [UnderlinePlugin.key]: UndelineLeaf,
        [BlockquotePlugin.key]: BlockquoteElement,
        [HEADING_KEYS.h1]: HeadingOneElement,
        [HEADING_KEYS.h2]: HeadingTwoElement,
        [BulletedListPlugin.key]: UnorderedListElement,
        [NumberedListPlugin.key]: ListElement,
        [ListItemPlugin.key]: ListItemElement,
        [ListItemContentPlugin.key]: ParagraphElement,
        [TipsPlugin.key]: TipsElement,
        [ImagePlugin.key]: ImageElement,
        [PlaceholderPlugin.key]: MediaPlaceholderElement,
      }),
    },
  });

  return (
    <Plate
      readOnly={isDisabled}
      editor={editor}
      onChange={({ value, editor }) => {
        const isAstChange = editor.operations.some(
          (op) => op.type !== 'set_selection'
        );
        if (isAstChange) {
          handleChange?.(value);
        }
      }}
    >
      <Card variant="outline" size="sm">
        <CardBody
          opacity={isDisabled ? '0.4' : undefined}
          cursor={isDisabled ? 'not-allowed' : undefined}
        >
          <Toolbar />
        </CardBody>
      </Card>
      <Card variant="outline" size="sm" mt="3" minH="440px">
        <CardBody as={PlateContent} autoFocus wordBreak="break-word" />
      </Card>
    </Plate>
  );
}
