import {
  BackofficeCreateRecipeResponse,
  BackofficeRecipeDetailResponse,
  BackofficeUpdateRecipeListBodyAlternativeProducts,
  BackofficeUpdateRecipeListBodyIngredients,
  BackofficeUpdateRecipeListBodyRecommendedProducts,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { BACKOFFICE_RECIPE_QUERY_KEY } from './get-recipe-list';

export const getRecipeDetail = async (token: string, recipeId: string) => {
  const url = `/backoffice/recipe/${recipeId}`;
  const { data } = await apiInstance(token).get<BackofficeRecipeDetailResponse>(
    url
  );
  return data;
};

function recipeDetailQueryOptions(token: string, recipeID: string) {
  return queryOptions({
    queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'list', recipeID],
    queryFn: async () => {
      if (!recipeID) return undefined;
      const data = await getRecipeDetail(token, recipeID);
      return data;
    },
    staleTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: false,
  });
}

export function useRecipeDetail(recipeId?: string) {
  const params = useParams();
  const id = recipeId ? recipeId : params?.['recipeId'] || '';
  const token = useAuthStore((s) => s.access_token);
  return useQuery(recipeDetailQueryOptions(token, id));
}

export function recipeResponseToState(
  response: BackofficeCreateRecipeResponse
) {
  return {
    title: response.title,

    allergens: response.allergens,
    author: response.author,
    body:
      Object.keys(response.body).length === 0 && !Array.isArray(response.body)
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as Array<any>)
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (response.body as Array<any>),
    category: response.category,
    making_duration: response.making_duration,
    medias: response.medias,
    portion_size: response.portion_size,
    short_desc: response.short_desc,
    slug: response.slug,
    tags: response.tags.map((t) => t.id),
    cg_3_business_types: response.cg_3_business_types.map(
      (t) => t.cg_3_business_type
    ),
    thumbnail: response.thumbnail,
    video: response.video,
    ingredient_groups: response.ingredient_groups.map((ig) => ({
      ingredients:
        ig.ingredients.map<BackofficeUpdateRecipeListBodyIngredients>(
          (igr) => ({
            alternative_products:
              igr.alternative_products.map<BackofficeUpdateRecipeListBodyAlternativeProducts>(
                (ap) => ap.id
              ),
            amount: igr.amount / 1000,
            amount_unit: igr.amount_unit,
            recommended_products:
              igr.recommended_products.map<BackofficeUpdateRecipeListBodyRecommendedProducts>(
                (rp) => rp.id
              ),
            title: igr.title,
            id: igr.id,
          })
        ),
      is_optional: ig.is_optional,
      title: ig.title,
      id: ig.id,
    })),
    is_published: response.is_published,
  };
}
