import { event } from 'nextjs-google-analytics';

function sanitizeString(input: string): string {
  return input.replace(/[^a-zA-Z0-9]/g, '_');
}

export const trackEvent = (
  eventName: string,
  params: { [key: string]: string | number | boolean | undefined }
) => {
  const session = localStorage.getItem('session-store');
  const shipto = localStorage.getItem('shipto-store');
  const parseUser = session ? JSON.parse(session)?.state : {};
  const parseShipTo = shipto ? JSON.parse(shipto)?.state : {};

  const eventParams = {
    ...params,
    platform: 'website',
    user_id: parseUser.userId ?? '-',
    user_name: parseUser.userName ?? '-',
    shipto_id: parseShipTo.selectedShipTo.id ?? '-',
    shipto_name: parseShipTo.selectedShipTo.name ?? '-',
    shipto_distribution_channel:
      parseShipTo.selectedShipTo.distribution_channel ?? '-',
    url: window.location.pathname ?? '-',
  };

  const name = sanitizeString(eventName);

  if (typeof window !== 'undefined' && typeof event === 'function') {
    event(name, eventParams);
  } else {
    console.error('Event analytics is not available.');
  }
};

export const trackPageView = (
  pageName: string,
  url = '/',
  params?: { [key: string]: string | number | boolean | undefined }
) => {
  const name = sanitizeString(pageName);

  const eventParams = {
    ...params,
    page: name,
    url,
    platform: 'website',
  };

  if (typeof window !== 'undefined' && typeof event === 'function') {
    event('page_view', eventParams);
  } else {
    console.error('Event analytics is not available.');
  }
};
