import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react';
import { RecipeDetailMobile } from '@diamond/shared/ui';

import { useLivePreview } from '../hooks';

export function RecipeLivePreview() {
  const data = useLivePreview();

  return (
    <Card
      variant="outline"
      flex="1"
      bg="#FEF9F2"
      h={0}
      minH="100%"
      overflowY="auto"
    >
      <CardHeader>
        <Heading size="md">Preview</Heading>
      </CardHeader>
      <CardBody maxW="md" mx="auto" w="full" id="recipe-live-preview">
        <RecipeDetailMobile
          data={data.state}
          key={data.serverState.dataUpdatedAt}
        />
      </CardBody>
    </Card>
  );
}
