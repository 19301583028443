import {
  Box,
  Button,
  Center,
  Divider,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { usePaginationAdmin } from '@diamond/shared/context';
import { Breadcrumbs, Pagination, showToast } from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useCleanSearchParams } from '@diamond/sol-admin/hooks';
import { Add } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';

import {
  BACKOFFICE_RECIPE_QUERY_KEY,
  deleteRecipeTag,
  useRecipeTags,
} from '../api';
import { CreateRecipeCategory } from '../components/create-recipe-category';
import { RecipeCategoryHeading } from '../components/recipe-category-heading';
import { RecipeCategoryItem } from '../components/recipe-category-item';

const links = [
  {
    title: 'Beranda',
    link: '/',
  },
  {
    title: 'Recipe Management',
    link: '/recipe',
  },
  {
    title: 'Recipe Category',
    link: '/recipe/category',
  },
];

export function RecipeCategoryPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { paramsEntries, setParams } = useCleanSearchParams();
  const toast = useToast();
  const queryClient = useQueryClient();

  const currentPage = !isNaN(Number(paramsEntries['page']))
    ? Number(paramsEntries['page'])
    : 1;

  const { data, refetch } = useRecipeTags({
    page: currentPage,
    size: 20,
  });

  const pageCount = data?.pagination.pages || 1;

  const session = useAuthStore();
  const { nextPage, previousPage, lastPage, firstPage } = usePaginationAdmin(
    currentPage,
    (value) => setParams({ page: `${value}` }),
    refetch,
    pageCount
  );

  const handleDelete = (id: string) => {
    deleteRecipeTag(session.access_token, id)
      .then(() => refetch())
      .catch((error) => showToast(toast, 'error', error.message))
      .finally(() => {
        showToast(toast, 'success', 'Kategori berhasil dihapus');
        queryClient.invalidateQueries({
          queryKey: [BACKOFFICE_RECIPE_QUERY_KEY],
        });
      });
  };
  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box py={4}>
        <Stack
          spacing={8}
          direction="row"
          justifyContent="space-between"
          mb="3"
        >
          <Text fontSize="2xl" fontWeight="bold">
            Kategori Resep Management
          </Text>
          <Button
            leftIcon={<Add />}
            textColor="#000000"
            variant="outline"
            onClick={onOpen}
          >
            Tambah Kategori
          </Button>
        </Stack>
        <Divider border="2px" borderColor="#D9D9D9" mb="5" />
        <Stack>
          <RecipeCategoryHeading />
          {data?.data.map((category) => {
            return (
              <RecipeCategoryItem
                item={category}
                onDelete={() => {
                  handleDelete(category.id);
                }}
              />
            );
          })}
        </Stack>
        <CreateRecipeCategory onClose={onClose} isOpen={isOpen} />
        <Center my="8">
          <Pagination
            currentPage={currentPage}
            totalPage={pageCount}
            nextPage={nextPage}
            firstPage={firstPage}
            lastPage={lastPage}
            previousPage={previousPage}
          />
        </Center>
      </Box>
    </AdminLayout>
  );
}
