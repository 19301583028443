import { Box, HStack, Image, Spacer, Text } from '@chakra-ui/react';
import { BackOfficeProductAdditionalData } from '@diamond/shared/types';
import { Badge } from '@diamond/shared/ui';

type BrowseProductCardProps = {
  idx: number;
  product: BackOfficeProductAdditionalData[0];
  selected: boolean;
  handleSelectProduct: (
    productSelected: BackOfficeProductAdditionalData[0]
  ) => void;
  resetSearch: () => void;
};

export function BrowseProductCard({
  idx,
  product,
  selected,
  handleSelectProduct,
  resetSearch,
}: BrowseProductCardProps) {
  return (
    <Box
      id={`product-${idx}`}
      key={`product-${idx}`}
      _hover={{
        backgroundColor: 'blue',
        textColor: 'white',
      }}
      p="2"
      borderRadius="lg"
      textColor={selected ? 'grey' : 'blue'}
      onClick={() => {
        if (!selected) {
          handleSelectProduct(product);
          resetSearch();
        }
      }}
      cursor={selected ? 'default' : 'pointer'}
    >
      <HStack>
        <Image
          src={product.product_image_thumbnails[0]}
          w="60px"
          h="60px"
          objectFit="scale-down"
          fallbackSrc="/assets/images/product_placeholder.png"
        />
        <Box>
          <Text>{product.desc}</Text>
          <Text>{product.item_code}</Text>
        </Box>
        <Spacer />
        {selected && (
          <Box>
            <Badge rounded="lg" textColor="grey">
              Terpilih
            </Badge>
          </Box>
        )}
      </HStack>
    </Box>
  );
}
