import {
  BackofficeRecipeNotificationListQuery,
  BackofficeRecipeNotificationListResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { queryOptions, useQuery } from '@tanstack/react-query';

export const getRecipeNotification = async (
  token: string,
  query: BackofficeRecipeNotificationListQuery
) => {
  const url = `/backoffice/recipe/notifications`;

  const { data } = await apiInstance(
    token
  ).get<BackofficeRecipeNotificationListResponse>(url, { params: query });
  return data;
};

export const BACKOFFICE_RECIPE_NOTIFICATION_QUERY_KEY = 'RecipeNotification';

function recipeNotificationQueryOptions(
  token: string,
  query: BackofficeRecipeNotificationListQuery
) {
  return queryOptions({
    queryKey: [BACKOFFICE_RECIPE_NOTIFICATION_QUERY_KEY, query],
    queryFn: () => getRecipeNotification(token, query),
  });
}

export function useRecipeNotification(
  query: BackofficeRecipeNotificationListQuery = {
    page: 1,
    size: 20,
    direction: 'desc',
  }
) {
  const token = useAuthStore((s) => s.access_token);
  return useQuery(recipeNotificationQueryOptions(token, query));
}
