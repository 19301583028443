import { Text, TextProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

export const Paragraph = forwardRef<
  React.ElementRef<typeof Text>,
  React.PropsWithChildren<TextProps>
>(({ children, ...props }, ref) => {
  return (
    <Text {...props} ref={ref}>
      {children}
    </Text>
  );
});

Paragraph.displayName = 'Paragraph';
