import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BACKOFFICE_RECIPE_QUERY_SECTION_KEY } from './get-recipe-section';

export const reorderSectionItemRecipe = async (
  token: string,
  sectionId: string,
  itemId: string,
  index: number
) => {
  return apiInstance(token).patch(
    `/backoffice/recipe/section/${sectionId}/item/${itemId}`,
    JSON.stringify({ index })
  );
};

type ReorderRecipeArgs = {
  sectionId: string;
  itemId: string;
  index: number;
};

export function useRecipeSectionItemReorderMutation() {
  const queryClient = useQueryClient();
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: ({ sectionId, itemId, index }: ReorderRecipeArgs) =>
      reorderSectionItemRecipe(token, sectionId, itemId, index),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY, variables.sectionId],
      });
    },
  });
}
