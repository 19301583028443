import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { RecipeInformationSectionPayload } from '@diamond/shared/types';
import {
  CheckboxListGroup,
  CountCharacterField,
  RadioButtonCardGroup,
  TextField,
} from '@diamond/shared/ui';
import { useBusinessTypes } from '@diamond/sol-admin/features/shipto';
import { UseFormReturn } from 'react-hook-form';
import slugify from 'slugify';

import { useRecipeDetail, useRecipeTags } from '../../api';
import { SlugField } from './fields';

type RecipeInfomationFields = RecipeInformationSectionPayload;

type RecipeInformationFormProps<
  TFields extends RecipeInfomationFields = RecipeInfomationFields
> = {
  form: UseFormReturn<TFields>;
};

export function RecipeInformationForm<
  TFields extends RecipeInfomationFields = RecipeInfomationFields
>({ form }: RecipeInformationFormProps<TFields>) {
  const tagsQuery = useRecipeTags();
  const businessTypeQuery = useBusinessTypes();
  const _form = form as UseFormReturn<RecipeInfomationFields>;

  const { data } = useRecipeDetail();
  const isPublished = data?.is_published;

  return (
    <Card variant="outline" size="sm" flex="1">
      <CardHeader>
        <Heading size="md">Informasi Resep</Heading>
      </CardHeader>
      <CardBody>
        <VStack spacing="3" alignItems="flex-start">
          <VStack w="full">
            <TextField
              register={_form.register}
              name="title"
              label="Nama resep:"
              placeholder="Masukkan nama resep"
              maxLength={40}
              errors={_form.formState.errors}
              labelProps={{
                color: 'gray.75',
                fontWeight: 'medium',
                fontSize: 'sm',
              }}
              isDisabled={isPublished}
            />
            <CountCharacterField
              control={_form.control}
              name="title"
              maxLength={40}
            />
          </VStack>

          <SlugField
            control={_form.control}
            setValue={_form.setValue}
            name="slug"
            label="Link Preview: "
            titleName="title"
            slugifyFn={(title) =>
              slugify(title, {
                lower: true,
                replacement: '-',
                trim: true,
              })
            }
          />

          <VStack w="full">
            <TextField
              register={_form.register}
              errors={_form.formState.errors}
              name="author"
              label="Dibuat oleh:"
              placeholder="Masukkan pembuat resep"
              maxLength={40}
              labelProps={{
                color: 'gray.75',
                fontWeight: 'medium',
                fontSize: 'sm',
              }}
              isDisabled={isPublished}
            />
            <CountCharacterField
              control={_form.control}
              name="author"
              maxLength={40}
            />
          </VStack>
          <RadioButtonCardGroup
            control={_form.control}
            errors={_form.formState.errors}
            name="category"
            label="Tipe resep:"
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
            options={[
              { label: 'Makanan', value: 'food' },
              { label: 'Minuman', value: 'beverage' },
            ]}
            isDisabled={isPublished}
          />
          <Flex gap="2">
            <CheckboxListGroup
              control={_form.control}
              errors={_form.formState.errors}
              name="cg_3_business_types"
              label="Tipe Business - CG3 (hidden):"
              labelProps={{
                color: 'gray.75',
                fontWeight: 'medium',
                fontSize: 'sm',
              }}
              cardProps={{
                w: '250px',
                h: '600px',
              }}
              allOption={{ value: '', label: 'Pilih Semua Tipe' }}
              options={businessTypeQuery.options}
              variant="recipe"
              isLoading={businessTypeQuery.isPending}
              isDisabled={isPublished}
            />
            <CheckboxListGroup
              key={tagsQuery.dataUpdatedAt}
              control={_form.control}
              errors={_form.formState.errors}
              name="tags"
              label="Kategori:"
              labelProps={{
                color: 'gray.75',
                fontWeight: 'medium',
                fontSize: 'sm',
              }}
              cardProps={{
                w: '250px',
                h: '600px',
              }}
              allOption={{ value: '', label: 'Pilih Semua Kategori' }}
              options={tagsQuery.data?.options}
              variant="recipe"
              isLoading={tagsQuery.isPending}
              isDisabled={isPublished}
            />
          </Flex>
        </VStack>
      </CardBody>
    </Card>
  );
}
