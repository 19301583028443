import { CancelReason, QueryStringSearch } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useQuery } from '@tanstack/react-query';

export const getCancelReason = async (
  token: string,
  params: Readonly<QueryStringSearch>
) => {
  const url = `/backoffice/order/cancel-reason`;
  const { data } = await apiInstance(token).get<CancelReason>(url, {
    params,
  });
  return data;
};

type queryString = {
  size: number;
  page: number;
};

function cancelReasonResponseToMap(data: CancelReason) {
  return {
    ...data,
    cancelReasonMap: data.data.reduce((acc, curr) => {
      if (!acc[curr.reason_id]) acc[curr.reason_id] = curr;
      return acc;
    }, {} as Record<SingleCancelReason['reason_id'], SingleCancelReason>),
  };
}

type SingleCancelReason = CancelReason['data'][number];

export const useCancelReason = (queryString: queryString) => {
  const token = useAuthStore((s) => s.access_token);

  const { data: cancelReasonData } = useQuery({
    queryKey: ['CancelReason'],
    queryFn: () => getCancelReason(token, queryString),
    select: cancelReasonResponseToMap,
    staleTime: 10 * 60 * 1000, // 10 Minutes
    gcTime: 30 * 60 * 1000, // 30 Minutes
  });

  return {
    cancelReasonData,
  };
};

export type UseCancelReasonData = ReturnType<
  typeof useCancelReason
>['cancelReasonData'];
