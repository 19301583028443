import {
  BackofficeRecipeSectionDetailResponse,
  BackofficeRecipeSectionListResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { BACKOFFICE_RECIPE_QUERY_SECTION_KEY } from './get-recipe-section';

export const getRecipeSectionDetail = async (
  token: string,
  sectionId: string
) => {
  const url = `/backoffice/recipe/section/${sectionId}`;
  const { data } = await apiInstance(
    token
  ).get<BackofficeRecipeSectionDetailResponse>(url);
  return data;
};

export function recipeSectionDetailQueryOptions(
  token: string,
  id: string,
  initialData: BackofficeRecipeSectionDetailResponse
) {
  return queryOptions({
    queryKey: [BACKOFFICE_RECIPE_QUERY_SECTION_KEY, id],
    queryFn: () => getRecipeSectionDetail(token, id),
    initialData,
  });
}

export function useRecipeSectionDetail(
  id: string,
  initialData: BackofficeRecipeSectionListResponse['data'][number]
) {
  const token = useAuthStore((s) => s.access_token);

  const transformedInitialData: BackofficeRecipeSectionDetailResponse = {
    id: initialData.id,
    title: initialData.title,
    type: initialData.type,
    recipes: [],
  };

  return useQuery(
    recipeSectionDetailQueryOptions(token, id, transformedInitialData)
  );
}
