import { Box, Button } from '@chakra-ui/react';
import { useEditorRef } from '@udecode/plate-core/react';
import { withRef } from '@udecode/react-utils';

import { TipsPlugin } from '../../plugins/lib/tips-plugin';
import { insertBlock } from '../../transforms';

type TipsButtonProps = {
  icon: React.ReactNode;
};

export const TipsButton = withRef<typeof Button, TipsButtonProps>(
  ({ icon, ...rest }, ref) => {
    const editor = useEditorRef();

    const handleClick = () => {
      // TODO: find out how to autofocus
      insertBlock(editor, TipsPlugin.key);
    };

    return (
      <Button
        size="sm"
        px="2"
        variant="toolbar"
        ref={ref}
        onClick={handleClick}
        {...rest}
      >
        {icon}{' '}
        <Box as="span" ml="1">
          Tips
        </Box>
      </Button>
    );
  }
);
