import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  IconButton,
  Link,
  Spacer,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DeleteDataWrapper } from '@diamond/shared/data-access';
import { ProductKeyword } from '@diamond/shared/types';
import { Modal, showToast } from '@diamond/shared/ui';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { EditKeyword } from './edit-keyword';

interface KeywordItemProps {
  data: ProductKeyword[0];
  refetch: () => void;
}
export function KeywordItem({ data, refetch }: KeywordItemProps) {
  const Keyword = Yup.object().shape({
    name: Yup.string().required('Nama Keyword harus di isi'),
    url: Yup.string().required('URL keyword harus di isi'),
  });

  const session = useAuthStore();
  const methods = useForm({
    defaultValues: {
      ...data,
      business_segmentation: undefined,
      distribution_channel: undefined,
    },
    resolver: yupResolver(Keyword),
  });
  const toast = useToast();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const { deleteData } = DeleteDataWrapper(
    'backoffice/product/keyword',
    session.access_token
  );

  function handleDelete(id: string) {
    deleteData(id)
      .then(() => {
        showToast(toast, 'success', 'Keyword berhasil dihapus');
        onDeleteClose();
        refetch();
      })
      .catch(({ response }) => {
        showToast(toast, 'error', response.data.message);
      });
  }
  const trimmedName =
    data?.name.length > 25 ? `${data?.name.slice(0, 25)}...` : data?.name;
  const trimmedURL =
    data?.url.length >= 100 ? `${data?.url.slice(0, 80)}...` : data?.url;

  return (
    <>
      <Flex
        minWidth="inherit"
        alignItems="center"
        gap={4}
        justifyContent="space-between"
        w="inherit"
      >
        <Box width={300}>
          <Tooltip label={data?.name}>{trimmedName}</Tooltip>
        </Box>
        <Box w="100%">
          <Flex
            minWidth="inherit"
            alignItems="center"
            gap={4}
            justifyContent="space-between"
            w="100%"
          >
            <Box>
              <Link href={data.url} isExternal>
                <Tooltip label={data.url}>
                  {decodeURIComponent(trimmedURL)}
                </Tooltip>
              </Link>
            </Box>
            <Spacer />
            <Box>
              <ButtonGroup>
                <IconButton
                  aria-label="Edit"
                  icon={<CreateOutlinedIcon />}
                  fontSize="xs"
                  variant="solid"
                  borderColor="gray.33"
                  onClick={onEditOpen}
                />
                <IconButton
                  aria-label="Delete"
                  icon={<DeleteOutlinedIcon />}
                  fontSize="xs"
                  variant="solid"
                  borderColor="gray.33"
                  background="red"
                  color="white"
                  onClick={onDeleteOpen}
                />
              </ButtonGroup>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <FormProvider {...methods}>
        <EditKeyword
          data={data}
          isOpen={isEditOpen}
          onClose={onEditClose}
          onSuccess={refetch}
        />
      </FormProvider>

      <Modal
        name="delete-keyword"
        labelSubmit="Hapus"
        labelCancel="Kembali"
        buttonColor="red"
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSubmit={() => {
          handleDelete(data.id);
        }}
        isCentered
      >
        <Center py={8}>
          <Heading>Hapus Keyword?</Heading>
        </Center>
      </Modal>
    </>
  );
}

export default KeywordItem;
