import { ErrorPage } from '@diamond/sol-admin/components';
import {
  AccountPage,
  AccountPageDetail,
} from '@diamond/sol-admin/features/account';
import {
  ActivitiesDetailPage,
  ActivitiesDetailPOPage,
  ActivitiesPage,
  ActivitiesPaymentPage,
} from '@diamond/sol-admin/features/activity';
import {
  AuthLayout,
  CreatePasswordPage,
  LoginPage,
  ResetPasswordPage,
} from '@diamond/sol-admin/features/auth';
import { BannerPage } from '@diamond/sol-admin/features/banner';
import { HighlightPage } from '@diamond/sol-admin/features/highlight';
import { HomePage } from '@diamond/sol-admin/features/home';
import { KeywordsPage } from '@diamond/sol-admin/features/keyword';
import { MinimumOrderPage } from '@diamond/sol-admin/features/minimum-order';
import { PromoDetailPage, PromoPage } from '@diamond/sol-admin/features/promo';
import {
  BlastNotificationPage,
  RecipeCategoryPage,
  RecipeDescriptionDetail,
  RecipeDetailLayout,
  RecipeInformationDetail,
  RecipeIngredientDetail,
  RecipeInstructionDetail,
  RecipeListCreatePage,
  RecipeListManagementPage,
  RecipeManagementPage,
  RecipeSectionHomepage,
  RecipeSectionRecipeList,
} from '@diamond/sol-admin/features/recipe';
import { ChangePasswordPage, UserPage } from '@diamond/sol-admin/features/user';
import * as Sentry from '@sentry/react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { ProtectedRoute } from './protected-route';
import { withLoggedOut } from './with-authenticated';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function AppRoutes() {
  const navigate = useNavigate();
  const AccessDeniedPage = withLoggedOut(LoginPage);

  return (
    <SentryRoutes>
      {/* Public routes */}
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<AccessDeniedPage />} />
        <Route
          path="/create-password/:token"
          element={<CreatePasswordPage />}
        />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      </Route>

      {/* Protected routes */}
      <Route
        element={
          <ProtectedRoute
            requiredRoles={['super_admin', 'cs_katalog', 'cs_cabang']}
          />
        }
      >
        <Route path="/" element={<HomePage />} />
        <Route path="/activities" element={<ActivitiesPage />} />
        <Route
          path="/activities/:activitiesId"
          element={<ActivitiesDetailPage />}
        />
        <Route
          path="/activities/:activitiesId/payment-proof"
          element={<ActivitiesPaymentPage />}
        />
        <Route
          path="/activities/meta/:orderMetaId"
          element={<ActivitiesDetailPOPage />}
        />
        <Route path="/change-password" element={<ChangePasswordPage />} />
      </Route>

      <Route element={<ProtectedRoute requiredRoles={['super_admin']} />}>
        <Route path="/account" element={<AccountPage />} />
        <Route
          path="/account/:userId/:shiptoId"
          element={<AccountPageDetail />}
        />
        <Route path="/highlight" element={<HighlightPage />} />
        <Route path="/banners" element={<BannerPage />} />
        <Route path="/keywords" element={<KeywordsPage />} />
        <Route path="/minimum-order" element={<MinimumOrderPage />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/promo" element={<PromoPage />} />
        <Route path="/promo/:promoId" element={<PromoDetailPage />} />
        <Route path="/recipe" element={<RecipeManagementPage />} />
        <Route path="/recipe/list" element={<RecipeListManagementPage />} />
        <Route path="/recipe/list/new" element={<RecipeListCreatePage />} />
        <Route element={<RecipeDetailLayout />}>
          <Route
            path="/recipe/list/:recipeId"
            element={<RecipeInformationDetail />}
          />
          <Route
            path="/recipe/list/:recipeId/description"
            element={<RecipeDescriptionDetail />}
          />
          <Route
            path="/recipe/list/:recipeId/ingredients"
            element={<RecipeIngredientDetail />}
          />
          <Route
            path="/recipe/list/:recipeId/instruction"
            element={<RecipeInstructionDetail />}
          />
        </Route>
        <Route
          path="/recipe/notification"
          element={<BlastNotificationPage />}
        />
        <Route path="recipe/category" element={<RecipeCategoryPage />} />
        <Route
          path="/recipe/section/homepage"
          element={<RecipeSectionHomepage />}
        />
        <Route
          path="/recipe/section/recipe-list"
          element={<RecipeSectionRecipeList />}
        />
      </Route>

      {/* 👇️ only match this when no other routes match */}
      <Route
        path="*"
        element={
          <ErrorPage
            message="Terjadi Kesalahan"
            subtitle="Halaman tidak ditemukan"
            buttonTitle="Kembali Ke Beranda"
            action={() => navigate('/')}
          />
        }
      />
      <Route
        path="/activities/error"
        element={
          <ErrorPage
            message="Terjadi Kesalahan"
            subtitle="Maaf terdapat harga atau stock barang yang tidak ditemukan. Mohon periksa kembali data stock dan harga pada SAP"
            buttonTitle="Kembali Ke Laman Activities"
            action={() => navigate('/activities')}
          />
        }
      />
    </SentryRoutes>
  );
}
