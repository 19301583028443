import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers([
    'stepper',
    'step',
    'title',
    'description',
    'indicator',
    'separator',
    'icon',
    'number',
  ]);

const $size = cssVar('stepper-indicator-size');
const $iconSize = cssVar('stepper-icon-size');
const $titleFontSize = cssVar('stepper-title-font-size');
const $descFontSize = cssVar('stepper-description-font-size');
const $accentColor = cssVar('stepper-accent-color');

const baseStyle = definePartsStyle(({ colorScheme: c }) => ({
  stepper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '4',
    '&[data-orientation=vertical]': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '&[data-orientation=horizontal]': {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [$accentColor.variable]: `colors.${c}`,
    _dark: {
      [$accentColor.variable]: `colors.${c}`,
    },
  },
  title: {
    fontSize: $titleFontSize.reference,
    fontWeight: 'medium',
    '&[data-status=incomplete]': {
      fontWeight: 'normal',
      color: 'gray.75',
    },
  },
  description: {
    fontSize: $descFontSize.reference,
    color: 'chakra-subtle-text',
  },
  number: {
    fontSize: $titleFontSize.reference,
    '&[data-status=incomplete]': {
      fontWeight: 'normal',
      color: 'gray.75',
    },
  },
  step: {
    flexShrink: 0,
    position: 'relative',
    display: 'flex',
    gap: '2',
    '&[data-orientation=horizontal]': {
      alignItems: 'center',
    },
    flex: '1',
    '&:last-of-type:not([data-stretch])': {
      flex: 'initial',
    },
  },
  icon: {
    flexShrink: 0,
    width: $iconSize.reference,
    height: $iconSize.reference,
  },
  indicator: {
    flexShrink: 0,
    borderRadius: 'full',
    width: $size.reference,
    height: $size.reference,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&[data-status=active]': {
      color: 'white',
      bg: $accentColor.reference,
    },
    '&[data-status=complete]': {
      bg: 'green',
      color: 'white',
    },
    '&[data-status=incomplete]': {
      borderWidth: '2px',
    },
  },
  separator: {
    bg: 'chakra-border-color',
    flex: '1',
    '&[data-status=complete]': {
      bg: 'green',
    },
    '&[data-orientation=horizontal]': {
      width: '100%',
      height: '2px',
      marginStart: '2',
    },
    '&[data-orientation=vertical]': {
      width: '2px',
      position: 'absolute',
      height: '100%',
      maxHeight: `calc(100% - ${$size.reference} - 8px)`,
      top: `calc(${$size.reference} + 4px)`,
      insetStart: `calc(${$size.reference} / 2 - 1px)`,
    },
  },
}));

export const Stepper = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    xs: definePartsStyle({
      stepper: {
        [$size.variable]: 'sizes.4',
        [$iconSize.variable]: 'sizes.3',
        [$titleFontSize.variable]: 'fontSizes.xs',
        [$descFontSize.variable]: 'fontSizes.xs',
      },
    }),
    sm: definePartsStyle({
      stepper: {
        [$size.variable]: 'sizes.6',
        [$iconSize.variable]: 'sizes.4',
        [$titleFontSize.variable]: 'fontSizes.sm',
        [$descFontSize.variable]: 'fontSizes.xs',
      },
    }),
    md: definePartsStyle({
      stepper: {
        [$size.variable]: 'sizes.8',
        [$iconSize.variable]: 'sizes.5',
        [$titleFontSize.variable]: 'fontSizes.md',
        [$descFontSize.variable]: 'fontSizes.sm',
      },
    }),
    lg: definePartsStyle({
      stepper: {
        [$size.variable]: 'sizes.10',
        [$iconSize.variable]: 'sizes.6',
        [$titleFontSize.variable]: 'fontSizes.lg',
        [$descFontSize.variable]: 'fontSizes.md',
      },
    }),
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'blue',
  },
});
