import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

export function AuthLayout() {
  return (
    <Box>
      <Container
        as={SimpleGrid}
        maxW="6xl"
        minH="100vh"
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, md: 'unset' }}
      >
        <Flex align={{ base: 'unset', md: 'center' }} justify="center">
          <Box rounded="md" overflow="hidden" m="2" flex={1}>
            <Stack>
              <Stack spacing={4}>
                <Heading lineHeight={1.1}>
                  <Stack direction="row">
                    <Image
                      src="/assets/images/logo.png"
                      alt="logo"
                      h={{ base: '32px', md: '61px' }}
                      w={{ base: '77px', md: '148px' }}
                    />
                    <Stack
                      align="center"
                      spacing="-1"
                      color="navy"
                      fontSize={{ base: 'sm', md: '2xl' }}
                    >
                      <Text mt="1">SUKANDA</Text>
                      <Text>ONELINK</Text>
                    </Stack>
                  </Stack>
                </Heading>
              </Stack>
              <Outlet />
            </Stack>
          </Box>
        </Flex>
        <Flex
          flex={1}
          align="center"
          justify="center"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box overflow="hidden" flex={1} rounded="3xl" m="unset">
            <Image
              src="/assets/images/login.png"
              alt="SUKANDA ONELINK"
              objectFit="cover"
              width={527}
              height={750}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
