import {
  BackofficeUpdateRecipeListBody,
  BackofficeUpdateRecipeListResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import {
  useMutation,
  useMutationState,
  useQueryClient,
} from '@tanstack/react-query';

import { useRecipeDetail } from './get-recipe-detail';
import { BACKOFFICE_RECIPE_QUERY_KEY } from './get-recipe-list';

export const updateRecipe = async (
  token: string,
  recipeId: string,
  payload: BackofficeUpdateRecipeListBody
) => {
  const url = `/backoffice/recipe/${recipeId}`;
  const thumbnail = payload.medias.length > 0 ? payload.medias[0].url : '';
  return apiInstance(token).put<BackofficeUpdateRecipeListResponse>(url, {
    ...payload,
    thumbnail,
  });
};

type UseCreateRecipeMutationArgs = {
  onSuccess?: (data: BackofficeUpdateRecipeListResponse) => void;
};

export function useUpdateRecipeMutation(
  recipeId: string,
  { onSuccess }: UseCreateRecipeMutationArgs = {}
) {
  const { data: detailRecipeData } = useRecipeDetail(recipeId);
  const queryClient = useQueryClient();
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationKey: ['RecipeUpdate', recipeId],
    mutationFn: (payload: BackofficeUpdateRecipeListBody) => {
      // Skip mutationFn if ispublished
      if (detailRecipeData?.is_published)
        return Promise.resolve({} as ReturnType<typeof updateRecipe>);

      const preTransformPayload = (
        payload: BackofficeUpdateRecipeListBody
      ): BackofficeUpdateRecipeListBody => {
        return {
          ...payload,
          video: payload.video?.trim() || null,
          ingredient_groups: payload.ingredient_groups.map((group) => ({
            ...group,
            ingredients: group.ingredients.map((ig) => ({
              ...ig,
              amount: ig.amount * 1000,
            })),
          })),
        };
      };
      return updateRecipe(token, recipeId, preTransformPayload(payload));
    },
    onSuccess(data) {
      if (detailRecipeData?.is_published) return;
      onSuccess?.(data.data);
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'list', recipeId],
      });
    },
  });
}

export function useIsUpdateRecipeMutating(recipeId: string) {
  return useMutationState({
    filters: {
      mutationKey: ['RecipeUpdate', recipeId],
    },
    select: (mutation) => mutation.state.status === 'pending',
  });
}
