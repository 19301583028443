import {
  Box,
  Fade,
  Link,
  Spacer,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { Login } from '@diamond/shared/types';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  PasswordField,
  Text,
  TextField,
} from '@diamond/shared/ui';
import {
  adminCaptureException,
  apiInstance,
  jwtPayload,
} from '@diamond/shared/utils';
import { LoginValidationSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCookie } from '@shopify/react-cookie';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { useAuthentication } from '../context';

export function LoginPage() {
  const { isOpen: isVisible, onClose, onOpen } = useDisclosure();
  const [status, setStatus] = useState<boolean>(false);
  const [statusDesc, setStatusDesc] = useState<string>('');
  const [cookie, setCookie] = useCookie('admin_jwt_token');
  const { login } = useAuthentication();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(LoginValidationSchema) });

  const handleFailure = (message: string) => {
    setStatus(false);
    setStatusDesc(message);
    setTimeout(() => onClose(), 5000);
    onOpen();
  };

  const handleLogin: SubmitHandler<Login | FieldValues> = async (formData) => {
    const { email, password, remember } = formData;
    await apiInstance()
      .post('/auth/login', {
        email,
        password,
        remember,
        subscriber: 'web_admin',
      })
      .then(({ data }) => {
        const payload = jwtPayload(data.token);
        const allowedRoles = ['super_admin', 'cs_katalog', 'cs_cabang'];

        if (!allowedRoles.includes(payload.role)) {
          return handleFailure('Anda tidak memiliki akses ke halaman Admin');
        }

        setCookie(data.token);
        login(data.token);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.code === 'ERR_NETWORK') {
            return handleFailure('Maaf, tidak ada koneksi internet');
          }
          handleFailure(error.response?.data.message);
          adminCaptureException(error.response?.data.message, (scope) => {
            scope.setTags({
              section: 'login',
              login: 'login error',
              status_code: error.response?.data.statusCode,
            });
            scope.setContext('error', { error: error.response?.data });
            scope.setUser({
              email: String(formData.email),
            });
            scope.setLevel('error');
          });
        }
      });
  };

  return (
    <>
      <Box py="8">
        <Text fontSize="3xl" fontWeight="bold">
          Masuk
        </Text>
        <Divider />
      </Box>

      <Box position="fixed" w={{ base: '350px', lg: '480px' }}>
        <Fade in={isVisible}>
          <Alert
            status={status ? 'success' : 'error'}
            description={statusDesc}
            onClose={onClose}
          />
        </Fade>
      </Box>

      <form onSubmit={handleSubmit(handleLogin)}>
        <Stack spacing={4}>
          <TextField
            name="email"
            label="Email"
            placeholder="Masukkan Email"
            register={register}
            errors={errors}
          />
          <PasswordField
            name="password"
            label="Password"
            placeholder="Masukkan Kata Sandi"
            register={register}
            errors={errors}
          />
        </Stack>
        <Stack mt="4">
          <Stack direction={{ base: 'column', sm: 'row' }} align="end">
            <Spacer display={{ base: 'none', md: 'flex' }} />
            <Link
              as={RouterLink}
              to="/reset-password"
              fontWeight="semibold"
              color="blue"
            >
              Lupa kata sandi?
            </Link>
          </Stack>
          <Checkbox
            name="remember"
            label="Ingat Saya"
            color="blue"
            fontWeight="semibold"
            register={register}
          />
          <Button
            bg="blue"
            color="white"
            variant="solid"
            _hover={{
              bg: 'navy',
            }}
            type="submit"
            isLoading={isSubmitting}
            py="24px"
          >
            <span>Masuk</span>
          </Button>
          <Stack
            flex={1}
            flexDirection="column"
            justifyContent="center"
            spacing="-1"
            fontSize={{ base: '12px', md: '16px' }}
          >
            <Text textAlign="center" mt="4">
              Dengan menekan tombol masuk, Anda telah menyetujui
            </Text>
            <Text textAlign="center">
              <Link
                href="https://open.sukandaonelink.com/privacy-policy"
                fontWeight="semibold"
                color="red"
                isExternal
              >
                Kebijakan Privasi{' '}
              </Link>
              &
              <Link
                href="https://open.sukandaonelink.com/cookie-policy"
                fontWeight="semibold"
                color="red"
                isExternal
              >
                {' '}
                Ketentuan Cookies
              </Link>
            </Text>
          </Stack>
        </Stack>
      </form>
    </>
  );
}
