import {
  PlateElement as PlateElementPrimitive,
  PlateElementProps,
} from '@udecode/plate-utils/react';
import React from 'react';

export const PlateElement = React.forwardRef<
  HTMLDivElement,
  PlateElementProps & { blockSelectionClassName?: string }
>(({ blockSelectionClassName, children, ...props }, ref) => {
  return (
    <PlateElementPrimitive ref={ref} {...props}>
      {children}
    </PlateElementPrimitive>
  );
});
