import {
  FormControl,
  FormErrorMessage,
  Radio as ChakraRadio,
  RadioProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export interface RadioButtonProps extends RadioProps {
  name: string;
  label: string;
}

export function RadioButton({
  name,
  label,
  children,
  value,
  ...props
}: RadioButtonProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';

  return (
    <FormControl id={props.id} isInvalid={isInvalid}>
      <ChakraRadio aria-label={label} {...register(name)} {...props}>
        {label}
        {children}
      </ChakraRadio>
      <FormErrorMessage>{errorMessage as string}</FormErrorMessage>
    </FormControl>
  );
}

export default RadioButton;
