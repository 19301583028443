import { Box, Divider, Flex, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { Breadcrumbs } from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/components';
import { Link } from 'react-router-dom';

type RecipeSectionLayoutProps = {
  section: 'homepage' | 'recipe';
  children: React.ReactNode;
  extra?: React.ReactNode;
};

const tabs = [
  {
    id: 'homepage',
    name: 'Section Homepage',
    title: 'Recipe Section Homepage',
    href: '/recipe/section/homepage',
  },
  {
    id: 'recipe',
    name: 'Section Recipe',
    title: 'Recipe Section Recipe List',
    href: '/recipe/section/recipe-list',
  },
];

export function RecipeSectionLayout({
  section,
  extra,
  children,
}: RecipeSectionLayoutProps) {
  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Recipe Management',
      link: '/recipe',
    },
    tabs.find((t) => t.id === section) || {},
  ];

  return (
    <AdminLayout>
      <Box pb="4">
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Flex justifyContent="space-between" alignItems="center" h="40px">
        <Text fontSize="2xl" fontWeight="bold" mb="0">
          Recipe Section
        </Text>
        {extra}
      </Flex>
      <Divider my="6" />
      <Tabs
        index={Math.max(
          tabs.findIndex((t) => t.id === section),
          0
        )}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab
              key={t.id}
              as={Link}
              to={t.href}
              _selected={{
                color: 'blue',
                borderColor: 'blue',
              }}
            >
              {t.name}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Box mt="6">{children}</Box>
    </AdminLayout>
  );
}
