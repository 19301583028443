import { Container, Flex, HStack, Image } from '@chakra-ui/react';
import {
  DEV_ADMIN_HOSTNAME,
  LOCALHOSTNAME,
  STAGING_ADMIN_HOSTNAME,
} from '@diamond/shared/environments';

import { UserMenu } from './user-menu';

export function Header() {
  const getHeaderColorStatusHost = () => {
    switch (window.location.hostname) {
      case DEV_ADMIN_HOSTNAME:
        return 'black';
      case STAGING_ADMIN_HOSTNAME:
        return 'maroon';
      case LOCALHOSTNAME:
        return 'black';
      default:
        return 'navy';
    }
  };
  const headerStatusHost = getHeaderColorStatusHost();
  return (
    <Flex
      as="header"
      bg={headerStatusHost}
      backdropFilter="saturate(180%) blur(5px)"
      h="full"
      w="100%"
      top={0}
      py={4}
      align="center"
      justify="center"
      boxSize="full"
      position="static"
    >
      <Container maxW="container.xl">
        <HStack justify="space-between">
          <a href="/">
            <Image
              borderRadius="3xl"
              src="/assets/images/sukanda_diamond_logo.png"
              alt="SOL Logo"
            />
          </a>
          <UserMenu />
        </HStack>
      </Container>
    </Flex>
  );
}

export default Header;
