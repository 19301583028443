import { RecipeIngredientsSectionPayload } from '@diamond/shared/types';

import { Ingredient, ProductModeType } from './types';

export const emptyIngredientItem: Ingredient = {
  title: '',
  recommended_products: [],
  alternative_products: [],
  amount: 0,
  amount_unit: 'gr',
};

export const emptyArr: Array<Ingredient> = [emptyIngredientItem];

export function copyRecommendedProductsToAlternative(
  values: RecipeIngredientsSectionPayload,
  productModeType: ProductModeType,
  hasAlternativeProducts: boolean
) {
  const isAlternativeEmpty = (products: string[]) =>
    hasAlternativeProducts && products.length === 0;

  if (productModeType === 'alternative_products') return values;

  return {
    ...values,
    ingredient_groups: values.ingredient_groups.map((ig) => ({
      ...ig,
      ingredients: ig.ingredients.map((ingredient) => ({
        ...ingredient,
        recommended_products: ingredient.recommended_products,
        alternative_products: isAlternativeEmpty(
          ingredient.alternative_products
        )
          ? // Copy only when alternative product is empty
            ingredient.recommended_products
          : ingredient.alternative_products,
      })),
    })),
  };
}

export function removeAlternativeProducts(
  values: RecipeIngredientsSectionPayload
) {
  return {
    ...values,
    ingredient_groups: values.ingredient_groups.map((ig) => ({
      ...ig,
      ingredients: ig.ingredients.map((ingredient) => ({
        ...ingredient,
        recommended_products: ingredient.recommended_products,
        alternative_products:
          ingredient.recommended_products.length === 0
            ? // delete only when recommended_products is deleted
              []
            : ingredient.alternative_products,
      })),
    })),
  };
}
