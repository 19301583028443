import { useToast } from '@chakra-ui/react';
import { showToast } from '@diamond/shared/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  recipeResponseToState,
  useRecipeDetail,
  useTogglePublishRecipeMutation,
  useUpdateRecipeMutation,
} from '../../api';
import { RecipeInstructionForm } from '../../components/forms/recipe-instruction-form';
import { RecipeFormLayout } from '../../components/layouts/recipe-form-layout';

export function RecipeInstructionDetail() {
  const params = useParams();
  const recipeId = params['recipeId'] as string;
  const toast = useToast();

  const { data } = useRecipeDetail();
  const navigate = useNavigate();

  const form = useForm({
    // https://react-hook-form.com/faqs#Howtoinitializeformvalues
    values: data ? recipeResponseToState(data) : undefined,
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const togglePublishMutation = useTogglePublishRecipeMutation(recipeId, {
    onSuccess: () => {
      showToast(toast, 'success', 'Resep Berhasil Dipublish');
    },
  });
  const updateRecipeMutation = useUpdateRecipeMutation(recipeId);

  const onSubmit = async (data: ReturnType<typeof recipeResponseToState>) => {
    await updateRecipeMutation.mutateAsync(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <RecipeFormLayout
          section={{
            title: 'Instruksi Memasak',
            link: 'instruction',
            index: 3,
          }}
          handlePublish={async (e) => {
            await form
              .handleSubmit(onSubmit)(e)
              .then(togglePublishMutation.mutate);
          }}
          handlePrev={async () => {
            await form.handleSubmit(onSubmit)();
            navigate(`/recipe/list/${recipeId}/ingredients`);
          }}
        >
          <RecipeInstructionForm form={form} />
        </RecipeFormLayout>
      </form>
    </FormProvider>
  );
}
