import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { usePaginationAdmin } from '@diamond/shared/context';
import { Breadcrumbs, Pagination, Searchbox } from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useCleanSearchParams, useSearchbox } from '@diamond/sol-admin/hooks';
import { Add } from '@mui/icons-material';

import { useRecipeNotification } from '../api';
import { CreateBlastNotification } from '../components/create-blast-notification';
import { RecipeNotificationItem } from '../components/recipe-notification-item';

const links = [
  {
    title: 'Beranda',
    link: '/',
  },
  {
    title: 'Recipe Management',
    link: '/recipe',
  },
  {
    title: 'Notification Blast',
    link: '/recipe/notification',
  },
];

export function BlastNotificationPage() {
  const { register } = useSearchbox();
  const { paramsEntries, setParams } = useCleanSearchParams();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentPage = !isNaN(Number(paramsEntries['page']))
    ? Number(paramsEntries['page'])
    : 1;
  const { data, isPending, refetch } = useRecipeNotification({
    search_query: paramsEntries['query'] || '',
    page: currentPage,
    size: 20,
  });
  const pageCount = data?.pagination.pages || 1;

  const { nextPage, previousPage, lastPage, firstPage } = usePaginationAdmin(
    currentPage,
    (value) => setParams({ page: `${value}` }),
    refetch,
    pageCount
  );

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box py={4}>
        <Stack
          spacing={8}
          direction="row"
          justifyContent="space-between"
          mb="3"
        >
          <Text fontSize="2xl" fontWeight="bold">
            Resep Blast Notification History
          </Text>
          <Button
            leftIcon={<Add />}
            onClick={onOpen}
            textColor="#000000"
            variant="outline"
          >
            Add New Notif
          </Button>
        </Stack>
        <Divider border="2px" borderColor="#D9D9D9" mb="5" />
        <Searchbox
          register={register}
          placeholder="Cari blast notification"
          mb={5}
        />

        <Flex
          maxW="container.xl"
          w="full"
          px="5"
          py="6"
          bg="#0000A310"
          borderTopRadius="xl"
          mb={1}
        >
          <Stack w="16% " ml={20}>
            <Text fontWeight="semibold" fontSize="sm">
              Gambar
            </Text>
          </Stack>
          <Stack w="20%">
            <Text fontWeight="semibold" fontSize="sm">
              Nama Resep
            </Text>
          </Stack>
          <Stack w="20%">
            <Text fontWeight="semibold" fontSize="sm">
              Date & Time
            </Text>
          </Stack>

          <Stack w="20%">
            <Text fontWeight="semibold" fontSize="sm">
              Status
            </Text>
          </Stack>
          <Stack w="20%">
            <Text fontWeight="semibold" fontSize="sm">
              Action
            </Text>
          </Stack>
        </Flex>
        {data &&
          data.data.map((d) => {
            return (
              <RecipeNotificationItem data={d} isNotifPending={isPending} />
            );
          })}

        <Center my="8">
          <Pagination
            currentPage={currentPage}
            totalPage={pageCount}
            nextPage={nextPage}
            firstPage={firstPage}
            lastPage={lastPage}
            previousPage={previousPage}
          />
        </Center>

        <CreateBlastNotification isOpen={isOpen} onClose={onClose} />
      </Box>
    </AdminLayout>
  );
}
