import {
  Button,
  Divider,
  HStack,
  Stack,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { PostDataWrapper } from '@diamond/shared/data-access';
import {
  BackofficeRecipeMediaUploadResponse,
  RecipeTagsResponse,
} from '@diamond/shared/types';
import { Dropzone, Modal, showToast, TextField } from '@diamond/shared/ui';
import { apiInstance } from '@diamond/shared/utils';
import { RecipeCategorySchema } from '@diamond/shared/validation';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { BACKOFFICE_RECIPE_QUERY_KEY } from '../api';
import { calculateColorShade } from './color-shade';
import { ColorTextField } from './color-text-field';

export interface EditRecipeCategoryProps {
  isOpen: boolean;
  onClose: () => void;
  recipeData: RecipeTagsResponse['data'][number];
}

export const EditRecipeCategory = ({
  isOpen = false,
  onClose,
  recipeData,
}: EditRecipeCategoryProps) => {
  const session = useAuthStore();
  const toast = useToast();
  const [isReady, setIsReady] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const nameBigIcon = recipeData.big_icon_url.split('/').pop();
  const nameSmallIcon = recipeData.small_icon_url.split('/').pop();
  const methods = useForm({
    resolver: yupResolver(RecipeCategorySchema),
    shouldUnregister: false,
  });

  const { postData: postUpload } = PostDataWrapper(
    '/backoffice/recipe/upload',
    session.access_token,
    true,
    'admin'
  );
  const onSubmit = async (formValues: FieldValues) => {
    const formData = new FormData();
    const { name, image, icon, color } = formValues;
    const { bg_color, text_color } = calculateColorShade(color);

    setIsReady(true);

    let big_icon_idx = -1;
    let small_icon_idx = -1;
    if (image) {
      formData.append('file', image[0], image[0].name);
      big_icon_idx = 0;
    }

    if (icon) {
      formData.append('file', icon[0], icon[0].name);
      small_icon_idx = big_icon_idx === 0 ? 1 : 0;
    }

    try {
      let response: BackofficeRecipeMediaUploadResponse | undefined;
      if (image || icon) {
        response = (await postUpload(formValues, {
          headers: {
            authorization: `Bearer ${session.access_token}`,
            'Content-Type': 'multipart/form-data',
          },
        })) as BackofficeRecipeMediaUploadResponse;
      }

      await apiInstance(session.access_token).patch(
        `/backoffice/recipe/${recipeData.id}/tag`,
        {
          name,
          big_icon_url:
            big_icon_idx !== -1 && response
              ? response[big_icon_idx]['url']
              : recipeData.big_icon_url,
          small_icon_url:
            small_icon_idx !== -1 && response
              ? response[small_icon_idx]['url']
              : recipeData.small_icon_url,
          text_color,
          bg_color,
          category: 'food',
        }
      );
      showToast(toast, 'success', 'Kategori berhasil diupdate');
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_KEY],
      });
      onClose();
    } finally {
      methods.reset();
    }
  };
  return (
    <Modal
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        methods.reset();
      }}
      name="form-edit-category"
      title="Edit Kategori"
      hideAction
    >
      <Divider borderColor="black" border="1px" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack pt={4} pb={8}>
            <TextField
              name="name"
              label="Title"
              placeholder="Masukan Title"
              register={methods.register}
              maxLength={100}
              errors={methods.formState.errors}
              defaultValue={recipeData.name}
            />
          </Stack>
          <Stack py={4}>
            <Text fontSize="md" fontWeight="bold">
              Gambar
            </Text>
            <Dropzone
              name="image"
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png'],
              }}
              errors={methods.formState.errors}
              onEmptied={() => setIsReady(false)}
              setIsFileAttached={(val) => setIsReady(val)}
              maxSize={0.1}
              urlDownload={recipeData.big_icon_url}
              fileName={nameBigIcon}
            />
            <Text fontSize="xs" color="gray" textAlign="right">
              Hanya menerima format PNG,SVG dan JPG dengan ukuran maksimal
              100kb.{' '}
            </Text>
            <Text fontSize="xs" color="gray" textAlign="right">
              Resolusi gambar disarankan berukuran 24x24 pixel.
            </Text>
          </Stack>

          <Stack py={4}>
            <Text fontSize="md" fontWeight="bold">
              Icon Kategori
            </Text>
            <Dropzone
              name="icon"
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png', '.svg'],
              }}
              errors={methods.formState.errors}
              onEmptied={() => setIsReady(false)}
              setIsFileAttached={(val) => setIsReady(val)}
              maxSize={0.1}
              urlDownload={recipeData.small_icon_url}
              fileName={nameSmallIcon}
            />
            <Text fontSize="xs" color="gray" textAlign="right">
              Hanya menerima format PNG,SVG dan JPG dengan ukuran maksimal
              100kb.
            </Text>
            <Text fontSize="xs" color="gray" textAlign="right">
              Resolusi gambar disarankan berukuran 24x24 pixel.
            </Text>
          </Stack>
          <Stack py={4}>
            <ColorTextField
              defaultValue={{
                bg_color: recipeData.bg_color,
                text_color: recipeData.text_color,
              }}
            />
          </Stack>

          <Divider borderColor="black" border="1px" />
          <Stack py={4}>
            <Wrap justify="end">
              <WrapItem>
                <HStack>
                  <Button
                    onClick={() => {
                      onClose();
                      methods.reset();
                    }}
                    variant="outline"
                  >
                    Tutup
                  </Button>
                  <Button type="submit" variant="solid" disabled={isReady}>
                    <span>Save</span>
                  </Button>
                </HStack>
              </WrapItem>
            </Wrap>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
};
