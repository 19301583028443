import { Flex, Stack, Text } from '@chakra-ui/react';

export const RecipeCategoryHeading = () => {
  return (
    <Flex
      maxW="container.xl"
      w="full"
      px="5"
      py="6"
      bg="#0000A310"
      borderTopRadius="xl"
    >
      <Stack w="20%" ml={20}>
        <Text fontWeight="medium">Gambar</Text>
      </Stack>
      <Stack w="22% " ml={20}>
        <Text fontWeight="medium">Nama Kategori</Text>
      </Stack>
      <Stack w="26% " ml={20}>
        <Text fontWeight="medium">Icon Kategori</Text>
      </Stack>
      <Stack w="24% " ml={20}>
        <Text fontWeight="medium">Warna Kategori</Text>
      </Stack>
      <Stack w="24% " ml={20}>
        <Text fontWeight="medium"></Text>
      </Stack>
    </Flex>
  );
};
