import cleanDeep from 'clean-deep';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useCleanSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const setParams = useCallback(
    (nextParams: Record<string, unknown>) => {
      const prevParams = {
        query: '',
        ...Object.fromEntries(searchParams.entries()),
      };

      setSearchParams(cleanDeep({ ...prevParams, ...nextParams }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  );
  return {
    searchParams,
    setParams,
    paramsEntries: Object.fromEntries(searchParams.entries()),
  };
}
