import {
  Box,
  Skeleton,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { QueryWrapper } from '@diamond/shared/data-access';
import { Banner } from '@diamond/shared/types';
import {
  type SortableItem,
  Breadcrumbs,
  Button,
  Divider,
  DropResult,
  showToast,
  Sortable,
  Text,
} from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/components';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

import { deleteBanner, reorderBanner } from '../api';
import { BannerItem } from '../components/banner-item';
import { CreateBanner } from '../components/create-banner';

export function BannerPage() {
  const session = useAuthStore();
  const navigate = useNavigate();
  const toast = useToast();

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Banner Management',
      link: '/banners',
    },
  ];

  const bannersQuery = QueryWrapper('/backoffice/banner', session.access_token);
  const { data, isLoading, refetch } =
    bannersQuery.useQueryGet<Banner>('Banners');
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isLoading) {
    return (
      <AdminLayout>
        <Box py={4}>
          <Skeleton height="540px" borderRadius="md" />
        </Box>
      </AdminLayout>
    );
  }

  const handleIndexChange = (result: DropResult) => {
    if (result.destination) {
      reorderBanner(
        session.access_token,
        result.draggableId,
        result.destination.index + 1
      );
    }
  };

  const handleDelete = (id: string) => {
    deleteBanner(session.access_token, id)
      .then(() => refetch())
      .catch((error) => showToast(toast, 'error', error.message))
      .finally(() => {
        showToast(toast, 'success', 'Banner berhasil dihapus');
        navigate(0);
      });
  };

  const banners: SortableItem[] = data.map((banner) => {
    return {
      id: banner.id,
      children: (
        <BannerItem item={banner} onDelete={() => handleDelete(banner.id)} />
      ),
    };
  });

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box py={4}>
        <Stack spacing={8} direction="row" justifyContent="space-between">
          <Text fontSize="2xl" fontWeight="bold">
            Banner Management
          </Text>
          <Button
            leftIcon={<AddIcon />}
            bg="blue"
            color="white"
            variant="solid"
            _hover={{ bg: 'navy' }}
            onClick={onOpen}
          >
            Tambah Banner
          </Button>
        </Stack>
      </Box>

      <Divider />

      <Box my={8} width="full">
        <Sortable data={banners} handleChange={handleIndexChange} />
      </Box>

      <CreateBanner data={data} isOpen={isOpen} onClose={onClose} />
    </AdminLayout>
  );
}
