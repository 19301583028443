import {
  BackofiiceUpdateRecipeBlastNotificationBody,
  BackofiiceUpdateRecipeBlastNotificationResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/features/auth';
import { useMutation } from '@tanstack/react-query';

export const updateBlastNotification = async (
  token: string,
  payload: BackofiiceUpdateRecipeBlastNotificationBody
) => {
  const url = `/backoffice/recipe/blast-notification`;

  return apiInstance(
    token
  ).post<BackofiiceUpdateRecipeBlastNotificationResponse>(url, payload);
};

type UseUpdateRecipeNotificationMutationArgs = {
  onSuccess: () => void;
  onSettled: () => void;
};

export function useUpdateRecipeNotificationMutation({
  onSuccess,
  onSettled,
}: UseUpdateRecipeNotificationMutationArgs) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: (payload: BackofiiceUpdateRecipeBlastNotificationBody) =>
      updateBlastNotification(token, payload),
    onSuccess,
    onSettled,
  });
}
