import { createTPlatePlugin } from '@udecode/plate-core/react';
import { getListItemEntry, moveListItemUp } from '@udecode/plate-list';

import { onKeyDownListSoftBreak } from './on-keydown-list-soft-break';
import { insertListItem } from './transform';
import { ListSoftBreakConfig } from './types';

export const ListSoftBreakPlugin = createTPlatePlugin<ListSoftBreakConfig>({
  key: 'listSoftBreak',
  options: {
    rules: [{ hotkey: 'shift+enter' }],
  },
  handlers: {
    onKeyDown: onKeyDownListSoftBreak,
  },
}).overrideEditor(({ editor, tf: { insertBreak } }) => ({
  transforms: {
    insertBreak() {
      const insertBreakList = () => {
        if (!editor.selection) return;
        const res = getListItemEntry(editor, {});
        let moved: boolean | undefined;

        // If selection is in a li
        if (res) {
          const { list, listItem } = res;

          // If selected li is empty, move it up.
          if (
            editor.api.isEmpty(editor.selection, { block: true }) &&
            listItem[0].children.length === 1
          ) {
            moved = moveListItemUp(editor, {
              list,
              listItem,
            });

            if (moved) return true;
          }
        }

        /** If selection is in li > p, insert li. */
        if (!moved) {
          const inserted = insertListItem(editor);

          if (inserted) return true;
        }

        return;
      };

      if (insertBreakList()) return;

      insertBreak();
    },
  },
}));
