import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const rightAddon = definePartsStyle({
  field: {
    px: '1',
  },
  icon: {
    p: 0,
  },
});

export const Select = defineMultiStyleConfig({
  variants: { rightAddon },
});
